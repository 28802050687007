<template>
	<v-dialog
		v-model="dialog"
		
		:fullscreen="$vuetify.breakpoint.smAndDown"
		hide-overlay
		transition="dialog-bottom-transition"

	>
		<template v-slot:activator="{ on, attrs }">

			<v-list-item-content
			
				v-bind="attrs"
				v-on="on">
					<!-- {{Room.conversation}} -->
				<v-list-item-title>
					<v-avatar
						right
						class="ma-0 d-block float-right mx-auto ml-2"
						color="grey darken-1"
						size="36"
					>
					<v-icon color="white">mdi-chat</v-icon>
					</v-avatar>
					{{Room.title}}  
					<v-list-item-subtitle>{{Room.description}}</v-list-item-subtitle>
				</v-list-item-title>
			</v-list-item-content> 
		</template>
		<v-slide-x-transition>

			<v-card
				style="top:0;left:0; position:fixed"
				:width="['xs','sm'].includes($vuetify.breakpoint.name) ? '100%': (['md'].includes($vuetify.breakpoint.name) ? '40%' : '30%')"

				tile>
				
				<v-toolbar
					ref="header"
					dark
					:color="Room.type == 'project' ? 'teal' : 'primary'"
					height="50px"
					extension-height="100"

				>
					<template
						v-if="chatExtension"
						v-slot:extension>
						<v-sheet
							v-show="chatExtension"
							style="box-shadow:  rgb(0 0 0 / 30%) 0px 4px 10px inset;
								border-bottom: 1px solid rgba(255,255,255, .4) !important;
								border-right: 1px solid rgba(255,255,255, .4) !important;
								flex: auto"
							:color="Room.type == 'project' ? 'teal darken-3' : 'primary darken-2'" rounded class="pa-2">
							{{Room.description}}

							<div dir="ltr">
								<v-chip small outlined><v-icon size="13" class="mr-2">mdi-calendar-plus</v-icon>{{Room.created_at}}</v-chip>
							</div>
							
						</v-sheet>
					</template>
				
					<v-btn
						icon
						dark
						@click="dialog = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>
						<span v-if="Room.type == 'project'">پروژه </span>{{Room.title}}
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							dark
							icon
							@click="chatExtension = !chatExtension, fixHeights()"
						>
							<v-badge
								offset-x="8"
								offset-y="-12"
								dot color="red" v-if="!chatExtension"></v-badge>
							<v-icon>mdi-information-variant</v-icon>
						</v-btn>
						<v-btn
							dark
							icon
							@click="drawer.open = true"
						>
							<v-icon>mdi-menu</v-icon>
						</v-btn>
					</v-toolbar-items>
					<!-- <template v-slot:extension>
						<div>

							<v-progress-linear
							height="10"
							stream
							color="blue"
							class="mb-0" :value="50"></v-progress-linear>
						</div>
						<v-divider></v-divider>
						<div>
						<v-chip-group>
							<v-chip>asd</v-chip>
							<v-chip>asd</v-chip>
							<v-chip>asd</v-chip>
						</v-chip-group>
						</div>

					</template> -->  
				</v-toolbar> 
				<v-row no-gutters> 
					<v-col > 
						<chat-conversation 
							:class="$vuetify.breakpoint.mdAndUp ? 'pr-10 pl-10' : ''" 
							ref="conversation"
							v-on:scroll.native="handleScroll"
							:Room="Room"
							:sendingMessage="sendingMessage ? sendingMessage : {}"
							:style="'min-height: 100%; height: 100vh;overflow-y: scroll;'"
						></chat-conversation>
						<v-slide-y-reverse-transition>

							<v-btn
								v-if="scrollFab"
								elevation="2"
								color="pink"
								dark
								fixed
								bottom
								right
								fab
								style="right: auto; left: 20px; bottom: 150px;z-index: 253;"
								@click="scrollBottom"
							><v-icon>mdi-arrow-down-bold-circle-outline</v-icon></v-btn>
						</v-slide-y-reverse-transition>
						
						<v-footer
							fixed
							:width="['xs','sm'].includes($vuetify.breakpoint.name) ? '100%': (['md'].includes($vuetify.breakpoint.name) ? '40%' : '30%')"
							style="left:0; right: auto"
							ref="footer"
							elevation="5"> 
							<v-row v-if="Array.isArray(ChatRoom.participants) && !ChatRoom.participants.find(x => x.id === account.user.id)"
								align="center" align-content="center">
								<v-col class="text-center">
									<v-chip>شما عضو این اتاق گفتگو نمیباشید</v-chip>
								</v-col>
							</v-row>
							<v-row
								v-else
								style="min-height: 125px"
								align="center" align-content="center">
								<v-col cols="3" sm="3" md="3" align-self="center" class="text-center">

									<v-row
										align="center" align-content="center">
										<v-col cols="12" class="pa-0">
											<v-btn-toggle
												rounded
												
											>
												<v-btn
													:style="$vuetify.breakpoint.smAndDown ? '5px 20px 5px 18px !important;' : ''"
													class="pa-3"

													icon
													:disabled="!(Object.keys(sendingMessage).length === 0)"
													
													@click="launchFilePicker"
												>
													<v-icon small>mdi-file-upload</v-icon>
												</v-btn>
												<v-btn
													:style="$vuetify.breakpoint.smAndDown ? 'padding: 5px 15px 5px 20px !important;' : ''"
													class="pa-3"
													icon
													:disabled="!(Object.keys(sendingMessage).length === 0)"
													@click="sendMessage"
												>
													<v-icon small>mdi-send</v-icon>
												</v-btn>
											</v-btn-toggle>

										</v-col>
										<!-- <v-col
											class="text-center"
											cols="12"
											sm="12"
											md="6"
											lg="6"
											style="height:50px;position: relative;"
										>


											<v-speed-dial
												v-model="moreFab"
												top
												
												style="position: absolute; top:0px; left: 0;"
												direction="top"
											>
												<template v-slot:activator>

													<v-btn
													class="ma-2"
													depressed
													elevation="2"
													icon
													outlined
													:loading="!(Object.keys(sendingMessage).length === 0)"
													>
														<v-icon v-if="moreFab">
															mdi-close
														</v-icon>
														<v-icon v-else>
															mdi-dots-horizontal
														</v-icon>
													</v-btn>
												</template> 

												<v-btn
													color="green lighten-4"
													style="background-color: green;"
													class="ma-2"
													elevation="2"
													icon
													:loading="!(Object.keys(sendingMessage).length === 0)"
													dark
													depressed
													@click="launchFilePicker"
												>
													<v-icon >mdi-file-upload</v-icon>
												</v-btn>
												<v-btn
													color="green lighten-4"
													style="background-color: green;"
													class="ma-2"
													elevation="2"
													icon
													:loading="!(Object.keys(sendingMessage).length === 0)"
													dark
													depressed
												>
													<v-icon >mdi-microphone</v-icon>
												</v-btn>
											</v-speed-dial> 
										</v-col> 
										<v-col
											class="text-center"
											cols="12"
											sm="12"
											md="6"
											lg="6"
											style="height:50px;position: relative;"
										>
											<v-btn
												class="ma-2"
												depressed
												style="position: absolute; top:0px; left: 0;"
												elevation="2"
												icon
												outlined
												:disabled="!(Object.keys(sendingMessage).length === 0)"
												:loading="!(Object.keys(sendingMessage).length === 0)"
											>
												<v-icon small>mdi-send</v-icon>
											</v-btn>
										</v-col> -->
									</v-row>
								</v-col>
								<v-col v-if="$refs.conversation">
									<v-slide-y-transition>

										<div v-if="message.file" dir="ltr">
											<v-row 
												align="center"
												justify="center"
											>
												<v-col :cols="12" sm="4" md="4" lg="4" xl="4" class="">

													<div 
														class="ma-2"
														v-if="message.file.type == 'image'">
														<img
															style="
																max-width: 100%;
																border-radius: 5px;
																float:left;
															"
															:src="message.file.preview">
													</div>
													<div v-else>
														<v-icon class="float-left" large v-if="message.file.type == 'compressed'">mdi-folder-zip</v-icon>
														<v-icon class="float-left" large v-if="message.file.type == 'word'">mdi-file-word</v-icon>
													</div>

												</v-col>
												<v-col cols="12" sm="8" md="8" lg="8">
													<v-chip 
														link
														outlined
														pill
														class="ml-3"
														style="max-width: 250px;"
														small>
														{{message.file.name}}
													</v-chip>
													<v-divider class="ma-3"></v-divider>
													<v-chip 
														v-if="message.file._size"
														link
														outlined
														pill
														class="ml-3"
														small>
														{{message.file._size}}
													</v-chip> 
													<v-chip
														@click="removeAttachment"
														link
														outlined pill color="red" class="ml-3" small>
														<v-icon size="18px">mdi-delete</v-icon>
													</v-chip>
												</v-col>
											</v-row>
											<v-divider class="ma-2"></v-divider>
										</div>
									</v-slide-y-transition>

									<v-textarea
											ref="messageInput" 
											style="min-height:100%; padding-top: 20px; max-height: 300px;overflow: auto;"
											label="متن پیام خود را بنویسید"
											auto-grow
											outlined
											:color="!isOnline ? (
												Room.type == 'project' ? 'teal' : 'primary'
											) : ''"
											:loading="!(Object.keys(sendingMessage).length === 0) || !isOnline"
											
											:disabled="!(Object.keys(sendingMessage).length === 0) || !isOnline"
											rows="3"
											row-height="1"
											clearable
											v-model="message.message"
											@keydown.enter.exact.prevent="sendMessage"
											@keydown.enter.shift.exact.prevent="message.message += '\n'"
											v-on:keyup="message.file == null ? message.type = 'text' : ''"
										></v-textarea>
								</v-col>
							</v-row>
						</v-footer>
					</v-col>
				</v-row>

				<input type="file"
					ref="file"
					name="uploading_file"
					@change="onFileChange(
							$event.target.name, $event.target.files)"
					style="display:none">
			</v-card>
		</v-slide-x-transition>
    <v-alert
			v-if="!isOnline"
      border="right"
      colored-border
			:color="Room.type == 'project' ? 'teal' : 'primary'"
      elevation="2"
			style="position: fixed; top: 100px;right: 20px; width: 350px;z-index:5;"
				
    >
			<v-progress-circular
				style="float: right"
				:color="Room.type == 'project' ? 'teal' : 'primary'"
				class="ml-5" indeterminate></v-progress-circular>
      <span>
				در حال برقراری ارتباط...
			</span>
			<!-- <v-btn @click="connectToWS()" small left dark class="mt-5" color="green"><v-icon>mdi-refresh</v-icon> اتصال مجدد</v-btn> -->
		</v-alert>
    <v-navigation-drawer
      v-model="drawer.open"
      fixed
			:permanent="drawer.open"
			overlay-opacity=".5"
			overlay-color="#000"
      dark
      src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"

    >
      <v-list-item>
        <v-list-item-avatar>
					<v-avatar
						right
						color="grey darken-1"
						size="36"
					>
					<v-icon color="white">mdi-chat</v-icon>
					</v-avatar>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-if="Room.type == 'support'" >تیکت پشتیبانی </v-list-item-title>
          <v-list-item-title v-else>اتاق گفتگو پروژه</v-list-item-title>
          <v-list-item-subtitle>{{Room.title}}</v-list-item-subtitle>
        </v-list-item-content>

				<v-list-item-action>
					<v-btn
						icon
						dark
						@click="drawer.open = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-list-item-action>
      </v-list-item>
			

      <v-divider></v-divider>

			<v-list dense v-if="ChatRoom.participants">
				<v-subheader>
					کاربران عضو اتاق
				</v-subheader>
				<v-list-item v-for="(U, _I) in ChatRoom.participants" :key="_I">
					<v-list-item-avatar>
						<v-avatar
							right
							color="grey darken-1"
							size="26"
						>
							<v-img :src="U.avatar" />
						</v-avatar>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>{{U.fullname}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item> 
			</v-list>

      <template v-if="Room.type == 'support'" v-slot:append>
				<v-divider class="mb-2"></v-divider>
        

					<v-list v-if="Room.type == 'support'">
						<v-subheader>مدیریت تیکت</v-subheader>
						<v-list-item
							
							@click="closeTicket"
						>
							<v-list-item-icon><v-icon>mdi-close</v-icon></v-list-item-icon>
							<v-list-item-title>بستن درخواست</v-list-item-title>
						</v-list-item>
						<v-divider></v-divider>
						<v-list-item
							
							@click="deleteTicket"
						>
							<v-list-item-icon><v-icon color="red">mdi-delete</v-icon></v-list-item-icon>
							<v-list-item-title class="red--text">حذف درخواست</v-list-item-title>
						</v-list-item>
					</v-list>
					<!-- Script for Chat Sheet instead of list -->
				<!-- <div class="pa-5">
          <v-btn
						rounded
						elevation="0"
						color="primary"
						dark
						block @click="chatSheet = true">
						<v-icon>mdi-cogs</v-icon>
            مدیریت تیکت
          </v-btn>
        </div> -->
      </template>

    </v-navigation-drawer>

    <v-bottom-sheet
      v-model="chatSheet"
      inset
			dark
			max-width="400"
    >
      <v-sheet
        class="text-center"
      >
      <v-list v-if="Room.type == 'support'">
        <v-subheader>مدیریت درخواست</v-subheader>
        <v-list-item
					
          @click="closeTicket"
        >
					<v-list-item-icon><v-icon>mdi-close</v-icon></v-list-item-icon>
          <v-list-item-title>بستن درخواست</v-list-item-title>
        </v-list-item>
				<v-divider></v-divider>
        <v-list-item
					color="red"
          @click="deleteTicket"
        >
					<v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
          <v-list-item-title>حذف درخواست</v-list-item-title>
        </v-list-item>
      </v-list>

      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-model="error.dialog" max-width="300">
      <v-card class="pt-2">
        <v-card-title class="" v-if="error.title">{{error.title}}</v-card-title>
        <v-card-text v-html="error.text" class=" subheading"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="error.dialog = false" text>باشه</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> <!-- end of delete dialog -->
		
	</v-dialog>
</template>

<script>
import ChatConversation from '@/components/Chat/ChatConversation.vue'
import { mapState, mapActions } from 'vuex'

const defaultMessageTemplate = {
	room: null,
	type: 'text',
	file: null,
	message: '',
}

export default {
	name: "ChatRoom",
	props: {
		Room: Object,
		admin: Boolean,
	},
	components:{
		ChatConversation
	},
	data () {
		return {
			chatExtension: true,
			drawer: {
				open: false,

			},
			error: {
				dialog: false,
				title: '',
				text: '',
			},

			// more sending options
			moreFab: false,

			isOnline: false, // is ws connected ?
			
			// Chat Room Dialog Status
			dialog: false,
			
			chatSheet: false, // DO NOT GET PAID ENOUGH! DO NOT NEED THIS SHIT! STOP IMPROVING AND FINISH IT UP!

			// WebSocket Keep Alive timer
			timerID: null,

			// WebSocket Keep Alive status
			autoConnect: {
				status: 'connecting',
				timerID: null, 
			},
			autoConnectTimerID: null,

			// Message Data
			message: JSON.parse(JSON.stringify(defaultMessageTemplate)),

			sendingMessage: {
				
			},

			conversationHeight: '75vh',
			scrollFab: false,
			firstTimeScroll: true,

			clientClouser: false
		}
	}, 

	computed: { 
    ...mapState({
      account: state => state.account,
      Panel: state => state.UserPanel,
			ChatPanel: state => state.ChatPanel,
      ChatRoom: state => state.ChatPanel.drawer.currentRoom,
    }),
		drawerState: {
			get() {
				return this.$store.getters.navDrawer; 
			},
			set(state) {
				if (state) {
					this.closeDrawer();
				}
			},
		},
	}, 
	created(){
		this.fixHeights()
	},
	mounted() {
		this.fixHeights()

	},
	methods: {
		closeTicket() {
			this.chatSheet = false // close sheet
			
			this.closeRoom(this.Room.id)
			
		},
		deleteTicket() {
			this.chatSheet = false // close sheet
			this.deleteRoom(this.Room.id)
		},
		launchFilePicker(){
			this.$refs.file.click();
		},
		removeAttachment() {
			this.message.file = null
			this.$refs.file.value = null
			
			setTimeout(() => {
				this.fixHeights()
			}, 500)
		},
		onFileChange(fieldName, file) {

			let actualFile = file[0]

			if (file.length>0) {

				let size = actualFile.size / 1024 / 1024

				if (size>2) {
					// check whether the size is greater than the size limit
					this.error.dialog = true
					this.error.text = 'لطفا فایل با حجم کمتر از ۲ مگابایت انتخاب کنید'
				} else if((actualFile.type.match('image/jpeg') || actualFile.type.match('image/png'))){

					// Append file into FormData and turn file into image URL
					let imageURL = URL.createObjectURL(actualFile)
					// // Emit the FormData and image URL to the parent component
					this.message.file = {
						name: actualFile.name,
						size: actualFile.size,
						_size: '',
						type: 'image', // compressed, audio, recorded
						preview: imageURL,
						data: actualFile
					}

				}
				else if(actualFile.type.match('audio/mpeg') || actualFile.type.match('audio/mp3')) {
					//
					// Append file into FormData and turn file into Sound URL
					let audioURL = URL.createObjectURL(actualFile)
					// // Emit the FormData and sound URL to the parent component
					this.message.file = {
						name: actualFile.name,
						size: actualFile.size,
						_size: '',
						type: 'audio', // compressed, audio, recorded
						preview: audioURL,
						data: actualFile
					}

				}
				else if(
					actualFile.type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
					actualFile.type.match('application/msword')
				) {

					this.message.file = {
						name: actualFile.name,
						size: actualFile.size,
						_size: '',
						type: 'word', // compressed, audio, recorded
						preview: false,
						data: actualFile
					}
				}
				else if(
					actualFile.type.match('application/x-rar-compressed') ||
					actualFile.type.match('application/zip') ||
					actualFile.type.match('application/zip') ||
					actualFile.type.match('application/x-zip-compressed')
				) {

					this.message.file = {
						name: actualFile.name,
						size: actualFile.size,
						_size: '',
						type: 'compressed', // compressed, audio, recorded
						preview: false,
						data: actualFile
					}
				} else {
					// check whether the upload is an image
					this.error.dialog = true
					this.error.title = 'فرمت های مجاز'
					this.error.text = 'لطفا فایل با یکی از فرمت های زیر انتخاب کنید'
					this.error.text+= '<ul dir="ltr">'
					this.error.text+= '	<li>JPG, JPEG, PNG</li>'
					this.error.text+= '	<li>DOC, DOCX</li>'
					this.error.text+= '</ul>'

					// cannot process this file, error
					return false
				}
				// 
				var reader = new FileReader();
				
				// read the file data
				reader.readAsDataURL(actualFile);
				
				// on file ready
				reader.onload = () => {
					// add the base 64 encoded data to our message request
					this.message.file.encoded = reader.result
			
					setTimeout(() => {
						this.fixHeights()
					}, 100)
				}
				// set the message type
				this.message.type = 'file'

				// focus message textarea input
				this.$refs.messageInput.$refs.input.focus()
				
				// at this point this.message contains a message with the selected file,
				// it will be sent to the WSS Server when this.sendMessage() is called
			}
		},
		scrollBottom(){
			if(this.$refs.conversation){

				this.$nextTick(() => {
					setTimeout(() => {
						this.$refs.conversation.$el.scrollTo({
							top: 999999990,
							left: 0,
							behavior: this.firstTimeScroll ? 'auto' : 'smooth'
						});
					}, 100)
				})

				this.firstTimeScroll = false
			}
		},
		fixHeights() {
			let d = this.$refs.conversation ? this.$refs.conversation.$el : false
			if(this.$refs.footer && this.$refs.header) {

				this.$nextTick(() => {
					setTimeout(() => {
						let $h = this.$refs.footer.$el.clientHeight + this.$refs.header.$el.clientHeight
						
						this.conversationHeight = 'calc(100vh - '+($h)+'px )';
						if(d)
						d.style.height = this.conversationHeight
					}, 1)
				})
			}
		},
		handleScroll(){
			
			this.fixHeights()

			var h = this.$refs.conversation.$el, 
					b = document.body,
					st = 'scrollTop',
					sh = 'scrollHeight';
			
			var scrollPassed = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100
			
			if(scrollPassed < 90) {
				this.scrollFab = true
			}
			else this.scrollFab = false
		},
		...mapActions({
			humanFileSize: 'website/humanFileSize', 

			enterRoom: 'ChatPanel/enterRoom',
			closeRoom: 'ChatPanel/closeRoom',
			deleteRoom: 'ChatPanel/deleteRoom',
			recievedMessage: 'ChatPanel/messageRecieved',
			markAsRead: 'ChatPanel/markAsReadLocal',
		}),
		sendMessage() {
			if (!this.message) return;

			// Room ID is required
			if(!this.message.room) this.message.room = this.Room.id
			
			//
			if (this.socket && this.socket.readyState == this.socket.OPEN) {

				switch (this.message.type)
				{
					case 'text':
						var $l = this.message.message.trim()

						if($l == '' || $l == ' ' ) {

							alert('لطفا پیام خود را بنویسید')
							return
						}
						// tell our component that we are sending a message
						this.sendingMessage = this.message
						this.socket.send(JSON.stringify(this.message))
						break;
					case 'file':
						// tell our component that we are sending a message
						this.sendingMessage = this.message
						this.socket.send(JSON.stringify(this.message))
						break;

					case 'read_update': // send a read signal
						this.socket.send(JSON.stringify(this.message))
						break;
				}
			}

			this.message = JSON.parse(JSON.stringify(defaultMessageTemplate))

			setTimeout(() => {
				this.$refs.messageInput.$refs.input.focus()
			}, 300)

		},
		connectToWS() {
			if(this.autoConnectTimerID) clearTimeout(this.autoConnectTimerID); // delete the timer so we dont wreck the server
			
			if(this.socket) // if previous sockets exists? 
				this.cancelKeepAlive(); // close it danngit! too many requests already made

			// return authorization header with jwt token
			let token = localStorage.getItem('token');
			
			let isAdmin = this.admin ? '&admin=true' : ''

			// this.socket = new WebSocket("wss://ketab.ahur.ir:8080/?room="+this.Room.id+"&access_token="+token+isAdmin);
			this.socket = new WebSocket("wss://ketab.ahur.ir:5080/?room="+this.Room.id+"&access_token="+token+isAdmin);
			
			// Socket Created
			this.socket.onopen = () => {
				
				// console.log("Connected.");

				// Let the Components know that the Socket has been opened
				this.isOnline = true

				// keep this socket open as long as possible
				// please do note that we will close this socket at appropriate time
				this.keepAlive();
			};
			
			// Socket Life Ended
			this.socket.onclose = (event) => {

				// Socket is now offline
				this.isOnline = false
				if(!this.clientClouser) { // if client closed the chat
					var timeout = 1000;  
					this.autoConnect.status  = 'connecting'
					this.autoConnectTimerID = setTimeout(this.connectToWS, timeout); 
					this.cancelKeepAlive();
				}
				// Was it a valid closure ?
				if (event.wasClean) {
					// Yes, we issued this
					// console.log('Connection closed');
				} else {
					// No, something bad has happened and connection been interrupted
					// console.log('Connection failure');
				}

				// console.log('Code: ' + event.code + ' Reason: ' + event.reason);
				

				// Cannot keep alive anymore
				this.cancelKeepAlive();
			};

			// New Socket message
			this.socket.onmessage = (event) => {
				
				// Load Resources
				var messageRecievedAudio = new Audio(require('@/assets/sound/recieved.mp3')  )
				// {
				// 	play: function () {
				// 		return ''
				// 	}
				// }

				// Do we have information ?
				if(event.data) {
					
					// Parse the information
					var c = JSON.parse(event.data)

					if(!(Object.keys(this.sendingMessage).length === 0)) {
						// If User has recently sent a message, 
						// then we check if the incoming message is the pong from our ping
						// if so then we deleting **sending message template**

						if(c.user_id == this.account.user.id) {
							// The incoming message must be from the current user 

							switch (c.type) {
								case 'text':
									if(c.message == this.sendingMessage.message) {
										// we are now determined that this is the message that our user just sent (we pinged, server ponged), 
										// hopefully we are right, due to budget cuts, we no longer feel like these 
										// improvements are important for the project manager

										this.sendingMessage = {} // we done with this, it'll loop back on
									}
									break;
								case 'file':
									if(c.message.name == this.sendingMessage.file.name &&
										c.message.caption == this.sendingMessage.message) {
										// we are now determined that this is the message that our user just sent (we pinged, server ponged), 
										// hopefully we are right, due to budget cuts, we no longer feel like these 
										// improvements are important for the project manager
										// if(this.sendingMessage.name == c.message.name)
										this.sendingMessage = {} // we done with this, it'll loop back on
									}
									break;
							
								default:
									break;
							}
						}

					}
					// does the data contain our required data structure ?
					if(c && c.type) {
						// c.user_id && c.message) {
						
						// Perform appropriate action based on the requests type
						switch (c.type) {
							case 'text':
								// text message being sent
								if(c.user_id !== this.account.user.id) {
									// send notification
									
									messageRecievedAudio.play();

									let msg = {
										'room': this.Room.id,
										'type': 'read_update',
										'message' : {
											user_id: this.account.user.id,
											message: c.id
										} 
									}
									this.message = msg
									this.sendMessage()
								}
								break;
							case 'file':
								// onmessage
								

								// text message being sent
								if(c.user_id !== this.account.user.id) {
									// send notification 
									messageRecievedAudio.play();

									let msg = {
										'room': this.Room.id,
										'type': 'read_update',
										'message' : {
											user_id: this.account.user.id,
											message: c.id
										} 
									}
									this.message = msg
									this.sendMessage()
								}

								break;
							case 'read_update': // a message has been read
								this.markAsRead(c.message.message)
								break;
						
							default:
								break;
						}
						this.recievedMessage(c)
					}
				}
			};

			this.socket.onerror = (error) => {
				console.log("Error: " + error.message);
				if(!(Object.keys(this.sendingMessage).length === 0)) {
					this.sendingMessage.FAILURE = error
				}
				
				this.cancelKeepAlive();
			};
		},
		
		keepAlive() { 
			// these will fuck up the server at some point
			var timeout = 20000;  
			if (this.socket && this.socket.readyState == this.socket.OPEN) {  
				this.socket.send('');  
			}  
			this.timerID = setTimeout(this.keepAlive, timeout);  
		},
		cancelKeepAlive() {

			if (this.socket && this.socket.readyState == this.socket.OPEN) { 
				this.socket.close()
			}

			if (this.timerID) {  
				clearTimeout(this.timerID);  
			}
			this.socket = null
		},
		killShot() {

			this.firstTimeScroll = true
			this.clientClouser 	 = true // let the component know not try to retry
			this.cancelKeepAlive(); // close it danngit! too many requests already made
			// remove connection retryer
			if(this.autoConnectTimerID) clearTimeout(this.autoConnectTimerID); // delete the timer so we dont wreck the server
		}
	},
	beforeDestroy() {
		this.killShot()
	},	
	watch: {
		'ChatPanel.drawer.currentRoom.status': {
			handler: function(newStatus) { 
				switch (newStatus) {
					case 'roomClosed':
					case 'roomDeleted':
						this.dialog = false
						break;
				
					default:
						break;
				}
			},
			// deep: true
		},
		'message.file.size': {
			handler: function(size) {
				if( typeof size == 'number') {
					this.humanFileSize(size).then((readable) => {
						this.message.file._size = readable
					})
				}
				this.fixHeights()
			}
		}, 
		// Room
		'ChatRoom.messages': {
			handler: function(newMessages){
				this.fixHeights()
				if(!newMessages) return false // no messages

				if(this.ChatRoom && this.ChatRoom.messages && this.ChatRoom.messages.length) {
					setTimeout(() => {

							if(this.firstTimeScroll) {
								this.scrollBottom()
							}
							else {

								var h = this.$refs.conversation.$el, 
										b = document.body,
										st = 'scrollTop',
										sh = 'scrollHeight';
								
								var scrollPassed = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100
								if(scrollPassed > 30) {
									this.scrollBottom()
								}
							}
							
					},800)
				}
			},
			deep: true
		},
		dialog: function(isOpen) {
			document.querySelector('html').classList.toggle('application--dialog-opened', isOpen);
			if(isOpen){
				this.clientClouser = false // this is so we can know when to activate auto reconnection proccess
				this.enterRoom(this.Room.id)
				this.connectToWS() 
			}
			else
			{
				this.killShot()
			}

		} 
	}
}
</script>