<template>
  <v-app id="admin-layout" class="cyan lighten-4" style="background: #eff5fb">
    <v-system-bar 
          
      color="blue lighten-4"
      class="unselectable"
      app
    >
    asd
    </v-system-bar>
    asd
  </v-app>
</template>
<script>

export default ({
    name: 'SupportPanel',
    setup() {
        
    },
})
</script>
