<template>
<div>
	<v-autocomplete
		v-model="model"
		:items="items"
		:loading="isLoading"
		:multiple="multi"
		:chips="chips"

		:search-input.sync="search"
    item-value="value"
		hide-no-data
		hide-selected
		label="جستجو"
		placeholder="جهت جستجو شروع به نوشتن کنید"
		prepend-icon="mdi-bookshelf"
	></v-autocomplete>
</div>
</template>
<script>
	import { WebsiteService } from '@/services/website.service';
  export default {
		name: "BookSelector",
		props: {
			chips: {},
			multi: {},
			value: {},
			type : { },
		},
    data () {
      return {
        descriptionLimit: 60,
        entries: [],
        isLoading: false,
        model: this.value,
        search: null,
        abort: new AbortController(),
      }
    },

    computed: {
      fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      items () {
        return this.entries.map(entry => {

          return Object.assign({}, entry)
        })
      },
    },

    watch: {
      search (val) {
        
        val
        // Items have already been loaded
        // if (this.items.length > 0) return

        // Items have already been requested, abort previous request and make new one
        if (this.isLoading) {
					if(this.abort) {
						this.abort.abort()
					}
					this.abort = new AbortController()
        }
				// if user has not yet selected an entry
				if(typeof val === 'string') this.doLoad();
      },
			model: {
				handler: function(e){
					this.$emit("on-select", e)
					this.search = null

				}
			}
    },
		methods : {
			doLoad(){ 
        this.isLoading = true

        // Lazily load input items
        WebsiteService.requestSearch({
            query: this.search,
            
        }, this.abort.signal)
          .then(res => {
            const { count } = res
						let output = []
						console.log(res)
						Object.keys(res.data.final).forEach((key) => {
							output.push({
								text: res.data.final[key].name,
								value: res.data.final[key].id,
							})
						})
            this.count = count
            this.entries = output

						// alert(typeof this.model)
            if(this.value) this.model = this.value
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
			}
		},
		created(){
			this.doLoad() 
		},
 
  }
</script>