<template>
	<div>

		<div :class="{ loader: true, fadeout: !isLoading }">
		<v-progress-circular
		style="position: absolute;
top: 50%;
transform: translate(0, -50%);
right: calc(50vw - 30px);
padding: 10px;"
      :width="3"
			size="80"
      indeterminate
    ></v-progress-circular>

		</div>

	</div>
</template>

<script>
export default {
  name: "SplashScreen",
  props: ["isLoading"],
	mounted(){


    this.interval = setInterval(() => {
      if (this.loaded === 100) {
        this.interval = {}
        return (this.value = 0)
      }
      this.loaded += 10
    },300)
	},
  data: () => ({
    interval: {},
    loaded: 0,
  }),
	watch:{
		isLoading(loading){

			if(!loading)
				this.loaded = 100
		}
	}
};
</script>

<style>
.loader {
  background-color: #63ab97;
  bottom: 0;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
}

.fadeout {
  animation: fadeout 2s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
