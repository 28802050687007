import { userService } from '../services';
import router from '../router'

const user = JSON.parse(localStorage.getItem('user'));
const remember = JSON.parse(localStorage.getItem('remembered'));
const userAccess = []
if(user)
{
    Object.keys(user.access).forEach(function(key) {
        userAccess.push(user.access[key])
    });
    user.access = userAccess
}
const state = user
    ? { 
		status: { loggedIn: true },
		user,
		verification: {},
		updateRequired: false,
	}
    : {
		status: {},
		user: null,
		verification: {},
		updateRequired: false,
	};

state.remembered = {
    token: remember ? remember.token : null,
    expire: remember ? remember.expire : null,
}
state.alert = {
    type: null,
    title: null,
    message: null,
},
state.panel = {
    drawer: {

    }
}
state.recovery = {
    loading     : null, // When the element is not set in object, it cannot be re-set later on
    type        : null, // SMS, Email
    ID          : null, // Phone, Email
    code        : null, // Verification Code
    validated   : null, // 
    messages    : [],   // api messages
    errors      : [],   // api errors
    FINISHED    : false,
}
/**
 * @param (bool)  refreshing    whether or not we are refreshing current session
 */
state.refreshing = false

const actions = {
    recover({ dispatch, commit }, { type, id }) {
        commit('recoveryRequest', { type, id });
        userService.recover(type, id)
            .then(
                user => {
                    
                    commit('recoverySuccess', user.account);
                    // router.push('/panel/account');
                },
                error => {
                    console.log(error);
                    commit('recoveryFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

    validateEmail({ commit }, code) {
        commit('emailVerificationRequest');

        userService.validateEmail(code)
            .then(
                response => {
					commit('updateUserData')
					commit('EmailValidationSuccess', response)
				},
                error => commit('EmailValidationFailure', error)
            );
    },

    validatePhone({ commit }, code) {
        commit('emailVerificationRequest');

        userService.validatePhone(code)
            .then(
                response => {
					commit('updateUserData')
					commit('EmailValidationSuccess', response)
				},
                error => commit('EmailValidationFailure', error)
            );
    },

    sendEmailVerificationCode({ commit }) {
        commit('emailVerificationRequest');

        userService.sendActivationEmail()
            .then(
                response => commit('sendEmailVerificationCodeSuccess', response),
                error => commit('sendEmailVerificationCodeFailure', error)
            );
    },

    sendPhoneVerificationCode({ commit }) {
        commit('emailVerificationRequest');

        userService.sendActivationPhone()
            .then(
                response => commit('sendPhoneVerificationCodeSuccess', response),
                error => commit('sendPhoneVerificationCodeFailure', error)
            );
    },

    requestRecovery({ commit }, { type, ID }) {
        commit('recoveryLoading', { type });
    
        userService.requestRecovery(type, ID).then(
                response => {
                    commit('recoveryRequestSuccess', {type, ID});
                    response
                },
                error => {
                    
                    commit('recoveryRequestFailure', {type, ID, error});
                }
            );
    },

    validateRecovery({ commit }, { type, ID, code }) {
        commit('recoveryLoading', { type });
    
        userService.validateRecoveryRequest(type, ID, code).then(
                response => {
                    commit('validateRecoverySuccess', {type, ID, code});
                    response
                },
                error => {
                    error
                    commit('validateRecoveryFailure', {type, ID, code,error});
                }
            );
    },

    recoverPassword({ dispatch, commit }, { type, ID, code, newPassword }) {
        commit('recoveryLoading', { type });
    
        userService.recoverPassword(type, ID, code, newPassword).then(
                response => {
                    dispatch('alert/success', response.message, { root: true });
                    commit('recoverPasswordSuccess', {type, ID, code});
                    response
                },
                error => {
                    error
                    commit('recoverPasswordFailure', {type, ID, code,error});
                }
            );
    },

    login({ dispatch, commit }, { phone_number, password, remember }) {
        commit('loginRequest', { phone_number });
    
        userService.login(phone_number, password, remember)
            .then(
                response => {
                    commit('loginSuccess', response.data);

                },
                error => {
                    console.log(error);
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

    refresh({ dispatch, commit }, { uid, token }) {
        // alert(state.remembered.token)
        
        commit('refreshRequest', { token });
        userService.refresh(uid, token)
            .then(
                response => {
                    response.data.refresh = true
                    commit('loginSuccess', response.data);

                },
                error => {
                    console.log(error);
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    register({ dispatch, commit }, user) {
        commit('registerRequest', user);

		userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    router.push('/authentication/login');
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', 'Registration successful', { root: true });
                    })
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    }
};

const mutations = {

	error(state, data) {
		state.result = data.data ? data.data : ( data.message ? data.message : data.status) 
		state.status  = data.status
		

		if(!data.OK && data.errors) {
			state.alert.type = 'error'
			state.alert.title = 'خطا'
			Object.keys(data.errors).forEach((errorKey) => {
				state.alert.message = (state.alert.message ? state.alert.message : '') + "<p>"+data.errors[errorKey]+"</p>"
			})
		}
	},
	recoveryLoading(state) {
        state.recovery.loading  = true
	},
	validateRecoverySuccess(state, {type, ID} ) {
        state.recovery.FINISHED      = false
        state.recovery.loading      = false
        state.recovery.type         = type
        state.recovery.code         = 'sent'
        state.recovery.ID           = ID
        state.recovery.validated    = true
        state.recovery.errors       = []
	},
	validateRecoveryFailure(state, {type, ID, code, error}) {
        code
        state.recovery.FINISHED      = false
        state.recovery.loading      = false
        state.recovery.type         = type
        state.recovery.code         = 'sent'
        state.recovery.ID           = ID
        state.recovery.validated    = false
        if(error && error.messages) state.recovery.errors       = error.messages
	},
	recoverPasswordSuccess(state) {
        state.recovery = {
            loading     : null, // When the element is not set in object, it cannot be re-set later on
            type        : null, // SMS, Email
            ID          : null, // Phone, Email
            code        : null, // Verification Code
            validated   : null, // 
            messages    : [],   // api messages
            errors      : [],   // api errors
            FINISHED: true
        }
	},
	recoverPasswordFailure(state, {type, ID, code, error}) {
        code
        state.recovery.loading      = false
        state.recovery.type         = type
        state.recovery.code         = 'sent'
        state.recovery.ID           = ID
        state.recovery.validate     = true // code is still validated, 
        if(error && error.messages) state.recovery.errors       = error.messages
	},
	recoveryRequestSuccess(state, {type, ID} ) {
        state.recovery.FINISHED      = false
        state.recovery.loading  = false
        state.recovery.type     = type
        state.recovery.code     = 'sent'
        state.recovery.ID       = ID
        state.recovery.errors   = []
	},
	recoveryRequestFailure(state, {type, ID, error}) {
        state.recovery.FINISHED      = false
        state.recovery.loading  = false
        state.recovery.type     = type
        state.recovery.code     = null
        state.recovery.ID       = ID
        state.recovery.validate = false
        if(error && error.messages) state.recovery.errors       = error.messages 
    },

	updateUserData(state){
        // userService.refresh(type, id)
		console.log(state);
		userService.getCurrentUser()
			.then(
				user => {
					state.UNABLE_TO_CONNECT = false;
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					localStorage.setItem('user', JSON.stringify(user.user));
					Object.keys(user.user.access).forEach(function(key) {
						userAccess.push(user.user.access[key])
					});
					user.user.access = userAccess
					state.user = user.user;
				},
				error => {
					state.UNABLE_TO_CONNECT = error;
				}
			)

	},
    emailVerificationRequest(state) {
        state.verification = { loading: true };
    },
    sendEmailVerificationCodeSuccess(state, response) {
        state.verification = { response: response };
    },
    sendEmailVerificationCodeFailure(state, error) {
        state.verification = { error };
        console.log(error)
    },
    sendPhoneVerificationCodeSuccess(state, response) {
        state.verification = { response: response };
    },
    sendPhoneVerificationCodeFailure(state, error) {
        state.verification = { error };
        console.log(error)
    },
    EmailValidationSuccess(state, response) {
        state.verification = { response: response };
    },
    EmailValidationFailure(state, error) {
        state.verification = {error: error.message ? error.message : error.messages  };
    },
	
    refreshRequest(state, token) {
        token
        state.refreshing = true;
    },

    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, response) {

        // // login successful if there's a jwt token in the response
        if (response.token && response.user) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(response.user));
            localStorage.setItem('expire', response.expire);
            localStorage.setItem('token', response.token);
            localStorage.setItem('remembered', JSON.stringify(response.remembered));
            
            
        }
        
        state.remembered = {
            token: response.remembered.token,
            expires: response.remembered.expires,
        }

        state.status = { loggedIn: true };
        state.user = response.user;
        if(response.refresh){
            state.refreshing = false;
            window.location.reload()
        }
        else
            //
            window.location.href = ("/panel/account")
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state, user) {
		user;
        state.status = { registering: true };
    },
    registerSuccess(state, user) {
		user;
        state.status = {};
    },
    registerFailure(state, error) {
		error;
        state.status = {};
    },
    recoveryRequest(state, user) {
		user;
        state.status = { registering: true };
    },
    recoverySuccess(state, user) {
		user;
        state.status = {};
    },
    recoveryFailure(state, error) {
		error;
        state.status = {};
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};