<template>
  <v-container>
    <v-main >
      <v-row>

        <v-col cols="12" md="9">
          <v-chip-group
            mandatory
            show-arrows=""
            active-class="primary--text"
          >
            <v-chip
              v-for="tag in tags"
              :key="tag" :to="tag.link"
            >
              {{ tag.name }}
            </v-chip>
          </v-chip-group>


          <CustomizedBookShelf
            dynamic
            prefix="home_page"

            class="mt-10" />

        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <v-sheet class="pa-1"  rounded="sm">
            <books-tree-category/>
          </v-sheet>
        </v-col>
      </v-row>
   </v-main>
  </v-container>
  
</template>

<script>
import BooksTreeCategory from '@/components/BooksTreeCategory.vue'
import CustomizedBookShelf from '@/components/CustomizedBookShelf.vue'

  export default {
    name: 'HomePage',

    components: {
        BooksTreeCategory,
        CustomizedBookShelf
    },
    data(){
      return {
        tags :[
          {
            name: 'کتاب‌های صوتی',
            link: '/shop/explore/tags',
          },
          {
            name: 'کتاب‌های رایگان',
            link: '/shop/explore/tags',
          },
          {
            name: 'تخفیف‌های ویژه امروز ',
            link: '/shop/explore/tags',
          },
          {
            name: 'پرفروش‌های ماه',
            link: '/shop/explore/tags',
          },
          {
            name: 'کتاب‌های برگزیده',
            link: '/shop/explore/tags',
          }
        ]
      }
    }
  }
</script>
