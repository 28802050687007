<template>
	<v-system-bar fixed style="z-index: 90999" :color="color" dir="ltr" dark>
		
		<TransitionGroup name="system_tools" tag="div">
			<system-helper-tool key="1" v-if="this.$route.meta.help"/>
			<!-- <system-chat-support key="2"/> -->
		</TransitionGroup>

      
      <v-spacer></v-spacer>
      <v-chip  x-small outlined>
        {{date.toString('jdddd jDo jMMMM')}}
      </v-chip>
      <v-spacer></v-spacer>

		<!-- <v-spacer />
		<v-spacer />
		<v-btn
			:to="account.status.loggedIn ? '/panel/account' : '/authentication/login'"
			dark
			v-if="this.$vuetify.breakpoint.name !='xs'"
			text
			class="green"
			color="white"
			x-small
		>
			بخش کاربری <v-icon class="ml-1">mdi-account-circle</v-icon>
		</v-btn>
		<v-btn
			:to="account.status.loggedIn ? '/panel/account' : '/authentication/login'"
			dark
			v-if="this.$vuetify.breakpoint.name =='xs'"
			class="green"
			color="white"
			x-small
		>
			<v-icon class="ml-1">mdi-account-circle</v-icon>
		</v-btn>

		<v-divider
			vertical class="ml-2 mr-2"
		/>
		
		<v-btn
			to="/"
			dark
			text
			color="white"
			x-small
			style="color:#333"
		>
			مجموعه اهل سخن <v-icon color="#fff" class="ml-1">mdi-home</v-icon>
		</v-btn> -->
	</v-system-bar>
</template>


<script>

import SystemHelperTool from "@/components/SystemHelperTool.vue";
// import SystemChatSupport from '@/components/SystemChatSupport.vue';
import {PersianDate} from '@alireza-ab/vue-persian-datepicker'
export default ({
	props: {
		color: { type: String },
		account: { type: Object },
	},
	name: 'SystemBar',
  components:{
    SystemHelperTool,
    // SystemChatSupport,
  },
	created(){

	},
	data() {
    return {
		date: new PersianDate(),
		}
  },
	methods: {
	},


})
</script>
