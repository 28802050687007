import { UserPanelService } from '../../services';

const state = {
	drawer: {
		header: null,
		loading: true,
		open: false,
		items: {}
	},
	alert: {
		type: null, // success, info , warning, error
		title: null,
		message: null,
	},
	status: 'loading',
	result: null,
	action: null,

	RequestController: new AbortController(), // Fetch API Request
};


const actions = {
	requestPanelData({ commit }) {
		commit('PanelDataRequested', 'requestPanelData');

		UserPanelService.getUserPanelData().then(
			result => commit('PanelDataDone', result.data),
			error => commit('PanelDataFailed', error)
		)

	},

	/**
	 * Request a Support Chat Room
	 * @param {*} commit 
	 * @param {*} request 
	 */
	requestSupport({ commit }, request) {
		commit('RequestStarted', 'requestSupport');

		UserPanelService.requestSupport(request, state.RequestController.signal).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	}, 

	/**
	 * Request Client Dashboard data
	 * @param {*} commit 
	 * @param {*} section 
	 */
	requestDashboard({ commit }, section) {
		commit('RequestStarted', 'requestDashboard');

		UserPanelService.getIndex(section).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	}, 

	/**
	 * Request User Save Profile
	 * @param {*} commit 
	 * @param {*} section 
	 */
	saveProfile({ commit }, User) {
		commit('RequestStarted', 'saveProfile');

		UserPanelService.saveProfile(User).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	}, 

	/**
	 * Request List of Projects available to order
	 * @param {*} commit 
	 * @param {*} section 
	 */
	requestProjectsToOrder({ commit }) {
		commit('RequestStarted', 'requestProjectsToOrder');

		UserPanelService.getAvailableProjects().then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	}, 
	
	/**
	 * Request My Invoices
	 * @param {*} commit 
	 * @param {*} filters
	 */
	requestMyInvoices({ commit }, filters) {
		commit('RequestStarted', 'requestMyInvoices');

		UserPanelService.requestInvoices(filters, state.RequestController.signal).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	}, 

	/**
	 * Request My Referred users
	 * @param {*} commit 
	 */
	getMyRefers({ commit }) {
		commit('RequestStarted', 'requestMyRefers');

		UserPanelService.requestMyRefers(state.RequestController.signal).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	},

	/**
	 * Request My Invoices
	 * @param {*} commit 
	 * @param {*} filters
	 */
	requestWalletTransactions({ commit }, filters) {
		commit('RequestStarted', 'requestWalletTransactions');

		UserPanelService.requestWalletTransactions(filters, state.RequestController.signal).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	},

	/**
	 * Request Get Invoice
	 * @param {*} commit 
	 * @param {*} ID
	 */
	getInvoice({ commit }, ID) {
		commit('RequestStarted', 'getInvoice');

		UserPanelService.getInvoice(ID, state.RequestController.signal).then(
			invoice => commit('RequestDone', invoice),
			error => commit('error', error)
		)
	}, 

	/**
	 * Request To Pay Invoices
	 * @param {*} commit 
	 * @param {*} ID
	 */
	requestPayment({ commit }, invoice) {
		commit('RequestStarted', 'requestPayment');

		UserPanelService.requestInvoicePayment(invoice, state.RequestController.signal).then(
			invoice => commit('RequestDone', invoice),
			error => commit('error', error)
		)
	}, 

	/**
	 * Request Buy Wallet Credit 
	 * @param {*} commit 
	 * @param {*} Amount
	 */
	buyWalletCredit({ commit }, Amount) {
		commit('RequestStarted', 'buyWalletCredit');

		UserPanelService.buyWalletCredit(Amount).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	}, 

	/**
	 * Request search Collaborators
	 * @param {*} commit 
	 * @param {*} Amount
	 */
	searchCollaborators({ commit }, filters,) {
		commit('RequestStarted', 'searchCollaborators');

		UserPanelService.searchCollaborators(filters, state.RequestController.signal).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	},
};

const mutations = {
	RequestStarted(state, action) {
		
		state.result = null
		state.action = action
		state.status  = 'loading'

		state.alert = {
			type: null,
			title: null,
			message: null,
		}

		if(state.status == 'loading')
		{
			state.RequestController.abort()
			state.RequestController = new AbortController()
		}
	},
	RequestDone(state, data) {
		state.result = data.data ? data.data : ( data.message ? data.message : data.status) 
		state.status  = 'done'
		if(state.action == 'saveProfile'){ 
			//
			if(data.data.user) {
				localStorage.setItem('user', JSON.stringify(data.data.user));
				window.location.reload()
			}
		}
		if(state.action == 'requestSupport'){ 
			//
			this.dispatch('ChatPanel/loadDrawerData', {   });
		}

		if(data.relogin) {
			this.dispatch('account/logout', {});
		}

		if(data.message) {
			state.alert.type = 'success'
			state.alert.title = 'انجام شد'
			state.alert.message = "<p>"+data.message+"</p>"

		} else if(data.messages) {
			state.alert.type = 'success'
			state.alert.title = 'انجام شد'
			
			Object.keys(data.errors).forEach((errorKey) => {
				state.alert.message = (state.alert.message ? state.alert.message : '') + "<p>"+data.errors[errorKey]+"</p>"
			})
		}
	},

	error(state, data) {
		if(data == 'AbortError: The user aborted a request.')
			return true; // cool
		state.result = data.data ? data.data : ( data.message ? data.message : data.status) 
		state.status  = 'error'
		
		if(data.relogin) {
			this.dispatch('account/logout', {});
		}
		
		if(data.errors) {
			state.alert.type = 'error'
			state.alert.title = 'خطا'
			Object.keys(data.errors).forEach((errorKey) => {
				state.alert.message = (state.alert.message ? state.alert.message : '') + "<p>"+data.errors[errorKey]+"</p>"
			})
		} else if(data.message) {
			state.alert.type = 'error'
			state.alert.title = 'خطا'
			state.alert.message = "<p>"+data.message+"</p>"
		} 
	},

	/**
	 * Panel Data Request has been made
	 * @param {*} state 
	 */
	PanelDataRequested(state) {
		state.drawer.loading = true
		state.drawer.header = null
	},

	/**
	 * Panel Data Request has finished with data
	 * @param {*} state 
	 * @param {*} data 
	 */
	PanelDataDone(state, data) {
		state.drawer.loading = false

		if(data.header) {
			state.drawer.header = data.header
		}
		if(data.items) {
			state.drawer.items = data.items
		}

	},

	/**
	 * Failed to get panel data
	 * @param {*} state 
	 * @param {*} data 
	 */
	PanelDataFailed(state, data) {

		if(data == 'AbortError: The user aborted a request.')
			return true; // cool

		state.drawer.loading = false
		
		state.drawer.header = null
		// alert(JSON.stringify(data))
	},

	clear(state) {
		state.result = null
		state.type = null;
		state.status = null;

		state.alert = {
			type: null,
			title: null,
			message: null,
		}
	}
};

export const UserPanel = {
	namespaced: true,
	state,
	actions,
	mutations
};