<template>
  <div id="orderspage"  class="hasbasefoot">
    <v-container>
      <div dir="ltr">
        <carousel-3d
          :controls-visible="true"
          :disable3d="false"
          :autoplay="true"
          :autoplay-timeout="8000"
          :display="3"
          :width="900" :height="$vuetify.breakpoint.smAndDown ? 500 : 350"
          :class="$vuetify.breakpoint.xs ? 'pt-8' : ''"
        >
          <slide :index="0">
            <img width="100%" height="100%" src="https://ahlesokhan.com/wp-content/uploads/revslider/slider-1/slide-ahlesokhan-desk3_0-768x341.jpg">
          </slide>
          <slide :index="1">
            <img width="100%" height="100%" src="https://ahlesokhan.com/wp-content/uploads/revslider/slider-1/slide-ahlesokhan-desk2_0.jpg">
          </slide>
          <slide :index="2">
            <img width="100%" height="100%" src="https://ahlesokhan.com/wp-content/uploads/revslider/slider-1/slide-ahlesokhan-desk1_0-768x341.jpg">
          </slide>
        </carousel-3d>
      </div>
    </v-container>
    <v-divider class="mt-5 mb-5"></v-divider>
    <v-row
      v-for="(service, index) in serviceCategorySliders"
      :index="index"
      :key="index"
      :style="{'background-color': service.color ? service.color : ''}" 
      class=" accent-9 no-gutters ma-0 mb-10"
    >
      <v-col :key="service.color">
        <v-container style="max-width: 1200px">
          <h3 style="padding: 16px 26px 6px 26px; background: #FFF; border-radius: 15px; display: inline-block; position: relative; top:-25px" class="pt-4">{{service.name}}</h3>
          <br/>
          <v-row>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3">
              <v-card height="100%" flat color="accent-3" style="border-radius: 15px;padding-top: 5px;position: relative">
                <div v-if="service.images && service.images.category" style="position: absolute; left: 0px; top: 0px; width: 100%; height: 100%;">
                  <v-img width="100%" :src="service.images.category.path"></v-img>
                </div>
                <div class="pa-2 ma-5" style="border-radius: 15px; color: #fff; background-color:rgba(0,0,0,.7); position: relative; z-index:1">
                  {{service.description}}
                </div>
                
              </v-card>
            </v-col>
            <v-col cols="12" md="9" lg="9" xl="9" dir="ltr">


              <Flicking :options="FlickingOptions" :plugins="ServicesFlickingPlugins[index]">
                <div
                 style="width: 320px;" class="ma-1" v-for="(form, i) in service.forms" :key="i">
                  <v-card
                    dir="rtl"
                    flat
                    height="100%"
                  >
                    <v-card-title>{{form.name}}</v-card-title>
                    <v-card-text>
                      {{form.description}}
                    </v-card-text>
                      
                    <v-row class="no-gutters">
                      <v-col>

                        <v-card dark color="green" class="text-center ma-2 pa-2 mb-0">
                          <v-icon>mdi-check</v-icon>
                          <br/>
                          
                          <v-badge
                            color="green darken-3"
                            offset-y="-25px"
                            offset-x="15px"
                            :content="kFormatter(36980)"
                            left
                          >
                            انجام شده
                          </v-badge>

                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card dark color="orange" class="text-center ma-2 pa-2 mb-0">
                          <v-icon>mdi-timelapse</v-icon> 
                          <br/>
                          <v-badge
                            color="orange darken-3"
                            offset-y="-25px"
                            offset-x="15px"
                            :content="kFormatter(36980)"
                            left
                          >
                            <span style="font-size:15px">درحال انجام</span>
                          </v-badge>

                        </v-card>
                      </v-col>
                      <v-col>

                        <v-card dark color="brown" class="text-center ma-2 pa-2 mb-0">
                        
                          
                        </v-card>
                      </v-col>
                    </v-row>

                  </v-card>
                </div>
                <span slot="viewport" class="flicking-arrow-prev">prev</span>
                <span slot="viewport" class="flicking-arrow-next">msd</span>
              </Flicking>
            </v-col>
          </v-row>

        </v-container>
      </v-col>
    </v-row>
    <v-container>

    </v-container>
  </div>
</template>
<style scoped>

@import "@egjs/vue-flicking/dist/flicking.css";
@import "@egjs/vue-flicking/dist/flicking-inline.css";

#homepage .carousel-3d-container{
  height: 330px !important;
}
.clslide{
  margin: 20px;
  box-shadow:0px 0px 10px rgba(0,0,0,0.5);
  border-radius: 5px; border: transparent; background: transparent
}
.cx_img {
  direction: rtl;
  color: #fff;
  padding: 15px;
  position:relative;
  height:150px;
  overflow:hidden;
  text-align: right;
}
.cx_img img {
  position: absolute;
  left: 0px;
  top:0px;
}

</style>
<script>
import { mapState, mapActions } from 'vuex'
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { Flicking } from "@egjs/vue-flicking";
import { Arrow,Perspective , Fade } from "@egjs/flicking-plugins";

export default ({
  components: {
    Carousel3d,
    Slide,
    Flicking: Flicking,
  },

  data() {
    return {
      FlickingOptions: {
        circularFallback: "linear",
        circular: true

      },  
      ServicesFlickingPlugins: [],
      serviceCategorySliders: [
        [],
        [],
        [], // empty means display loading placeholders
      ]
    }
  },
  computed: {
    ...mapState({
      account: state => state.account,
      website: state => state.website,
    }),
  },
  methods: {
    kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    },
    reached(serviceIndex, formIndex){
      serviceIndex, formIndex
    },
    ...mapActions('website', {
      loadPage: 'loadPage',
    }),
  },
  created(){
    if(!this.account.loggedIn) this.$router.push({
        name: 'LoginPage', 
        params: { }
      });
    else 
      this.$router.push({
        name: 'UserDashboard', 
        params: {  }
      });

    // no more home page :( 
    // this.loadPage('home')
  },
  watch:{
    'website.status': function(){
      switch (this.website.status) {
        case 'done': 
          this.ServicesFlickingPlugins = []
          this.serviceCategorySliders = [] 
          Object.keys(this.website.result.services).forEach((key) => { 
            this.ServicesFlickingPlugins.push([
              new Arrow(),
              new Perspective({ rotate: 0.1 }),
              new Fade() 
            ])

            if(this.website.result.services[key].images.cover) {
              this.website.result.services[key].images.cover = JSON.parse(this.website.result.services[key].images.cover)
            }
            if(this.website.result.services[key].images.category) {
              this.website.result.services[key].images.category = JSON.parse(this.website.result.services[key].images.category)
            }
            this.serviceCategorySliders.push(this.website.result.services[key]) 

            
          })

          break;
      
        default:
          break;
      }
    },
  }
})
</script>
