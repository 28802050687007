// Important data
import { authHeader, config, handleResponse } from '../helpers';

// ------------------------------------------------------------

/**
 * formService exportable constant
 */
export const formService = {
    getForm,
    getCategories,
    saveForm
};

// ------------------------------------------------------------

/**
 * Get Form By Section
 * 
 * @param {string} section  Form Section 
 * @returns Promise
 */
function saveForm(formData, signal){
    
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            signal,
            'Accept': 'application/json',
        },
        body: formData
    };
    return fetch(`${config.apiUrl}/client/forms/request`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get Form By Section
 * 
 * @param {string} section  Form Section 
 * @returns Promise
 */
function getForm(section, signal){

    const requestOptions = {
        signal,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/forms/section?form=${section}`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get Form By Section
 * 
 * @returns Promise
 */
function getCategories(signal){

    const requestOptions = {
        signal,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/admin/custom_forms/categories`, requestOptions).then(handleResponse);
} 