import { authHeader, config, handleResponse} from '../helpers';
export const userService = {
    refresh,
    login,
    logout,
    register,
    getCurrentUser,

    getAll,
    getById,
    update,
    sendActivationEmail,
    validateEmail,
    
    sendActivationPhone,
    validatePhone,
    
    // when not logged in
    requestRecovery,
    validateRecoveryRequest,
    recoverPassword,

    delete: _delete
};

function refresh(uid, token) {
    const requestOptions = {
        method: 'POST',
        // added auth bearer so we can know if current use owns a token or not
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({uid, token })
    };

    let promise = fetch(`${config.apiUrl}/users/validate`, requestOptions).then(handleResponse)

    return promise ;
}

function requestRecovery(type, auth, remember) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type, auth, remember})
    };

    let promise = fetch(`${config.apiUrl}/users/request_token`, requestOptions).then(handleResponse)

    return promise ;
}

function validateRecoveryRequest(type, auth, code) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type, auth, code})
    };

    let promise = fetch(`${config.apiUrl}/users/validate_token`, requestOptions).then(handleResponse)

    return promise ;
}

function recoverPassword(type, auth, code, newPassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type, auth, code, newPassword})
    };

    let promise = fetch(`${config.apiUrl}/users/reset_pw`, requestOptions).then(handleResponse)

    return promise ;
}


function login(phone_number, password, remember) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone_number, password, remember})
    };

    let promise = fetch(`${config.apiUrl}/users/authenticate`, requestOptions).then(handleResponse)

    return promise ;
}

function getCurrentUser(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/client/info`, requestOptions).then(handleResponse);
}

function sendActivationPhone() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/client/request/validate/phone`, requestOptions).then(handleResponse);
}
function validatePhone(code) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({code: code})
    };

    return fetch(`${config.apiUrl}/client/request/validate/phone`, requestOptions).then(handleResponse);
}


function sendActivationEmail() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/client/request/validate/email`, requestOptions).then(handleResponse);
}
function validateEmail(code) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({code: code})
    };

    return fetch(`${config.apiUrl}/client/request/validate/email`, requestOptions).then(handleResponse);
}
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}


function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}