<template>
	<div dark v-if="hasHelp" style="display: inline-block">

    <v-dialog
      v-model="dialog"
      persistent
      max-width="490"
    >
		<v-tooltip bottom>
			asd
      <span>Tooltip</span>
    </v-tooltip>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          v-bind="attrs"
          v-on="on" 
					icon
        >

					<v-icon>mdi-help-circle-outline</v-icon>
					<v-badge
						dot
						style="position: relative; top:-5px;"
						color="red"
					></v-badge>
        </v-btn> 
      </template>

			<!-- load all helpers here -->
			<help-home-page @close-dialog="closeDialog" :dialog="dialog" v-if="currentHelp == 'HelpHomePage'"></help-home-page>
    </v-dialog>

	</div>
</template>
<style > 
</style>

<script>

import HelpHomePage from "@/components/Help/HelpHomePage.vue";


export default ({
	name: 'SystemHelperTool',
	components:{
		HelpHomePage
	},
	created(){
    this.currentHelp = this.$route.meta.help
    this.hasHelp = this.$options.components[this.currentHelp]

	},
	data() {
    return {
			dialog: false,
			currentHelp: null,
			hasHelp: false
		}
  },
	methods: {
		closeDialog()
		{
			this.dialog = false;
		},
		HelpMe () {
			this.$refs.form.validate()
		},
	},


})
</script>
