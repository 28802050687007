import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import DefaultLayout from './layouts/default'
import AuthorsLayout from './layouts/authors'
import ShopLayout from './layouts/shop'
import AuthenticationLayout from './layouts/authentication'
import MainPanelLayout from './layouts/panels/main'
import AdminPanelLayout from './layouts/panels/admin'
import WriterPanelLayout from './layouts/panels/writer'
import SupportPanelLayout from './layouts/panels/Support'
import ReaderLayout from './layouts/pdf_reader'
import TranslatorPanelLayout from './layouts/panels/translator'

import VueMeta from 'vue-meta'


import VueMask from 'v-mask'
import moment from 'vue-jalali-moment'
import PersianCalendar from 'vue-persian-calendar';
import datePicker from '@alireza-ab/vue-persian-datepicker'
import "./helpers/Vee-ValidationRules.js"
import {VueMasonryPlugin} from 'vue-masonry';

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})


Vue.use(VueMasonryPlugin)

Vue.config.productionTip = false;

Vue.use(moment)
Vue.use(PersianCalendar)
Vue.use(VueMask);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.component('date-pick', datePicker)

Vue.component('reader-layout', ReaderLayout)
Vue.component('translator-panel-layout', TranslatorPanelLayout)
Vue.component('support-panel-layout', SupportPanelLayout)
Vue.component('admin-panel-layout', AdminPanelLayout)

Vue.component('writer-panel-layout', WriterPanelLayout)
Vue.component('main-panel-layout', MainPanelLayout)
Vue.component('authentication-layout', AuthenticationLayout)
Vue.component('default-layout', DefaultLayout)
Vue.component('authors-layout', AuthorsLayout)
Vue.component('shop-layout', ShopLayout)

new Vue({
  rtl: true,
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted(){
  },
}).$mount('#app')
