import { UserPanelService } from '../../services';

const state = {
	drawer: {
		header: null,
		loading: true,
		open: false,
		items: {}
	},
	alert: {
		type: null, // success, info , warning, error
		title: null,
		message: null,
	},
	status: 'loading',
	result: null,
	action: null,

	RequestController: new AbortController(), // Fetch API Request
};


const actions = {
	requestPanelData({ commit }) {
		commit('PanelDataRequested', 'requestPanelData');

		UserPanelService.getUserPanelData().then(
			result => commit('PanelDataDone', result.data),
			error => commit('PanelDataFailed', error)
		)

	},

	/**
	 * Request Client Dashboard data
	 * @param {*} commit 
	 * @param {*} section 
	 */
	requestDashboard({ commit }, section) {
		commit('RequestStarted', 'requestDashboard');

		UserPanelService.getIndex(section).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	},  
};

const mutations = {
	RequestStarted(state, action) {
		
		state.result = null
		state.action = action
		state.status  = 'loading'

		state.alert = {
			type: null,
			title: null,
			message: null,
		}

		if(state.status == 'loading')
		{
			state.RequestController.abort()
			state.RequestController = new AbortController()
		}
	},
	RequestDone(state, data) {
		state.result = data.data ? data.data : ( data.message ? data.message : data.status) 
		state.status  = 'done'
	},

	error(state, data) {
		if(data == 'AbortError: The user aborted a request.')
			return true; // cool
		state.result = data.data ? data.data : ( data.message ? data.message : data.status) 
		state.status  = data.status
		

		if(!data.OK && data.errors) {
			state.alert.type = 'error'
			state.alert.title = 'خطا'
			Object.keys(data.errors).forEach((errorKey) => {
				state.alert.message = (state.alert.message ? state.alert.message : '') + "<p>"+data.errors[errorKey]+"</p>"
			})
		}
	},

	/**
	 * Panel Data Request has been made
	 * @param {*} state 
	 */
	PanelDataRequested(state) {
		state.drawer.loading = true
		state.drawer.header = null
	},

	/**
	 * Panel Data Request has finished with data
	 * @param {*} state 
	 * @param {*} data 
	 */
	PanelDataDone(state, data) {
		state.drawer.loading = false

		if(data.header) {
			state.drawer.header = data.header
		}
		if(data.items) {
			state.drawer.items = data.items
		}

	},

	/**
	 * Failed to get panel data
	 * @param {*} state 
	 * @param {*} data 
	 */
	PanelDataFailed(state, data) {

		if(data == 'AbortError: The user aborted a request.')
			return true; // cool

		state.drawer.loading = false
		
		state.drawer.header = null
	},

	clear(state) {
		state.result = null
		state.type = null;
		state.status = null;

		state.alert = {
			type: null,
			title: null,
			message: null,
		}
	}
};

export const TranslatorPanel = {
	namespaced: true,
	state,
	actions,
	mutations
};