<template>
  <v-app id="userpanel">
    <v-system-bar color="green lighten-5" class="unselectable" window app>

      <v-tooltip
        min-width="155px"
        left
        
        nudge-bottom="0"
        nudge-left="60"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            v-bind="attrs"
            v-on="on"
            icon
            small
            to="/authentication/logout">
            <!-- <v-badge
              color="orange"
              
              :content="expire.minutes +':'+expire.seconds"
              bordered
              right
              inline
              style="position: absolute; left:-50px;"
            >
            </v-badge> -->


            <v-icon size="15px" style="margin-right: -1px">mdi-logout-variant</v-icon>

          </v-btn>  
        </template>
        <span>خروج از نشست فعلی</span>
      </v-tooltip>

      
      <v-spacer></v-spacer>
      <v-chip  x-small outlined>
        {{date.toString('jdddd jDo jMMMM')}}
      </v-chip>
      <v-spacer></v-spacer>



<!-- 
      <v-tooltip
            v-if="UserPanel.drawer.header"
        nudge-bottom="30px"
        >
        <template v-slot:activator="{ on, attrs }">
        
          <div
              v-bind="attrs"
              v-on="on"
          >

            <v-btn 
              x-small rounded text color="green"
              
              to="/panel/account/wallet" style="position: relative" class="  pr-0">
              <v-icon v-if="UserPanel.drawer.header.wallet" size="17px" color="green darken-4" class="ml-2">mdi-wallet-plus</v-icon>
              <v-icon v-else size="17px" color="green darken-4" class="ml-2">mdi-wallet-plus-outline</v-icon>
              <span v-if="wallet">
                {{wallet + ' تومان'}}
              </span>
              <span v-else>کیف پول</span>
            </v-btn>
          </div>
        </template>
        <span>کیف پول </span>
      </v-tooltip> -->
    </v-system-bar>

    <v-app-bar
      app
      text
      height="72"
      class="pr-5"
      color="green darken-3"
      dark
      dense
    >
      <v-btn icon class=" ml-2" @click="updatePanelDrawer (!panelMenuDrawer)">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-slide-x-transition mode="out-in">
        <v-app-bar-title  class="ma-0 text-no-wrap title" v-if="showTitle !== ''" v-bind:key="showTitle">
          {{ showTitle }}
        </v-app-bar-title>
      </v-slide-x-transition>

      <v-spacer></v-spacer>
      <v-slide-y-transition hide-on-leave>

        <div v-if="UserPanel.drawer.header">


          <v-btn
            icon
            @click="chatDrawer = !chatDrawer">
              <v-badge
                color="red"
              >
                <v-icon size="18px" class="ml-1">mdi-chat</v-icon>
              </v-badge>
          </v-btn>
        </div>
      </v-slide-y-transition>
      <v-slide-y-transition hide-on-leave>
        <template v-if="!UserPanel.drawer.header">
            <v-progress-circular
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-circular>
        </template>
      </v-slide-y-transition> 
    </v-app-bar>

    <panel-selector-drawer :account="account" v-on:handle-drawer-change="updatePanelDrawer"  :panel="panelMenuDrawer" />

    <user-chat-drawer v-on:close-chat="chatDrawer = false" :account="account" :isopen="chatDrawer" />
    
    <v-main class="green lighten-4">
      <v-slide-y-transition leave-absolute>
        <slot />
      </v-slide-y-transition>
    </v-main>
    <v-snackbar
      top
      left
      elevation="5"

    
      rounded="5"
      :color="message.color"
      v-model="message.active"
    >
    <v-icon right style="position:absolute; right: 15px">mdi-alert-outline</v-icon>
      <div style="padding-right: 35px;" v-html="message.text"> 
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="lighten-1"
          depressed
          small
          v-bind="attrs"
          @click="message.active = false"
        >
          باشه
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<style>
.v-app-bar-title__content{
  width: 40% !important;
  font-family: 'IRANSans'
}
</style>

<script>

import PanelSelectorDrawer from '@/components/Panels/General/PanelSelectorDrawer.vue';
import { mapState, mapActions } from 'vuex'
import UserChatDrawer from '@/components/Panels/General/UserChatDrawer.vue';
import {PersianDate} from '@alireza-ab/vue-persian-datepicker'
export default {

  name: "MainPanel",
  components: {
    PanelSelectorDrawer,
    UserChatDrawer
  },
  data () {
    return {
      // api result messaging
      message: {
        active: false,
        text: [],
        type: 'error',
        color: 'primary',
      },
      wallet: 0,
      showTitle: this.$route.meta.layoutTitle,
      timer: null,
      date: new PersianDate(),
      chatDrawer: ['md','lg','xl'].includes(this.$vuetify.breakpoint.name) ? false : false,
      panelMenuDrawer: ['md','lg','xl'].includes(this.$vuetify.breakpoint.name) ? true : false,
      drawer: false,
      PanelSelectorDrawer: false,
      expire: this.getTimeRemaining()
      
    }
  },

  computed: {
    ...mapState({
      UserPanel: state => state.UserPanel,
      ChatPanel: state => state.ChatPanel,
      account: state => state.account,
      users: state => state.users.all
    }),
  },
  created () {

    this.timer = setInterval(()=>{
      this.expire = this.getTimeRemaining()
    }, 1000);

  },
  /**
   * IMPORTANT: Delete Admin Panel Data Updater interval
   */
  beforeDestroy () {
    clearInterval(this.ChatPanel.drawer.timer);
  },
  methods: {
    toggle(v){
      this.$emit('handle-drawer-change', v)
    },
    updatePanelDrawer(drawerStatus) {
      this.panelMenuDrawer = drawerStatus
    },
    getTimeRemaining() {
      const total = new Date(localStorage.getItem("expire") * 1000) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      
      return {
        total,
        days,
        hours,
        minutes,
        seconds
      };
    },
		...mapActions({
			abbreviateToman: 'website/abbreviateToman', 
			getPriceList: 'customForm/calculateRequestPrice', 
		}),
    
    ...mapActions('users', {
      getAllUsers: 'getAll',
      deleteUser: 'delete'
    })
  },
  watch:{
    'account.user': function(user) {
      if(!user) {
        this.$router.push('/authentication/login')
      }
    },
		'UserPanel.status': function(requestStatus){
      if(requestStatus == 'error' && this.UserPanel.alert.message) {
        //
        this.message.active = true // open snack 
        this.message.text = this.UserPanel.alert.message
        this.message.color = 'red'
      }
      if(requestStatus == 'done' && this.UserPanel.alert.message) {
        //
        this.message.active = true // open snack 
        this.message.text = this.UserPanel.alert.message
        this.message.color = 'primary'
      }

    },
    '$route': function(){
      this.showTitle = '';
    },
    'UserPanel.drawer.header.wallet': function(e){
      
      this.abbreviateToman(e).then((res) => {
        this.wallet = res
      })
    },
    '$route.meta.layoutTitle': function(){
      this.showTitle = this.$route.meta.layoutTitle;
    }
  }
}; 
</script>
