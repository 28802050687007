<template>

  <v-card class="brown accent-4 " dark>
    <v-card-title class="text-h5">
      به {{sitename}} خوش آمدید!
    </v-card-title>
    <v-card-text>
      <p>در این صفحه با خدمات ما آشنا میشوید</p>
      <p>در این صفحه با خدمات ما آشنا میشوید</p>
      <p>در این صفحه با خدمات ما آشنا میشوید</p>
      <p>در این صفحه با خدمات ما آشنا میشوید</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="green darken-1"
        text
        dark
        @click="closeDialog()"
      >
        متوجه شدم
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script> 
  export default {
    props: {
      dialog: {
        type: Boolean,
        required: true
      }
    },

    name: 'HelpHomePage',
    data(){ //instance
      return{
        sitename: 'مجموعه اهل سخن',
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog', true);
      },

    }
  }
</script>
