import { authHeader, config, handleResponse} from '../../helpers';
export const Admin = {

    getAdminPanelData,

    createUser,
    saveUser,
    getUserGroups,
    addUserGroup,
    deleteUserGroup,

    addFormCategory,
    deleteFormCategory,
    
    createInvoiceForRequest,

    getAllUsers,
    getUserByID,

    formDesignPage,
    getFormByID,
    getFormList,
    deleteForm,

    setRequestStatus,
    getFormRequestsList,
    deleteFormRequest,
    
    requestInvoices,
    deleteInvoice,
    setInvoiceAsPaid,

    getProjects,
    createProject,
    modifyProject,
    deleteProject,

    fileUpload,
    getFiles,
    

    getAdminDashboard,


    /**
     * Book Related APIs
     */
    // getBook,
    deleteBook,
    uploadBook,
    getAllBooks,
    addBookCategory,
    getBookCategories,
    deleteBookCategory,

    // deleteBookCategory <- missing
    getCollaborators,
    saveCollaborator,
    // deleteCollaborator,

    getSettings,
    setSettings,


    // User Wallet Management
    getWalletInformation,
    checkoutUserWallet,

};

// ------------------------------------------------------------

/**
 * Request a Wallet Checkout for UserID
 * 
 * @returns Promise
 */
function checkoutUserWallet(transaction, signal) {
    
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            action      : 'checkout',
            user_id     : transaction.user_id,
            transaction,
        })
    };

    return fetch(`${config.apiUrl}/admin/users/wallet/manager`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Requset Wallet Information for UserID
 * 
 * @returns Promise
 */
function getWalletInformation(userID, signal) {
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            action  : 'information',
            user_id : userID
        })
    };

    return fetch(`${config.apiUrl}/admin/users/wallet/manager`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get System Settings
 * 
 * @returns Promise
 */
function getSettings(signal) {
    const requestOptions = {
        signal,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/admin/settings/get`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Set System Settings
 * 
 * @returns Promise
 */
function setSettings(data, signal) {
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/admin/settings/set`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Upload File
 * 
 * @returns Promise
 */
function uploadBook(formData, signal) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            signal,
            'Accept': 'application/json',
        },
        body: formData
    };
    return fetch(`${config.apiUrl}/admin/books/upload`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Delete Book
 * 
 * @returns Promise
 */
function deleteBook(id, signal) {
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({id: id})
    };

    return fetch(`${config.apiUrl}/admin/books/delete`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get All Books
 * 
 * @returns Promise
 */
function getAllBooks(request, signal) {
    const requestOptions = {
        method: 'GET',
        signal,
        headers: authHeader()
    };
    var queryString = Object.keys(request).map(key => key + '=' + request[key]).join('&');

    return fetch(`${config.apiUrl}/admin/books/list?${queryString}`, requestOptions).then(handleResponse);
}


// ------------------------------------------------------------

/**
 * Book Cat
 * 
 * @returns Promise
 */
function addBookCategory(cat, signal)
{
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(cat)
    };

    return fetch(`${config.apiUrl}/admin/books/categories/add`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get Book Cats
 * 
 * @returns Promise
 */
function getBookCategories(filters, signal){

    const requestOptions = {
        signal,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    if(filters)
    var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&'); 

    return fetch(`${config.apiUrl}/admin/books/categories?${queryString}`, requestOptions).then(handleResponse);
} 

function deleteBookCategory(cat, signal) {
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({id: cat})
    };

    return fetch(`${config.apiUrl}/admin/books/categories/del`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get Book Cats
 * 
 * @returns Promise
 */
function getCollaborators(filters, signal){

    const requestOptions = {
        signal,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    if(filters)
    var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&'); 

    return fetch(`${config.apiUrl}/admin/books/collaborators?${queryString}`, requestOptions).then(handleResponse);
} 


// ------------------------------------------------------------

/**
 * Get Book Cats
 * 
 * @returns Promise
 */
 function saveCollaborator(collaborator, signal){

    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(collaborator)
    };

    return fetch(`${config.apiUrl}/admin/books/collaborators/save`, requestOptions).then(handleResponse);
} 

// ------------------------------------------------------------

/**
 * Upload File
 * 
 * @returns Promise
 */
function fileUpload(formData, signal) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            signal,
            'Accept': 'application/json',
        },
        body: formData
    };
    return fetch(`${config.apiUrl}/admin/files/upload`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get Files
 * 
 * @returns Promise
 */

function getFiles(request, signal) {
    const requestOptions = {
        method: 'GET',
        signal,
        headers: authHeader()
    };
    var queryString = Object.keys(request).map(key => key + '=' + request[key]).join('&');

    return fetch(`${config.apiUrl}/admin/files/get?${queryString}`, requestOptions).then(handleResponse);
}



// ------------------------------------------------------------

/**
 * Get Projects List
 * 
 * @returns Promise
 */
function getProjects(request, signal) {
    const requestOptions = {
        method: 'GET',
        signal,
        headers: authHeader()
    };
    var queryString = Object.keys(request).map(key => key + '=' + request[key]).join('&');

    return fetch(`${config.apiUrl}/admin/projects/list?${queryString}`, requestOptions).then(handleResponse);
}


// ------------------------------------------------------------

/**
 * Create Project
 * 
 * @returns Promise
 */
function createProject(project, signal)
{
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(project)
    };

    return fetch(`${config.apiUrl}/admin/projects/create`, requestOptions).then(handleResponse);
}


// ------------------------------------------------------------

/**
 * Modify Project
 * 
 * @returns Promise
 */
function modifyProject(project, signal)
{
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(project)
    };

    return fetch(`${config.apiUrl}/admin/projects/modify`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Delete Project
 * 
 * @returns Promise
 */
function deleteProject(projectID, signal)
{
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(projectID)
    };

    return fetch(`${config.apiUrl}/admin/projects/delete`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Request invoice list
 * 
 * @returns Promise
 */
 function requestInvoices(filters, signal){

    const requestOptions = {
        signal,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&'); 

    return fetch(`${config.apiUrl}/admin/invoice/list?${queryString}`, requestOptions).then(handleResponse);
}


// ------------------------------------------------------------

/**
 * Request invoice list
 * 
 * @returns Promise
 */
function setInvoiceAsPaid(id, receipt_number, signal){

    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({id, receipt_number})
        
    };
    return fetch(`${config.apiUrl}/admin/invoice/set_paid`, requestOptions).then(handleResponse);
}


// ------------------------------------------------------------

/**
 * Request invoice list
 * 
 * @returns Promise
 */
function deleteInvoice(id, signal){

    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({id})
        
    };
    return fetch(`${config.apiUrl}/admin/invoice/delete`, requestOptions).then(handleResponse);
}


function getAdminDashboard(signal)
{
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        signal
    };

    return fetch(`${config.apiUrl}/admin/sys/dashboard`, requestOptions).then(handleResponse);
}

function getAdminPanelData(signal)
{
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        signal
    };

    return fetch(`${config.apiUrl}/admin/sys/panel`, requestOptions).then(handleResponse);
}
function addFormCategory(cat, signal)
{
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(cat)
    };

    return fetch(`${config.apiUrl}/admin/custom_forms/categories/add`, requestOptions).then(handleResponse);
}

function deleteFormCategory(cat, signal) {
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({id: cat})
    };

    return fetch(`${config.apiUrl}/admin/custom_forms/categories/del`, requestOptions).then(handleResponse);
}
function getUserGroups(signal)
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        signal
    };

    return fetch(`${config.apiUrl}/admin/users/get_user_groups`, requestOptions).then(handleResponse);
}

function addUserGroup(group) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(group)
    };

    return fetch(`${config.apiUrl}/admin/users/add_user_group`, requestOptions).then(handleResponse);
}

function deleteUserGroup(group_id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({id: group_id})
    };

    return fetch(`${config.apiUrl}/admin/users/delete_user_group`, requestOptions).then(handleResponse);
}


function getAllUsers(request, signal) {
    const requestOptions = {
        method: 'GET',
        signal,
        headers: authHeader()
    };
    var queryString = Object.keys(request).map(key => key + '=' + request[key]).join('&');

    return fetch(`${config.apiUrl}/admin/users/list?${queryString}`, requestOptions).then(handleResponse);
}
 
function getUserByID(id, signal) {
    const requestOptions = {
        method: 'GET',
        signal,
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/admin/users/find?id=${id}`, requestOptions).then(handleResponse);
}

function createUser(formData, signal) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            signal,
            'Accept': 'application/json',
        },
        body: formData
    };
    return fetch(`${config.apiUrl}/admin/users/create`, requestOptions).then(handleResponse);
}



// ------------------------------------------------------------

/**
 * Request invoice list
 * 
 * @returns Promise
 */

function saveUser(formData, signal) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            signal,
            'Accept': 'application/json',
        },
        body: formData
    };
    return fetch(`${config.apiUrl}/admin/users/save`, requestOptions).then(handleResponse);
}

function createInvoiceForRequest(data, signal){

    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/admin/custom_forms/createInvoice`, requestOptions).then(handleResponse);
}


function formDesignPage(data, signal){

    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/admin/custom_forms/designing_form`, requestOptions).then(handleResponse);
}

function getFormByID(FormID, signal){

    const requestOptions = {
        method: 'GET',
        signal: signal,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/admin/custom_forms/get_form?form=${FormID}`, requestOptions).then(handleResponse);
}

function getFormList(request, signal){

    const requestOptions = {
        method: 'GET',
        signal,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    var queryString = Object.keys(request).map(key => key + '=' + request[key]).join('&');

    return fetch(`${config.apiUrl}/admin/custom_forms/list?${queryString}`, requestOptions).then(handleResponse);
} 

// ------------------------------------------------------------

/**
 * Delete a Form
 * 
 * @returns Promise
 */
function deleteForm(formID, signal) {
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({id: formID})
    };

    return fetch(`${config.apiUrl}/admin/custom_forms/delete`, requestOptions).then(handleResponse);
}


// ------------------------------------------------------------

/**
 * Set Status for A Custom Form Request
 * 
 * @returns Promise
 */

 function setRequestStatus(id, status) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            id: id, 
            status: status
        })
    };

    return fetch(`${config.apiUrl}/admin/custom_forms/request/setStatus`, requestOptions).then(handleResponse);
}

function getFormRequestsList(request, signal){

    const requestOptions = {
        method: 'GET',
        signal,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    
    if(request)
        var queryString = ("?") + Object.keys(request).map(key => key + '=' + request[key]).join('&');
    return fetch(`${config.apiUrl}/admin/custom_forms/request/list${queryString}`, requestOptions).then(handleResponse);
} 


function deleteFormRequest(cat, signal) {
    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({id: cat})
    };

    return fetch(`${config.apiUrl}/admin/custom_forms/request/delete`, requestOptions).then(handleResponse);
}