<template>
	<div style="position: fixed; left: 20px; bottom: 50px; z-index: 3">

		<v-fab-transition>
			<v-btn
				small
				bottom
				left
				:color="color"
				dark
				outlined
				elevation="5"
				style="background-color: green"
				@click="go"
			>
				<v-icon class="mr-0 ml-2" small>mdi-alert-decagram-outline</v-icon> بروزرسانی جدید
			</v-btn>
		</v-fab-transition>
	</div>
</template>

<script>

import { config } from '@/helpers';

export default ({
	name: 'SystemUpgrade',

	props: {
		color: { type: String },
	},
	components: { },

	created() { },

	data() {
    return {
			
		}
  },
	methods: {
		go(){
      localStorage.setItem("ak_version", config.version)
			location.reload(true);
		}
	},


})
</script>
