import { WebsiteService } from '@/services';

const state = {
	status: null, // state status
	result: {},

	// website global searcher
	search: {
		loading: false,
		query: null,
		items: [],
		filters: {}
	},

	bookshelf: {
		display: {
			prefix: '',
			loading: false,
			items: [
				// {
				// 	title: '',
				// 	subtitle: '',
				// 	description: '',
				// 	explore_category: '',
				// 	color: '',
				// 	image: '',
				//	books: [],
				// 	link: '',
				// }
			]
		},
		modify: {
			loading: false,
			item   : {
				title: '',
				subtitle: '',
				description: '',
				explore_category: '',
				color: '',
				image: '',
				books: [],
				link: '',
			}
		}
	},

	RequestController: new AbortController(), // Fetch API Request

};

const actions = {

	/**
	 * Request a Global Search
	 * @param {*} commit 
	 * @param {*} section 
	 */
	requestSearch({ commit }, query) {
		commit('searchBegun', query);
		
		WebsiteService.requestSearch({
			query
			// add filters: later on
		}, state.RequestController.signal).then(
			response => commit('searchDone', response),
			error => commit('searchDone', error)
		)
	},

	/**
	 * Request a Global Search
	 * @param {*} commit 
	 * @param {*} section 
	 */
	loadBookshelf({ commit }, prefix) {
		// reset items
		state.bookshelf.display.items 	= []
		// set loading shelf
		state.bookshelf.display.loading = true
		// set the loading shelf prefix
		state.bookshelf.display.prefix 	= prefix
		WebsiteService.loadShelf({
			prefix
			// add filters: later on
		}, state.RequestController.signal).then(
			response => commit('shelfLoaded', response),
			error => commit('shelfLoaded', error)
		)
	},
	/**
	 * Request Client Dashboard data
	 * @param {*} commit 
	 * @param {*} section 
	 */
	loadPage({ commit }, page) {
		commit('RequestStarted', 'loadPage');
		
		WebsiteService.getPage(page, state.RequestController.signal).then(
			response => commit('RequestDone', response),
			error => commit('error', error)
		)
	},

	abbreviateToman({ commit }, value) {
		commit
		var newValue = value;

		if (value >= 9999999) {
				var suffixes = ["", " هزار", " میلیون", " میلیارد"," میلیارد"];
				var suffixNum = Math.floor( (""+value).length/3 );
				var shortValue = '';
				for (var precision = 2; precision >= 1; precision--) {
						shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
						var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
						if (dotLessShortValue.length <= 2) { break; }
				}
				if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
				newValue = Number(shortValue+suffixes[suffixNum]).toLocaleString() + " تومان"
		}
		else {
			if (value >= 10000) {
				newValue = Number(newValue).toLocaleString() + " تومان"

			}
			else {
				let thousends = String(newValue).slice(0, 3)

				newValue = (Number(thousends).toLocaleString()) + " تومان"
			}

		}

		return newValue;
	},

	/**
	 * Format bytes as human-readable text.
	 * 
	 * @param bytes Number of bytes.
	 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
	 *           binary (IEC), aka powers of 1024.
	 * @param dp Number of decimal places to display.
	 * 
	 * @return Formatted string.
		*/
	humanFileSize: async function({commit}, bytes, si=false, dp=1) {
		commit
		const thresh = si ? 1000 : 1024;
		
		if (Math.abs(bytes) < thresh) {
			return bytes + ' B';
		}
		
		const units = si 
			? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
			: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
		let u = -1;
		const r = 10**dp;
		
		do {
			bytes /= thresh;
			++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
		
		
		return bytes.toFixed(dp) + ' ' + units[u];
  },  
	isNumber: function({commit}, evt) {
		commit
		evt = (evt) ? evt : window.event;
		var charCode = (evt.which) ? evt.which : evt.keyCode;
		if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
			evt.preventDefault()
		} else {
			return true;
		}
	},
};

const mutations = {

	searchBegun(state, query) {
		state.search.loading = true

		if(query !== state.search.query) {
			state.search.query = query
		}

	},
	searchDone(state, result) {
		state.search.loading = false
		if(result.error == 409) {
			alert(result.messages.errors)
		}
		else {
			if(result.data && result.data.final)
				state.search.items = result.data.final
		}
		
	},
	shelfLoaded(state, result) {

		state.bookshelf.display.loading = false
		if(result.error == 409) {
			alert(result.messages.errors)
		}
		else {

				state.bookshelf.display.items = result.result
				if(result.status == 'Ok' && result.data && result.result)
				state.bookshelf.display.items = result.result
		}
	},
	// display: {
	// 	prefix: '',
	// 	loading: false,
	// 	items: [
	// 		// {
	// 		// 	title: '',
	// 		// 	subtitle: '',
	// 		// 	description: '',
	// 		// 	explore_category: '',
	// 		// 	color: '',
	// 		// 	image: '',
	// 		//	books: [],
	// 		// 	link: '',
	// 		// }
	// 	]
	// },
	RequestStarted(state, action) {
		
		state.result = null
		state.action = action
		state.status  = 'loading'

		state.RequestController.abort()
		state.RequestController = new AbortController()
	},
	RequestDone(state, data) {
		state.result = data.result
		state.status  = 'done'
	},

	error(state, data) {
		if(data == 'AbortError: The user aborted a request.')
			return true; // cool
		state.result = data.data ? data.data : ( data.message ? data.message : data.status) 
		state.status  = data.status
		

		if(!data.OK && data.errors) {
			state.alert.type = 'error'
			state.alert.title = 'خطا'
			Object.keys(data.errors).forEach((errorKey) => {
				state.alert.message = (state.alert.message ? state.alert.message : '') + "<p>"+data.errors[errorKey]+"</p>"
			})
		}
	},
};

export const website = {
    namespaced: true,
    state,
    actions,
    mutations
};