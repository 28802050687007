<template>
  <v-app id="inspire_translation" class="grey lighten-3">
    

    <div style="margin-top: 270px;">
      <slot />
    </div>
  </v-app>
</template>
<style>

/* 1. declare transition */
.system_tools-move,
.system_tools-enter-active,
.system_tools-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.system_tools-enter-from,
.system_tools-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.system_tools-leave-active {
  position: absolute;
}
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'PDFReader',
  components:{ 
  },
  computed:{
    ...mapState({
      account: state => state.account,
      users: state => state.users.all
    }),
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    }
  },
  methods: {

  ...mapActions('users', {
    getAllUsers: 'getAll',
    deleteUser: 'delete'
  }),
  }, 
  data: () => ({
      e6: 1,
  }),
}
</script>
