import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2f6a3aa4&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VAppBar,VAppBarTitle,VAvatar,VBtn,VCard,VCardText,VChip,VChipGroup,VCombobox,VDivider,VFooter,VIcon,VList,VListGroup,VListItem,VListItemIcon,VListItemSubtitle,VListItemTitle,VMenu,VRow,VSkeletonLoader,VSpacer})
