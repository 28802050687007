<template>
	<v-autocomplete
		v-model="model"
		:items="items"
		:loading="isLoading"
		:search-input.sync="search"
		:multiple="multiple"
		:chips="chips"
				
		hide-no-data
		hide-selected
		label="انتخاب کنید"
		prepend-icon="mdi-book-search-outline"
	>
	</v-autocomplete>
</template>
<script>
	import { UserPanelService } from '@/services/panel.user.service';
  export default {
		props: {
			type: { type: String },
			multiple: { type: Boolean },
			chips: { type: Boolean },
			value: { },
		},
    data: (instance) => ({
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: instance.value,
      search: null,
      abort: new AbortController(),
    }),
	
    computed: {
      fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      items () {
        return this.entries.map(entry => {
          const Description = entry

          return Object.assign({}, entry, { Description })
        })
      },
    },

    watch: {

			model: function(e) {
				this.$emit('on-select', e)
			},
      search: {
				handler: function() {
					// Items have already been loaded
					if (this.items.length > 0) return

					// Items have already been requested, abort previous request and make new one
					if (this.isLoading) {
						this.abort.abort()
						this.abort = new AbortController()
					}

					this.isLoading = true

					// Lazily load input items
					UserPanelService.searchBookCategories({
						name: ' '
					}, this.abort.signal)
						.then(res => {
							console.log(res)
							let output = []
							Object.keys(res.data.categories).forEach((key) => {
								output.push({
									text: res.data.categories[key].name,
									value: res.data.categories[key].id,
								})
							})
							this.entries = output
							if(this.value) this.model = this.value
						})
						.catch(err => {
							console.log(err)
						})
						.finally(() => (this.isLoading = false))
				},
				immediate: true
			},
    },
  }
</script>