<template> 
	<v-navigation-drawer
		left
		fixed
		:width="['xs','sm'].includes($vuetify.breakpoint.name) ? '100%': (['md'].includes($vuetify.breakpoint.name) ? '40%' : '30%')"
		v-model="isOpen"
		overlay-opacity=".5"
		temporary
		touchless
		id="chat-container"
	>
    <v-system-bar color="deep-purple darken-3"></v-system-bar>
		
    <v-app-bar
      :color="'deep '+colors[activeChatRoom]+' accent-4'"
      dark
      prominent
			dense
			absolute
			elevate-on-scroll
			shrink-on-scroll
			extended
			fade-img-on-scroll
			scroll-target="#chat-container > .v-navigation-drawer__content"
			
    >
      <v-app-bar-nav-icon 
				@click.stop="$emit('close-chat')"></v-app-bar-nav-icon>

      <v-toolbar-title
				
				>گفت و گوهای من</v-toolbar-title>

      <v-spacer></v-spacer>
			<v-menu v-if="false" rounded="">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						dark
						v-bind="attrs"
						v-on="on"
						:color="user.state ? user.state.color + ' darken-4' : ''"
						class="ml-2 mt-3"
						text
						outlined
					>
						<span v-if="!user.state">
							انتخاب وضعیت
						</span>
						<span v-else>
							<v-badge x-small :color="user.state.color" style="top: 3px; right: -5px" class="ml-5"></v-badge> {{user.state.text}}
						</span>
					</v-btn>
				</template>
				<v-list elevation="3" dense dark color="blue darken-4">
					<v-list-item
						v-for="(item, index) in userStates"
						:key="index"
						@click="user.state = item"
					>
						<v-list-item-action class="ml-2">
							<v-badge :color="item.color" style="top: 5px; right: 0px"></v-badge>
						</v-list-item-action>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-avatar
				v-if="false"
				right
				class="mb-0 d-block float-right mx-auto mt-4"
				color="grey darken-1"
				size="36"
			>
				<img
					alt="user"
					:src="account.user.avatar"
				>
			</v-avatar>
      <template v-slot:extension >
        <v-tabs
					grow
					align-with-title
					center-active
					>
          <v-tab
						@click="activeChatRoom = 'support'"
						:color="colors['support']+' accent-3'"
						depressed
						dark>
						
						<v-badge
						
							color="red"
							:value="ChatPanel.drawer.rooms.support.badge"
							:content="ChatPanel.drawer.rooms.support.badge"
						>
							پشتیبانی
						</v-badge>
						

					</v-tab>
          <v-tab 
						@click="activeChatRoom = 'projects'"
						:color="colors['projects']+' accent-3'"
						depressed
						dark >
							<v-badge
								color="red"
								:value="ChatPanel.drawer.rooms.projects.badge"
								:content="ChatPanel.drawer.rooms.projects.badge"
							>
								پروژه ها
							</v-badge>
						</v-tab>
        </v-tabs> 
			</template>
    </v-app-bar>
		
		<v-sheet :color="colors[activeChatRoom]" dark rounded elevation="10" style="transition: 1000ms all; margin-top: 175px" class=" overflow-hidden pa-2 mr-2 ml-2 ">
			<v-slide-x-reverse-transition hide-on-leave mode="out-in">
				<div v-show="activeChatRoom == 'projects'">
					<b><v-icon class="ml-3">mdi-folder-star-multiple</v-icon>اتاق پروژه ها</b>
					<v-card-subtitle class="">ارتباط مستقیم با مجری پروژه به شما اجازه میدهد تا از آخرین وضعیت پروژه خود با خبر باشید و در صورت وجود نارضایتی امکان گزارش سریع فراهم شده است</v-card-subtitle>
				
					<div dir="ltr">
						<v-btn to="/panel/account/order" dir="rtl" left class="mt-3" text><v-icon class="ml-2">mdi-plus</v-icon> ثبت سفارش جدید</v-btn>
					</div>
				</div>
			</v-slide-x-reverse-transition>
			<v-slide-x-reverse-transition hide-on-leave mode="out-in">

				<div v-show="activeChatRoom == 'support'">
					<b><v-icon class="ml-2">mdi-face-agent</v-icon> اتاق پشتیبانی</b>
					<v-card-subtitle class="">در صورتی که سوالی دارید یا با مشکل فنی برخورده اید میتوانید از این قسمت با کارشناسان مجموعه اهل سخن در تماس باشید</v-card-subtitle>
					<v-card-subtitle
						v-if="ChatPanel.drawer.rooms.archived && ChatPanel.drawer.rooms.archived.support.badge"
						class=""> 
							<v-switch v-model="addArchived" dense :label="ChatPanel.drawer.rooms.archived.support.badge +' تیکت آرشیو شده'"></v-switch>
						</v-card-subtitle>

					<div dir="ltr">
						<v-btn @click="supportRequestDialog = true" dir="rtl" left class="mt-3" text><v-icon class="ml-2">mdi-plus</v-icon> ثبت درخواست پشتیبانی</v-btn>
					</div>

					<v-dialog
						persistent
						max-width="450px"
						v-model="supportRequestDialog">
															
						<validation-observer
							ref="observer"
							v-slot="{ invalid }"
						>
							<v-card
								color="primary darken-4"
								dark
								:loading="loading"
							>
								<v-card-title><v-icon class="ml-2">mdi-face-agent</v-icon> درخواست پشتیبانی</v-card-title>
								<v-card-text>
									<form @submit.prevent="submit">
										<div>
											<validation-provider
												v-slot="{ errors }"
												name="دپارتمان"
												:rules="{
													required: true,
												}"
											>
												<v-select
													:disabled="loading"
													filled
													autofocus
													flat
													append-icon="mdi-account-question"
													:items="[
														{
															text: 'بخش ویراستار',
															value: 'admin_editor',
														},
														{
															text: 'بخش مترجمین',
															value: 'admin_translator',
														},
														{
															text: 'بخش تولید محتوی',
															value: 'admin_content_creator',
														},
														{
															text: 'بخش انتشارات',
															value: 'admin_publisher',
														},
														{
															text: 'مدیریت',
															value: 'admin_support',
														}
													]"
													v-model="support.department"
													:error-messages="errors"
													label="دپارتمان"
													required
												></v-select>
											</validation-provider>
										</div>
										<div>
											<validation-provider
												v-slot="{ errors }"
												name="موضوع"
												:rules="{
													required: true,
												}"
											>
												<v-text-field
													:disabled="loading"
													filled
													autofocus
													flat
													append-icon="mdi-playlist-edit"
													v-model="support.subject"
													:error-messages="errors"
													label="موضوع"
													required
												></v-text-field>
											</validation-provider>
										</div>
										<div>
											<validation-provider
												v-slot="{ errors }"
												name="متن پیام"
												:rules="{
													required: true,
												}"
											>
												<v-textarea
													:disabled="loading"
													filled
													autofocus
													flat
													append-icon="mdi-playlist-edit"
													v-model="support.message"
													:error-messages="errors"
													label="متن پیام"
													required
												></v-textarea>
											</validation-provider>
										</div>
									</form>
								</v-card-text>
								<v-card-actions dir="ltr">
									<v-btn :disabled="invalid" :loading="loading" @click="makeSupportRequest" depressed color="green">ثبت درخواست</v-btn>
									<v-btn class="ml-2" text @click="supportRequestDialog = false">لغو</v-btn>
								</v-card-actions>
							</v-card>
						</validation-observer>
					</v-dialog>
				</div>
			</v-slide-x-reverse-transition>
		</v-sheet>
		<div v-if="activeChatRoom == 'projects'">

			<v-list class="mb-10">
				<v-list-item
					v-for="(room, index) in ChatPanel.drawer.rooms.projects.conversations"
					:key="index"
					link
				>
					<ChatRoom :Room="room"></ChatRoom>
				
				</v-list-item> 

			</v-list>
		</div>
		<div v-if="activeChatRoom == 'support'">

			<v-list class="mb-10" v-if="ChatPanel.drawer.rooms.support.conversations.length || ChatPanel.drawer.rooms.archived.support.conversations.length">
				<v-list-item
					v-for="(room, index) in supportList "
					:key="index"
					link
				>
					<ChatRoom :Room="room"></ChatRoom>
					<v-list-item-action v-if="room.status == 'archived'">
						<v-chip> آرشیو شده</v-chip>
					</v-list-item-action>
				</v-list-item> 

			</v-list> 
 
		</div>
	</v-navigation-drawer>

</template>



<script>
const chatResult = {
	'private': {
		count: 1,
		people: [
			{
				id: 1,
				displayName: "کاربر تست",
				message: "سلام این یک پیام تست میباشد"
			}
		]
	},
	'support': {
		count: 0,
		people: [
			{
				id: 1,
				displayName: "کاربر تست",
				message: "سلام این یک پیام تست میباشد"
			}
		]
	},
	'projects': {
		count: 0,
		people: [
			{
				id: 1,
				displayName: "کاربر تست",
				message: "سلام این یک پیام تست میباشد"
			}
		]
	}
}
import ChatRoom from "../../Chat/ChatRoom.vue"
import { mapState, mapActions } from 'vuex'

  import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default ({
	props: {
		isopen: { type: Boolean },
		account: { type: Object },
	},
  computed: {
    ...mapState({
      ChatPanel: state => state.ChatPanel,
      UserPanel: state => state.UserPanel,
    }),
    supportList(){
			if(!this.ChatPanel.drawer.rooms.support.conversations && !this.ChatPanel.drawer.rooms.archived.support)
			{
				return []
			}
			
			let combined = Object
				.values([...this.ChatPanel.drawer.rooms.support.conversations, ...this.ChatPanel.drawer.rooms.archived.support.conversations].reduce((room, item) => ({ ...room, [item.id]: item }), {}))
				.sort((a, b) => (b.id - a.id))

			if(this.addArchived) 
				return combined
      else
				return this.ChatPanel.drawer.rooms.support.conversations
    }
  },
	name: 'UserChatDrawer',
  components:{
		ChatRoom,
		ValidationProvider,
		ValidationObserver,
		
  },
	created(){

		this.loadDrawer() 
	},
	data(instance) {

		return {
			addArchived: false,

			support: {
				subject: null,
				department: null,
				message: null,
			},
			loading: false,
			supportRequestDialog: false,


			socket: null,
			timerID : null,

			// Chat Drawer State
			isOpen: instance.isopen,

			// Active Chat Room (group) to display
			activeChatRoom: 'support',

			// Chat Group Colors
			colors: {
				'private': 'purple',
				'support': 'primary',
				'projects': 'teal',
			},

			// Object of Chat Results
			chats: chatResult,

			userStates: [
				{
					text: 'در دسترس میباشم',
					color: 'green',
				},
				{
					text: 'مشغول هستم',
					color: 'red',
				},
				{
					text: 'حالت مخفی (نیستم)',
					color: 'grey',
				},
			],
			// Object of Current User Status
			user: {
				status: 'online',
				state: '',
			},
		}
  },
	methods: {
		makeSupportRequest() {
			this.loading = true
			this.requestSupport(this.support)
		},
    ...mapActions('UserPanel', {
      requestSupport: 'requestSupport'
    }), 
    ...mapActions('ChatPanel', {
      loadDrawer: 'loadDrawerData'
    }), 

	},
  watch: {
    'UserPanel.status': function(val){
      this.loading = val == 'loading'
				console.log(this.UserPanel)

      switch (this.UserPanel.action) {
        case 'requestSupport':
          if(val == 'done')
          {
						this.support = {
							subject		: null,
							department: null,
							message		: null,
						}
						this.supportRequestDialog = false
          }
          break;
        case 'loadDrawerData':
          
          if(val == 'done')
          {
						//
          }
          break;
      
        default:
          break;
      }

    },
    isopen: function(newVal) { // watch it
      this.isOpen = newVal
    },
		isOpen: function(n)
		{
			document.querySelector('html').classList.toggle('application--drawer-opened', n);

			if(!n)
			this.$emit('close-chat')
		}
  }

})
</script>
