<template>   <v-sheet
    class="treee pa-2"
    color="grey lighten-4"
    rounded=""
  >
    <div v-if="loading" class="pa-5 text-center">

      <v-progress-circular
        width="1"
        
        
        indeterminate
        color="purple"
      ></v-progress-circular>
    </div>

    <v-treeview
      v-else
      dense
      v-model="treeview"
      selected-color="indigo"
      open-on-click
      expand-icon="mdi-chevron-down"
      item-key="id"
      hoverable
      search
      rounded
      :items="items"
      return-object
    >
      <template v-slot:label="{item}">
        <router-link :to="'/shop/explore/categories/'+item.id" style="position: absolute; right:40px;top:12px;height:100%;width:100%;">{{item.name}}</router-link>
      </template>

    </v-treeview>
  </v-sheet>
</template>

<style >
.treee a{
  color: #333
}
.treee .v-treeview-node__children{
  padding-right: 20px !important;
  border-right: 2px solid #4527a0;
  border-radius: 55px;
}
</style>

<script>
import {UserPanelService} from "@/services/panel.user.service"

export default {
  name: 'BooksTreeCategory',
  created(){
    
    
    UserPanelService.searchBookCategories({
      tree: true
    }).then(
			response => {
        this.loading = false
        this.items = response.data.tree
      },
			error => {
        this.loading = false
        console.log(error)
      }
		)
  },
  data: () => ({
    loading: true,
    treeview: [],
    items: [],
  }),
}
</script>
