var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{style:(_vm.customStyle),attrs:{"fixed":"","app":"","color":"lighten-3","right":"","dir":"ltr","width":"350px"},on:{"input":_vm.toggle},model:{value:(_vm.panelMenuDrawer),callback:function ($$v) {_vm.panelMenuDrawer=$$v},expression:"panelMenuDrawer"}},[_c('v-row',{staticClass:"full-height",staticStyle:{"height":"100%","overflow":"hidden"},attrs:{"dir":"rtl","no-gutters":""}},[_c('v-col',{staticStyle:{"height":"calc(100vh - 20px )","overflow-y":"scroll","direction":"ltr"},attrs:{"cols":"3"}},[_c('v-avatar',{staticClass:"d-block text-center mx-auto mt-4",attrs:{"color":"brown darken-4"},on:{"click":function($event){return _vm.$router.push({path: '/'})}}},[_c('img',{attrs:{"alt":"Avatar","src":require("@/assets/appicon.svg")}})]),_c('v-divider',{staticClass:"mx-3 my-5"}),(_vm.account.user.access.includes('admin') ||
              _vm.account.user.access.includes('admin_publisher') ||
              _vm.account.user.access.includes('admin_content_creator') ||
              _vm.account.user.access.includes('admin_editor') ||
              _vm.account.user.access.includes('admin_translator')
            )?_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-block text-center mx-auto mb-4",attrs:{"color":"blue lighten-1","size":"28","to":"/panel/admin","dense":"","rounded":"","icon":""},on:{"click":function($event){_vm.panelMenuDrawer = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-tie")])],1)]}}],null,false,3096060945)},[_c('span',[_vm._v("پنل مدیریت")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-block text-center mx-auto mb-4",attrs:{"to":"/panel/account","color":"green lighten-1","size":"28","dense":"","rounded":"","icon":""},on:{"click":function($event){_vm.panelMenuDrawer = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-circle-outline")])],1)]}}])},[_c('span',[_vm._v("پنل کاربری")])]),_c('v-tooltip',{attrs:{"left":"","color":"teal"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-block text-center mx-auto mb-4",attrs:{"to":_vm.account.user.access.includes('translator') ? '/panel/translator' : '/panel/account/register/translator',"color":"teal lighten-1","size":"28","dense":"","rounded":"","icon":""},on:{"click":function($event){_vm.panelMenuDrawer = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-translate")])],1)]}}])},[(_vm.account.user.access.includes('translator'))?_c('span',[_vm._v("پنل ترجمه")]):_c('span',[_vm._v("ثبت نام مترجم")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-block text-center mx-auto mb-4",attrs:{"to":_vm.account.user.access.includes('writer') ? '/panel/writer' : '/panel/account/register/writer',"color":"blue lighten-1","size":"28","dense":"","rounded":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-typewriter")])],1)]}}])},[(_vm.account.user.access.includes('writer'))?_c('span',[_vm._v("پنل ویراستار")]):_c('span',[_vm._v("ثبت نام ویراستار")])]),_c('v-tooltip',{attrs:{"left":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-block text-center mx-auto mb-4",attrs:{"to":_vm.account.user.access.includes('content_creator') ? '/panel/content_creator' : '/panel/account/register/content_creator',"color":"purple lighten-1","size":"28","dense":"","rounded":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-video-vintage")])],1)]}}])},[(_vm.account.user.access.includes('content_creator'))?_c('span',[_vm._v("پنل تولید محتوی")]):_c('span',[_vm._v("ثبت نام کارشناس تولید محتوی")])]),_c('v-tooltip',{attrs:{"left":"","color":"cyan"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-block text-center mx-auto mb-4",attrs:{"to":_vm.account.user.access.includes('publisher') ? '/panel/publisher' : '/panel/account/register/publisher',"color":"cyan lighten-1","size":"28","dense":"","rounded":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-book-open-page-variant-outline")])],1)]}}])},[(_vm.account.user.access.includes('publisher'))?_c('span',[_vm._v("پنل ناشرین")]):_c('span',[_vm._v("ثبت نام ناشر")])])],1),_c('v-col',{staticStyle:{"height":"calc(100vh - 20px )"},attrs:{"cols":"9"}},[_c('v-slide-x-transition',{attrs:{"mode":"out-in"}},[_c(_vm.panelDrawer,{tag:"component",attrs:{"account":_vm.account,"panel":_vm.panelMenuDrawer},on:{"handle-drawer-change":_vm.updatePanelDrawer,"close":function($event){_vm.panelMenuDrawer = false},"open":function($event){_vm.panelMenuDrawer = true}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }