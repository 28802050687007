import { userService } from '@/services/user.service';
import store from '@/store/index'

export * from './auth-header';

 
export const config = {
    handleResponse,
    // apiUrl: "https://ketab.ahur.ir/api/public/api",
    // apiUrl: "https://orders.ahlesokhan.com/core/public/api",
    apiUrl: "/api",
    version: 27.3
} 


export function handleResponse(response) {

    switch (response.status) {
        case 401:
            // auto logout if 401 response returned from api
            userService.logout();
            location.reload(true);
            break;
        case 429:
            alert("لطفا از ارسال درخواست های پی در پی جلوگیری کنید")
            break;
        case 406:
            store.dispatch('account/refresh',{
                uid: store.state.account.user.id,
                token: store.state.account.remembered.token
            } );

            // userService.refresh();
            break;
    
        default:
            break;
    } 
    
    return response.text().then(text => {

        const data = text && JSON.parse(text);
        if (data.error) {

            const error = (data && data.messages) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}