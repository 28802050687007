<template>
  <div>
		<div class="text-center pa-3">
			<v-btn
				depressed
				color="blue"
				outlined
				v-if="ChatRoom.total_pages && ChatRoom.total_pages > ChatRoom.current_page"
				@click="reqLoadMore"
			>
				<v-icon>mdi-refresh</v-icon> نمایش پیام های قدیمی تر
			</v-btn>
			{{ChatRoom.current_page}} / {{ChatRoom.total_pages}}
		</div>
		<div dir="ltr" style="text-align: center;" v-if="ChatRoom.loading">
			<v-progress-circular color="blue" class="mt-10" indeterminate></v-progress-circular>
		</div>
		<transition-group v-if="ChatRoom && ChatRoom.messages && ChatRoom.messages.length" name="card"  mode="out-in" tag="div">
			<div class="card overflow-hidden" v-for="(message, index) in ChatRoom.messages" :key="index">
				<div v-if="message.type == 'info'" class="text-center">
					<v-chip
						v-if="message.action == 'join' && message.user"
						color="green"
						class="ml-5 mr-5 mb-5 text-center"
						outlined
						dark
						style="">

						<v-avatar v-if="message.user && message.user.avatar" left>
							<v-img :src="message.user.avatar"  width="100%"/>
						</v-avatar>
						{{message.user.fullname}} وارد مکالمه شد
					</v-chip>
					<v-chip
						v-else-if="message.action == 'left' && message.user"
						color="black"
						class="ml-5 mr-5 mb-5 text-center"
						outlined
						dark
						style="">

						<v-avatar v-if="message.user && message.user.avatar" left>
							<v-img :src="message.user.avatar"  width="100%"/>
						</v-avatar>
						{{message.user.fullname}} از مکالمه خارج  شد
					</v-chip>
				</div>
				<div v-else-if="message && message.user" :class="'chatmessage ' + (message.user.id !== account.user.id ? 'sent' : 'recieved')" >
					<div class="user">
						<v-avatar :left="message.user.id !== account.user.id" :class="'m'+(message.user.id !== account.user.id ? 'r' : 'l')+'-5'" size="40">
							<v-img :src="message.user.avatar"></v-img>
						</v-avatar>
						<span :class="message.user.id == account.user.id ? 'white--text' : '' ">{{message.user.fullname}}</span>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }"> 
								<v-chip
									:color="message.user.id == account.user.id ? 'white' : 'blue'"
									v-bind="attrs"
									v-on="on"
									small
									:class="'date ' + ( message.user.id == account.user.id ? 'black--text' : 'white--text')">
									<span v-if="message.user.id == account.user.id">
										<!-- <v-badge bordered color="green" style="position: relative;right: -15px; top: -15px;" :content="message.read"></v-badge> -->
										<v-icon v-if="message.read" small class="ml-2" color="blue">mdi-check-all</v-icon>
										<v-icon v-else small class="ml-2">mdi-check</v-icon>
									</span>
									<!-- <span v-else>
										<v-icon v-if="message.seen && message.seen.status == 'read'" small class="ml-2">mdi-check-all</v-icon>
										<v-icon v-else small class="ml-2">mdi-check</v-icon>
									</span> -->


									<v-icon small class="ml-2">mdi-calendar</v-icon>{{date(message.created_at).toString('HH:mm')}}
								</v-chip>
							</template>
							<span>{{date(message.created_at).toString('jddd, jDo jMMMM jy')}}</span>
						</v-tooltip>

					</div>
					<div
						class="msg"> 
						<div v-if="message.type == 'file'">
							<div v-if="message.message">
								<div v-if="message.message.type == 'jpg' || message.message.type == 'png' || message.message.type == 'jpeg'">
									<img width='100%' :src="message.message.path"> 
									<v-divider v-if="message.message.caption" class="mt-3 mb-3"></v-divider>
									<p v-if="message.message.caption">{{message.message.caption}}</p>
								</div>

								<div v-if="message.message.type == 'doc' || message.message.type == 'docx' || message.message.type == 'dot'">
									<v-list dir="ltr"
										dark
										class="pa-0"
										style="border-radius: 5px"
										:color="message.user.id == account.user.id ? 'blue' : 'green'"
									>
										<v-list-item
											class="pl-0"
											:href="message.message.path"
											target="_blank"
											ripple=""
											
										>
											<v-list-item-icon class="ml-0 mr-3">
												<v-icon>mdi-file-word</v-icon>
											</v-list-item-icon>
											<v-list-item-title>
												{{message.message.name}}
											</v-list-item-title>
										</v-list-item>

									</v-list>
									<v-divider v-if="message.message.caption" class="mt-3 mb-3"></v-divider>
									<p v-if="message.message.caption">{{message.message.caption}}</p>
								</div>

								<div v-if="message.message.type == 'zip'">
									<v-list dir="ltr"
										dark
										class="pa-0"
										style="border-radius: 5px"
										:color="message.user.id == account.user.id ? 'blue' : 'green'"
									>
										<v-list-item
											class="pl-0"
											:href="message.message.path"
											target="_blank"
											ripple=""
											
										>
											<v-list-item-icon class="ml-0 mr-3">
												<v-icon>mdi-folder-zip</v-icon>
											</v-list-item-icon>
											<v-list-item-title>
												{{message.message.name}}
											</v-list-item-title>
										</v-list-item>

									</v-list>
									<v-divider v-if="message.message.caption" class="mt-3 mb-3"></v-divider>
									<p v-if="message.message.caption">{{message.message.caption}}</p>
								</div>
							</div>
						</div>
						<div v-else>
							{{message.message}}
						</div>
					</div>
				</div>
			</div>
		</transition-group>
		<!-- <pre v-if="false" dir="ltr">{{ChatRoom.messages}}</pre> -->
	</div>
</template>
<style scoped>

.card {
  transition: all 0.5s;

}
.card-enter, .card-leave-to
/* .card-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: scale(0);
}
.card-enter-to {
  opacity: 1;
  transform: scale(1);
}

.card-leave-active {
  /*position: absolute;*/
}

.card-move {
  opacity: 1;
  transition: all 0.5s;
}

.chtmsg {
  transition: all 0.5s;

}
.chtmsg-enter, .chtmsg-leave-to
/* .card-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: scale(0);
}
.chtmsg-enter-to {
  opacity: 1;
  transform: scale(1);
}

.chtmsg-leave-active {
  /*position: absolute;*/
}

.chtmsg-move {
  opacity: 1;
  transition: all 0.5s;
}

.chatmessage{
	margin:10px;
	border-radius: 5px;
	background: red;
	min-width: 250px;
	width: max-content;
	max-width: calc(100% - 150px);
	padding: 15px;
	margin-top: 20px;
	/* white-space: pre; */
	word-wrap: normal;


}
.chatmessage>.user
{
	position: relative;
	top:-35px;
	right:-20px;
}
.chatmessage>.user>span
{
	position: relative;
	top:25px;
}
.chatmessage>.user>.date {
	position: absolute;
}
.chatmessage.sent>.user>.date
{
	right: -70px;
	direction: rtl;
	text-align: right;
}
.chatmessage.recieved>.user>.date
{
	left: -70px;
}
.chatmessage.recieved>.user
{
	right: -60px;
	color: #333;
}
.chatmessage.recieved
{
	border-radius: 20px 0px 30px 30px;
  margin-right: 50px;
	background: #2056F7;
	color: #FFF;
}
.chatmessage.sent
{
	float: left;
	border-radius: 0 20px 30px 30px;
  margin-left: 50px;
	background: #fff;
	border: 1px solid #2056F7;
	color: #000;
}
.chatmessage.sent>.user
{
	left: -60px;
	right: auto;
	direction: ltr;
}
</style>
<script>
import { mapState, mapActions } from 'vuex'
import {PersianDate} from '@alireza-ab/vue-persian-datepicker'


export default({
  computed: {
    ...mapState({
      account: state => state.account,

      Panel: state => state.UserPanel,
      Drawer: state => state.ChatPanel.drawer,
      ChatRoom: state => state.ChatPanel.drawer.currentRoom,

			filteredMessages: (messages) => {
				if(messages) {
					return messages
				}
				return []
			}
    }),
  },
	created(){
	},
	methods: {
		reqLoadMore() {
			this.ChatRoom.loading = true
			let roomID = this.Room.id;
			let page   = parseInt(this.ChatRoom.current_page) + 1;

			let data = {
				roomID,
				page
			}
			this.loadMore(data)
		},
		date(date)
		{
			return new PersianDate(date);
		},

		...mapActions({
			loadMore: 'ChatPanel/loadMoreMessages' ,
			markAsRead: 'ChatPanel/markAsRead' ,
		}),
	},

	props: {
		Room: Object,
		sendingMessage: Object,
	},
	data() {
		return {
			messages: []
		}
	},
	watch: {
		sendingMessage: function(Message) { 
			if(Object.keys(Message).length === 0) {
				//
			}
			else {
				// alert(JSON.stringify(Message))
				this.ChatRoom.messages.push(Message)
			}
		},
		'ChatRoom.messages': {
			handler: function(newMessages) {
				if(!newMessages) return false // no messages
				let IDs = []
				this.ChatRoom.loading = false
				Object.keys(this.ChatRoom.messages).forEach((key) => {
					let m = this.ChatRoom.messages[key]

					if(m.type && m.type == 'file') {
						try {
							this.ChatRoom.messages[key].message = JSON.parse(this.ChatRoom.messages[key].message)
						} catch (error) {
							// 
						}

					}
					// if message has a user, and the user is not me
					if(m.user && m.user.id !== this.account.user.id) {
						
						// and if the message has not already marked as seen
						if(m.seen && m.seen.status !== 'read') {
							// alert(JSON.stringify(m.seen))
							// message ID to mark as read
							IDs.push(m.id)
						}
					}
				})
				
				if(IDs.length !== 0) this.markAsRead(IDs)
			}
		}
	}
})
</script>
