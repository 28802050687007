<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :persistent="persistent"
      width="300"
    >
      <v-card
        :color="color ? color : 'primary'"
        dark
      >
        <v-card-text>
          {{ message ? message : 'در حال بارگذاری'}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {
    props: {
      isOpen: {type: Boolean},
      persistent: {type: Boolean},
			color: {type: String},
			message: {type: String},
    },
    data (instance) {
      return {
        dialog: instance.isOpen,
      }
    },
    watch: {
      isOpen: function(e)
      {
        this.dialog = e
      }
    }
  }
</script>
