<template>
  <v-app id="admin-layout" class="blue lighten-4" style="background: #eff5fb">
    <v-system-bar 
          
      color="blue lighten-4"
      class="unselectable"
      app
    >
      <v-btn 
        icon
        small
        to="/authentication/logout">
        <v-icon size="15px">mdi-logout-variant</v-icon>
      </v-btn> 
      <v-spacer></v-spacer>
      <v-chip  x-small outlined>
        {{date.toString('jdddd jDo jMMMM')}}
      </v-chip>
      <v-spacer></v-spacer>
<!-- 
      <v-btn
        icon
        small
        
        >
        <v-icon size="18px" class="ml-1">mdi-cart</v-icon>
      </v-btn> -->
    </v-system-bar>

    <v-app-bar
      color="blue darken-4"
      dark
      app
      text
      dense
      height="52"
      class="pr-5"
      
      elevate-on-scroll
      scroll-target="#basesys"
      shrink-on-scroll
    >
      <v-btn icon class=" ml-2" @click="updatePanelDrawer (!panelMenuDrawer)">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-slide-x-transition mode="out-in">
        <v-app-bar-title class="ma-0" v-if="showTitle !== ''" v-bind:key="showTitle">
            <div >
            {{ showTitle }}
            </div>
        </v-app-bar-title>
      </v-slide-x-transition>

      <v-spacer></v-spacer>  
      <v-menu
        v-model="supportMenu"
        :close-on-content-click="false"
        
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="AdminPanel.drawer.notifications.admin || AdminPanel.drawer.notifications.new"
            v-bind="attrs"
            v-on="on"
            color="orange"
            icon>
            <v-slide-y-transition>

              <v-badge
                v-show="(AdminPanel.drawer.notifications.admin.length + AdminPanel.drawer.notifications.new.length) > 0"
                :content="(AdminPanel.drawer.notifications.admin.length + AdminPanel.drawer.notifications.new.length)" color="red"
                bottom
                
                offset-x="25px"
                offset-y="25px"
                overlap
            ></v-badge>
            </v-slide-y-transition>
            <v-icon>mdi-face-agent</v-icon>
          </v-btn> 
        </template>

        <v-card
          dark
          :width="350"
          color="blue accent-4">
        
          <v-tabs
            background-color="blue accent-4"
            fixed-tabs
            :vertical="$vuetify.breakpoint.smAndDown"

            :value="supportList == 'new' ? 1 : 0"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              dark
            
              :color="supportList == 'assigned' ? 'green' : ''"
              @click="supportList = 'assigned'"
              value="assigned" class="white--text ml-2" outlined
            >
              <v-badge
                v-show="(AdminPanel.drawer.notifications.admin.length) > 0"
                :content="(AdminPanel.drawer.notifications.admin.length)" color="red"
                bottom
                
                offset-x="25px"
                offset-y="10px"
                overlap
            ></v-badge>
              گفتوگو های من
            </v-tab>

            <v-tab
              dark
              :color="supportList == 'new' ? 'green' : ''"
              @click="supportList = 'new'"
              value="new" class="white--text ml-2" outlined
            >
              <v-badge
                v-show="(AdminPanel.drawer.notifications.new.length) > 0"
                :content="(AdminPanel.drawer.notifications.new.length)" color="red"
                bottom
                
                offset-x="25px"
                offset-y="10px"
                overlap
            ></v-badge>
              گفتوگو های جدید
            </v-tab> 
          </v-tabs> 

          <v-divider></v-divider>
          <v-slide-x-reverse-transition hide-on-leave>
            <div v-show="supportList == 'new'">
              <v-alert
                class="ma-5"
                v-if="AdminPanel.drawer.notifications.new.length == 0" type="warning">
                هیچ درخواستی یافت نشد
              </v-alert>

              <v-list
                color="blue darken-4 "
                max-height="400px"
                style="overflow-y: scroll"
                class="" v-else-if="AdminPanel.drawer.notifications.new">
                <v-list-item
                  v-for="(room, index) in AdminPanel.drawer.notifications.new"
                  :key="index"
                  link
                >
                  <v-list-item-content>
                    <ChatRoom admin :Room="room"></ChatRoom>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip x-small color="green" v-if="room.admin">
                      <v-icon small class="ml-2">mdi-face-agent</v-icon>
                      {{room.admin.fullname}}
                    </v-chip>
                    <p></p>
                    <v-chip x-small v-if="room.details == 'admin_editor'">بخش ویراستار</v-chip>
                    <v-chip x-small v-if="room.details == 'admin_translator'">بخش مترجمین</v-chip>
                    <v-chip x-small v-if="room.details == 'admin_content_creator'">بخش تولید محتوی</v-chip>
                    <v-chip x-small v-if="room.details == 'admin_publisher'">بخش انتشارات</v-chip>
                    <v-chip x-small v-if="room.details == 'admin_support'">مدیریت</v-chip>
                  </v-list-item-action>
                  
                
                </v-list-item> 
              </v-list>
              <v-sheet dark color="orange" class="pa-5 text--center" v-else>
                <v-icon class="float-right ml-2">mdi-close</v-icon> هیچ گفتگویی یافت نشد
              </v-sheet>
            </div>

          </v-slide-x-reverse-transition>
          <v-slide-x-transition hide-on-leave>
            <div v-show="supportList == 'assigned'">
              <v-alert
                class="ma-5"
                v-if="AdminPanel.drawer.notifications.admin.length == 0" type="warning">
                هیچ درخواستی یافت نشد
              </v-alert>
              <v-list
                color="blue darken-4 "
                max-height="400px"
                style="overflow-y: scroll"
                class="" v-else-if="AdminPanel.drawer.notifications.admin">
                <v-list-item
                  v-for="(room, index) in AdminPanel.drawer.notifications.admin"
                  :key="index"
                  link
                >
                  <v-list-item-content>
                    <ChatRoom admin :Room="room"></ChatRoom>
                  </v-list-item-content>
                  <v-list-item-action>
                    <p></p>
                    <v-chip x-small v-if="room.details == 'admin_editor'">بخش ویراستار</v-chip>
                    <v-chip x-small v-if="room.details == 'admin_translator'">بخش مترجمین</v-chip>
                    <v-chip x-small v-if="room.details == 'admin_content_creator'">بخش تولید محتوی</v-chip>
                    <v-chip x-small v-if="room.details == 'admin_publisher'">بخش انتشارات</v-chip>
                    <v-chip x-small v-if="room.details == 'admin_support'">مدیریت</v-chip>
                  </v-list-item-action>
                  
                
                </v-list-item>
              </v-list>
              <v-sheet dark color="orange" class="pa-5 text--center" v-else>
                <v-icon class="float-right ml-2">mdi-close</v-icon> هیچ گفتگویی یافت نشد
              </v-sheet>

            </div>
          </v-slide-x-transition>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="supportMenu = false"
            >
              بستن
            </v-btn> 
          </v-card-actions>
        </v-card>
      </v-menu> 

      <!--
      <v-btn
        color="blue lighten-3"
        icon>
        <v-badge content="0" color="red"
          bottom
          
          offset-x="25px"
          offset-y="25px"
          overlap
      ></v-badge>
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn> -->

      <template v-slot:extension v-if="breadcrumbs.length !==0">
        
        <v-tabs
          align-with-title
          height="30"
          class="mt-8"
        >
          <v-tabs-slider style="background-color:transparent;"></v-tabs-slider>
            <template v-for="(item, index) in breadcrumbs" >
              <v-slide-x-transition mode="out-in" :key="index">
                <v-tab
                  v-bind:key="index"
                  dark
                  class="pa-3 v-tab--active"
                  :to="item.to"
                  aria-selected="false"
                  style="font-size:10px"
                  v-if="showTitle !== ''"
                >
                  {{ item.text }}
                </v-tab>
              </v-slide-x-transition>
            </template>
        </v-tabs>
      </template>
    </v-app-bar>
    <panel-selector-drawer customStyle="background-color: #BBDEFB !important;" :account="account" v-on:handle-drawer-change="updatePanelDrawer"  :panel="panelMenuDrawer" />
    
    <v-main class="ma-5">
      <slot />
    </v-main>



    <v-snackbar
      top
      left
      elevation="5"

    
      rounded="5"
      color="primary"
      v-model="message.active"
    >
    <v-icon right style="position:absolute; right: 15px">mdi-alert-outline</v-icon>
      <div style="padding-right: 35px;">
        {{message.text}}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="lighten-1"
          depressed
          small
          v-bind="attrs"
          @click="message.active = false"
        >
          باشه
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<style>
.fade-in-enter-active {
  transition: all 0.5s ease;
}

.fade-in-leave-active {
  transition: all 0.5s ease;
}

.fade-in-enter, .fade-in-leave-to {
  position: absolute; /* add for smooth transition between elements */
  opacity: 0;
}
.v-app-bar-title__content{
  width: 200px !important;
}
.v-application--is-rtl .v-tabs--align-with-title > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing) > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tabs-slider-wrapper + .v-tab{
  margin-right: 0px;
  padding-right: 0px;
}
.v-toolbar__title
{
  padding-right: 0px !important;
}
</style>
<script> 

import PanelSelectorDrawer from '@/components/Panels/General/PanelSelectorDrawer.vue';
import { mapState, mapActions } from 'vuex'
import {PersianDate} from '@alireza-ab/vue-persian-datepicker'
import ChatRoom from '@/components/Chat/ChatRoom.vue';
export default {

  name: "AdminPanel",
  components: {
    PanelSelectorDrawer,
    ChatRoom
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'داشبورد',
    // all titles will be injected into this template
    titleTemplate: 'مدیریت |‌ %s',
    description: 'test',
  }, 
  data () {
    return {
      showTitle: this.$route.meta.layoutTitle,
      breadcrumbs: [],
      breadcrumbModel: null,
      
      items: [],
      date: new PersianDate(),
      panelMenuDrawer: ['md','lg','xl'].includes(this.$vuetify.breakpoint.name) ? true : false,
      drawer: false,
      PanelSelectorDrawer: false,
      
      message: {
        active: false,
        text: [],
        type: 'error',
      },

      supportMenu: false, // dialog
      supportList: 'new', // assigned | new
    }
  },

  computed: {
    ...mapState({
			AdminPanel: state => state.AdminPanel,
      account: state => state.account,
      users: state => state.users.all
    }),
  }, 
  created () {
    this.breadcrumbs = JSON.parse(JSON.stringify(this.$route.meta.breadcrumbs))
    const index = this.$route.meta.breadcrumbs.length-1;
    if (index > -1) {
      this.breadcrumbs.splice(index, 1); 
    }

    // this.account.user.addRule('admin')
    this.items = [
      {
        title: 'پنل مدیر',
        status: this.account.user.access.includes('admin'),
        description: 'فعال سازی پنل دمو مدیریت'
      },
      {
        title: 'پنل مترجم',
        status: false,
        description: 'فعال سازی پنل دمو ترجمه'
      },
      {
        title: 'پنل مدیر مترجم',
        status: false,
        description: 'فعال سازی پنل دمو مدیر ترجمه'
      },
      {
        title: 'پنل ویراستار',
        status: false,
        description: 'فعال سازی پنل دمو مدیریت'
      },
      {
        title: 'پنل مدیر ویراستار',
        status: false,
        description: 'فعال سازی پنل دمو مدیریت'
      },
      {
        title: 'پنل ناشران',
        status: false,
        description: 'فعال سازی پنل دمو مدیریت'
      },
      {
        title: 'پنل مدیر ناشران',
        status: false,
        description: 'فعال سازی پنل دمو مدیریت'
      },
      {
        title: 'پنل تولید محتوی',
        status: false,
        description: 'فعال سازی پنل دمو مدیریت'
      },
      {
        title: 'پنل مدیر تولید محتوی',
        status: false,
        description: 'فعال سازی پنل دمو مدیریت'
      },
    ]
  },
  methods: {
    changeTitle(e){
      alert(e)
    },
    toggle(v){
      this.$emit('handle-drawer-change', v)
    },
    updatePanelDrawer(drawerStatus) { 
      this.panelMenuDrawer = drawerStatus
    },
    ...mapActions('users', {
      getAllUsers: 'getAll',
      deleteUser: 'delete'
    })
  },
  /**
   * IMPORTANT: Delete Admin Panel Data Updater interval
   */
  beforeDestroy () {
    clearInterval(this.AdminPanel.drawer.timer);
  },
  watch:{

		'AdminPanel.status': function(requestStatus){
      if(requestStatus == 'error' && this.AdminPanel.alert.message) {
        //
        this.message.active = true // open snack 
        this.message.text = this.AdminPanel.alert.message
      }
      if(requestStatus == 'done' && this.AdminPanel.alert.message) {
        //
        this.message.active = true // open snack 
        this.message.text = this.AdminPanel.alert.message
      }

    },
    '$route': function(){
      this.showTitle = '';
    },
    '$route.meta.breadcrumbs': function(){
      this.breadcrumbs = JSON.parse(JSON.stringify(this.$route.meta.breadcrumbs))
      this.showTitle = this.$route.meta.layoutTitle;
      const index = this.$route.meta.breadcrumbs.length-1;
      if (index > -1) {
        this.breadcrumbs.splice(index, 1); 
      }
    }
  }
}; 
</script>
