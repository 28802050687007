import { formService } from '../services';
const state = {
	ClientRequest: {
		form: {}
	},
	alert: {
		type: null, // success, info , warning, error
		title: null,
		message: null,
	},

	action: null,
	status: null,
	result: null,

	RequestController: new AbortController(), // Fetch API Request
};

const actions = {
	/**
	 * Calculate Price
	 * 
	 * @param {*} commit 
	 * @param {*} section 
	 */
	calculateRequestPrice({ commit }, Request) {
		let Output = []
		commit
		let submitted = JSON.parse(Request.form_structure)
		switch (submitted.type)
		{
			case 'simple':
				Object.keys(Request.form_values).forEach((key) => {
					let input = Request.original.configuration.steps.data[0].DOM[Request.form_values[key].position.row].child[Request.form_values[key].position.column].inputConfig
					
					switch (input.ElementType) {
						case 'DropDownField':
							// input.itemPrices[OptionValue.option_index]
							console.log(Request.form_values)
								
							var selectedOptions = Request.form_values[key].value

							Object.keys(selectedOptions).forEach((optIndex) => {
								Output.push({
									name		:  Request.form_values[key].name + " » " +selectedOptions[optIndex].value,
									description	: null,
									price		: input.itemPrices[selectedOptions[optIndex].option_index]
								})
							})

							break;
					
						default:
							break;
					}
					return Output
					// if(!this.keys.includes(Request.form_values[key]))
					// 	this.keys.push(Request.form_values[key].name)
				})
				break;
			case 'steps':
			
				var steps = Request.original.configuration.steps.data;

				
				Object.keys(steps).forEach((key) => {
					key
					// if(!this.steps.includes(steps[key])) this.steps.push(steps[key].name)

					Object.keys(Request.form_values).forEach((key) => {
					
						// let s = [];

						//
						Object.keys(Request.form_values[key]).forEach((stepIndex) => {
							stepIndex
							// s.push(Request.form_values[key].values)

						});
						// 
						// this.keys.push(s)
					})

				})
				break;
		
			default:
			break;
		}
		return Output
	},

	/**
	 * Get Form By Section
	 * 
	 * @param {*} commit 
	 * @param {*} section 
	 */
	getForm({ commit }, section) {
		commit('RequestStarted', 'getForm');

		formService.getForm(section).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Save a Form Request
	 * 
	 * @param {*} commit 
	 * @param {*} section 
	 * @param {*} FormID 
	 * @param {*} data 
	 */
	saveFormRequest({ commit }, FormID, data) {
		commit('RequestStarted', 'saveFormRequest');

		formService.saveForm(FormID, data, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Something went wrong with our request
	 * 
	 * @param {*} commit 
	 * @param {*} message
	 */
	error({ commit }, message) {
		commit('error', message);
	},
	

	/**
	 * Something went wrong with our request
	 * 
	 * @param {*} commit 
	 * @param {*} message
	 */
	clear({ commit }) {
		commit('clear');
	}
};

const mutations = {

	/**
	 * New Request has been made
	 * 
	 * @param {*} commit 
	 * @param {*} section
	 */
	RequestStarted(state, section) {
		state.result	= null
		state.action	= section
		state.status 	= 'loading'
	},

	/**
	 * A Request has been finished successfully
	 * 
	 * @param {*} commit 
	 * @param {*} response
	 */
	RequestDone(state, response) {
		switch (state.action) {
			case 'getForm':
				response.form.configuration = (typeof response.form.configuration === 'string' || response.form.configuration instanceof String) ? JSON.parse(response.form.configuration) :  response.form.configuration
				state.result = response
				
				break;
		
			default:
				state.result = response
				break;
		}
		state.status  = 'done'
	},

	/**
	 * A Request has failed to finish
	 * 
	 * @param {*} commit 
	 * @param {*} response
	 */
	error(state, data) {
		if(data == 'AbortError: The user aborted a request.')
			return true; // cool
		state.result = data.data ? data.data : ( data.message ? data.message : data.status) 
		state.status = 'error'
		

		if(!data.OK && data.errors || data.messages) {
			state.alert.type = 'error'
			state.alert.title = 'خطا'
			if(data.errors)
			{

				Object.keys(data.errors).forEach((errorKey) => {
					state.alert.message = (state.alert.message ? state.alert.message : '') + "<p>"+data.errors[errorKey]+"</p>"
				})
			}
			else if(data.messages) {

				Object.keys(data.messages).forEach((errorKey) => {
					state.alert.message = (state.alert.message ? state.alert.message : '') + "<p>"+data.messages[errorKey]+"</p>"
				})
			}
		}
	},

	/**
	 * Clear the State
	 * 
	 * @param {*} commit 
	 * @param {*} response
	 */
	clear(state) {
		state.result = null
		state.action = null;
		state.status = null;

		state.alert = {
			type: null,
			title: null,
			message: null,
		}
	}
};

export const customForm = {
	namespaced: true,
	state,
	actions,
	mutations
};