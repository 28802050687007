<template>
  <v-app style="height: max-content" id="auth_de"  class="vapp grey lighten-3"> 
    

    <v-row justify="center" :class="'screen_state_' + this.$vuetify.breakpoint.name+' row transition full-height'" style="position:relative; margin:0px;padding:0px; z-index:2">
      
      <v-col cols="12" sm="4" md="7" lg="8" :class="'deep-purple darken-4 transition bbax screen_state_' + this.$vuetify.breakpoint.name" style="background-image: url('https://picsum.photos/1920/1080?random'); box-shadow: inset: 0px 0px 10px rgba(0,0,0,.5)">
        <div class="mobile_nav">

        </div>
        <v-tabs
          :vertical="this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'xs'"
          background-color="deep-purple accent-4"
          dark
          style=""
          grow
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab to="/">
            <v-icon style="position: absolute; right: 10px" class="ml-2">mdi-home</v-icon>
            اهل سخن
          </v-tab>

          <v-tab to="/authentication/login">
            <v-icon style="position: absolute; right: 10px" class="ml-2">mdi-account-arrow-right</v-icon>
            ورود به سامانه
          </v-tab>

          <v-tab to="/authentication/register">
            <v-icon style="position: absolute; right: 10px" class="ml-2">mdi-account-plus-outline</v-icon>
            ثبت نام
          </v-tab>

          <v-tab to="/authentication/recover/via/sms">
            <v-icon style="position: absolute; right: 10px" class="ml-2">mdi-account-convert</v-icon>
            بازیابی حساب کاربری
          </v-tab>
        </v-tabs>

      </v-col>
      <v-col cols="10" sm="8" md="5" lg="4" :class="'transition elevation-20 authorization screen_state_' + this.$vuetify.breakpoint.name">
          <slot />
      </v-col>
    </v-row>
  </v-app>
</template>
<style scoped>
.mobile_nav
{
  display: none;
}
.screen_state_xs.row
{
  display: block;
}
.screen_state_md.authorization,
.screen_state_sm.authorization,
.screen_state_lg.authorization
{
  overflow-x: scroll;
}
.authorization.screen_state_xs
{
  margin: auto;
  margin-top:-70px;
  border-radius: 10px;;
  margin-bottom: 10px;;
  box-shadow: 0px 0px 10px rgba(0,0,0,.51);
}
.screen_state_xs.bbax
{
  height: 300px;
}
.screen_state_xs .mobile_nav
{
  display: block;
}
.transition{
  transition: 500ms all;
}
.authorization
{
  background: #FFF;
  position: relative;
  z-index: 10;
}
.authorization.done
{
  padding: 0px !important;
  margin: 0px !important;
  border-radius: 0px;
}
.full-height{
  height: 100vh;
  min-height:max-content
}
.mnav
{
  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 40%; /* Need a specific value to work */
  padding: 15px;
  border-radius: 12px;
  margin-top: 20%;
  text-align: center;
}
</style>
<script>

export default ({
  name: "AuthenticationLayout",

	computed: { 
	},
  setup() {
    
  },
})
</script>

