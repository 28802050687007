import { Admin } from '@/services/Admin/index';
// import { UserPanelService } from '@/services/panel.user.service';
import {formService} from "@/services/form.service"

const state = {
	drawer: {
		loading: true,
		timer: null,
		open: false,
		notifications: {
			admin: [],
			new  : [],
		},
		items: []
	},
	alert: {
		type: null, // success, info , warning, error
		title: null,
		message: null,
	},
	status: null,
	result: null,
	action: null,

	RequestController: new AbortController(), // Fetch API Request
};

const actions = {
	requestPanelData({ commit }, section) {
		section
		commit('PanelDataRequested', 'requestPanelData');

		Admin.getAdminPanelData().then(
			result => commit('PanelDataDone', result.data),
			error => commit('error', error)
		)

	},

	/**
	 * Request Admin Dashboard 
	 * 
	 * @param {*} param0 
	 */ 
	requestDashboard({ commit } ) {
		commit('RequestStarted', 'requestDashboard');

		Admin.getAdminDashboard(state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request User Wallet Info 
	 * 
	 * @param {*} param0 
	 */ 
	getWalletInformation({ commit }, UserID ) {
		commit('RequestStarted', 'getWalletInformation');

		Admin.getWalletInformation(UserID, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Checkout User Wallet Credit
	 * 
	 * @param {*} param0 
	 */ 
	checkoutUserWallet({ commit }, transaction ) {
		commit('RequestStarted', 'checkoutUserWallet');

		Admin.checkoutUserWallet(transaction , state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request to get single book
	 * 
	 * @param {*} project
	 * @param {*} filters 		
	 */
	getBook({ commit, state}, id) {
		commit('RequestStarted', 'getBook');
		Admin.getAllBooks({id: id}, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request to get single book
	 * 
	 * @param {*} project
	 * @param {*} filters 		
	 */
	uploadBook({ commit, state}, book) {
		commit('RequestStarted', 'uploadBook');
		Admin.uploadBook(book, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request Delete a Book 
	 * 
	 * @param {*} bookID 
	 */
	deleteBook({ commit }, bookID) {

		commit('RequestStarted', 'deleteBook');

		Admin.deleteBook(bookID).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request to get books
	 * 
	 * @param {*} project
	 * @param {*} filters 		
	 */
	requestAllBooks({ commit, state}, filters) {
		commit('RequestStarted', 'requestAllBooks');
		Admin.getAllBooks(filters, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},
	/**
	 * Request to get book collaborators
	 * 
	 * @param {*} project
	 * @param {*} filters 		
	 */
	getBookCollaborators({ commit, state}, filters) {
		commit('RequestStarted', 'getBookCollaborators');
		Admin.getCollaborators(filters, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request to create or update collaborator
	 * 
	 * @param {*} project
	 * @param {*} filters 		
	 */
	saveCollaborator({ commit, state}, collaborator) {
		commit('RequestStarted', 'saveCollaborator');
		Admin.saveCollaborator(collaborator, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request to get book categories
	 * 
	 * @param {*} project
	 * @param {*} filters 		
	 */
	getBookCategories({ commit, state}, filters) {
		commit('RequestStarted', 'getBookCategories');
		Admin.getBookCategories(filters, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Create or update a new Book Category
	 * 
	 * @param {*} project
	 * @param {*} filters 		
	 */
	saveBookCategory({ commit, state}, category) {
		commit('RequestStarted', 'createBookCategory');

		Admin.addBookCategory(category, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request Delete a Book Category 
	 * 
	 * @param {*} catID 
	 */
	requestDeleteBookCategory({ commit }, catID) {

		commit('RequestStarted', 'requestDeleteBookCategory');

		Admin.deleteBookCategory(catID).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request User List 
	 * 
	 * @param {*} param0 
	 * @param {*} options 
	 */
	requestUsers({ commit, state}, options) {
		commit('RequestStarted', 'requestUsers');

		Admin.getAllUsers(options, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request User Groups List 
	 * 
	 * @param {*} param0 
	 * @param {*} options 
	 */
	requestUserGroups({ commit, state }) { 
		commit('RequestStarted', 'requestUserGroups');

		Admin.getUserGroups(state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request User By ID
	 * 
	 * @param {*} userID 		User ID
	 */
	requestUserByID({ commit }, userID) {

		commit('RequestStarted', 'requestUserByID');

		Admin.getUserByID(userID, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request Create User
	 * 
	 * @param {*} formData 		New User Form Data
	 */
	requestCreateUser({ commit }, formData) {

		commit('RequestStarted', 'requestCreateUser');

		Admin.createUser(formData, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Save A User
	 * 
	 * @param {*} userID 		User ID
	 */
	saveUser({ commit }, userID) {

		commit('RequestStarted', 'requestUserByID');

		Admin.saveUser(userID, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request Invoices
	 * @param {*} commit 
	 * @param {*} filters
	 */
	requestInvoices({ commit }, filters) {
		commit('RequestStarted', 'requestInvoices');

		Admin.requestInvoices(filters, state.RequestController.signal).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	}, 

	/**
	 * setInvoiceAsPaid  
	 * @param {*} commit 
	 * @param {*} filters
	 */
	setInvoiceAsPaid({ commit }, invoiceID, receipt_number) {
		commit('RequestStarted', 'setInvoiceAsPaid');

		Admin.setInvoiceAsPaid(invoiceID, receipt_number, state.RequestController.signal).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	}, 

	/**
	 * Delete Invoice
	 * @param {*} commit 
	 * @param {*} filters
	 */
	deleteInvoice({ commit }, invoiceID) {
		commit('RequestStarted', 'deleteInvoice');

		Admin.deleteInvoice(invoiceID, state.RequestController.signal).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	}, 

	/**
	 * Request Form List 
	 * 
	 * @param {*} param0 
	 * @param {*} options 
	 */
	requestForms({ commit }, request) {

		commit('RequestStarted', 'requestForms');

		//
		Admin.getFormList(request, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request Delete a Form
	 * 
	 * @param {*} param0 
	 * @param {*} options 
	 */
	deleteForm({ commit }, formID) {

		commit('RequestStarted', 'deleteForm');

		//
		Admin.deleteForm(formID, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request Form Categories List 
	 * 
	 * @param {*} param0 
	 * @param {*} options 
	 */
	requestFormCategories({ commit }) {

		commit('RequestStarted', 'requestFormCategories');

		formService.getCategories(state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request Save a Form Category 
	 * 
	 * @param {*} Payload 
	 */
	requestSaveCategory({ commit }, Payload) {

		commit('RequestStarted', 'requestSaveCategory');

		Admin.addFormCategory(Payload, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request Delete a Form Category 
	 * 
	 * @param {*} catID 
	 */
	requestDeleteCategory({ commit }, catID) {

		commit('RequestStarted', 'requestDeleteCategory');

		Admin.deleteFormCategory(catID, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request Form Design Submission
	 * 
	 * @param {*} FormObject 		JSON Object of a Form
	 * @param {*} Locate 			Whether or not Locate ( or create if not found ) the pending Form
	 */
	requestFormDesign({ commit }, FormObject, Locate) {

		commit('RequestStarted', 'requestFormDesign');

		formService.formDesignPage({FormObject, Locate}, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	}, 

	/**
	 * Request List of Submitted forms for a given form
	 * 
	 * @param {*} ID 				JSON Object of a Form
	 * @param {*} Values 			Array of Values
	 */
	getFormRequestsList({ commit, state}, Request) {

		commit('RequestStarted', 'getFormRequestsList');

		Admin.getFormRequestsList(Request, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Delete a requested form
	 * 
	 * @param {*} ID 				JSON Object of a Form
	 * @param {*} Values 			Array of Values
	 */
	deleteFormRequest({ commit, state}, RequestID) {

		commit('RequestStarted', 'deleteFormRequest');

		Admin.deleteFormRequest(RequestID, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request Projects List 
	 * 
	 * @param {*} param0 
	 * @param {*} filters 		Object of Filters to be made upon request {limit: 10, ...}
	 */
	requestProjects({ commit, state}, filters) {
		commit('RequestStarted', 'requestProjects');

		Admin.getProjects(filters, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Create an Invoice for the given Custom Form Request
	 * 
	 * @param {*} param0 
	 * @param {*} filters 		Object of Filters to be made upon request {limit: 10, ...}
	 */
	createInvoiceForRequest({ commit, state}, Invoice) {
		commit('RequestStarted', 'createInvoiceForRequest');

		Admin.createInvoiceForRequest(Invoice, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	/**
	 * Request to create a new project
	 * 
	 * @param {*} param0 
	 * @param {*} filters 		Object of Filters to be made upon request {limit: 10, ...}
	 */
	createProject({ commit, state}, project) {
		commit('RequestStarted', 'createProject');

		Admin.createProject(project, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},
	
	/**
	 * Request to modify a project
	 * 
	 * @param {*} project
	 * @param {*} filters 		
	 */
	modifyProject({ commit, state}, project) {
		commit('RequestStarted', 'modifyProject');

		Admin.modifyProject(project, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},
	
	/**
	 * Request to delete a project
	 * 
	 * @param {*} param0 
	 * @param {*} filters 		Object of Filters to be made upon request {limit: 10, ...}
	 */
	deleteProject({ commit, state}, project) {
		commit('RequestStarted', 'deleteProject');

		Admin.deleteProject(project, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},
	
	/**
	 * Get System Settings
	 * 
	 * @param {*} param0 
	 */
	getSettings({ commit, state }) {
		commit('RequestStarted', 'getSettings');

		Admin.getSettings(state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},
	
	/**
	 * Set System Settings
	 * 
	 * @param {*} param0 
	 * @param {*} data 		Object of Filters to be made upon request {limit: 10, ...}
	 */
	setSettings({ commit, state }, data) {
		commit('RequestStarted', 'setSettings');

		Admin.setSettings(data, state.RequestController.signal).then(
			result => commit('RequestDone', result),
			error => commit('error', error)
		)
	},

	
};

const mutations = {
	RequestStarted(state, action) {
		
		state.result = null
		state.action = action
		state.status  = 'loading'

		state.alert = {
			type: null,
			title: null,
			message: null,
		}

		if(state.status == 'loading')
		{
			state.RequestController.abort()
			state.RequestController = new AbortController()
		}
	},
	RequestDone(state, data) {
		state.result = data.data ? data.data : ( data.message ? data.message : data.status) 
		state.status  = 'done'
		if(data.message) {
			state.alert.type = 'message'
			state.alert.title = 'انجام شد'
			if(data.messages) {
				Object.keys(data.messages).forEach((key) => {
					state.alert.message = (state.alert.message ? state.alert.message : '') + "<p>"+data.messages[key]+"</p>"
				})
			}
			else {
				state.alert.message = data.message
			}
		}
	},

	error(state, data) {
		if(data == 'AbortError: The operation was aborted.' || data == 'AbortError: The operation was aborted. ' || data == 'AbortError: The user aborted a request.')
			return true; // cool
		state.result = {
			message: data.message,
			errors: data.errors ? data.errors : (data.messages ? data.messages : []),
		}
		state.status  = 'error'
		

		if(!data.OK && data.errors) {
			state.alert.type = 'error'
			state.alert.title = 'خطا'
			Object.keys(data.errors).forEach((errorKey) => {
				state.alert.message = (state.alert.message ? state.alert.message : '') + "<p>"+data.errors[errorKey]+"</p>"
			})
		}
	},

	PanelDataRequested(state) {
		if(!state.drawer.timer) {
			// First time initiate
			state.drawer.loading = true
			state.drawer.items = []
		}

		if(state.drawer.timer) clearInterval(state.drawer.timer)

		state.drawer.timer = setInterval(() => {
			state.drawer.loading = true
	
			this.dispatch('AdminPanel/requestPanelData', { });
		}, 5000)

	},
	PanelDataDone(state, data) {
		state.drawer.loading = false
		if(data.notifications) state.drawer.notifications = data.notifications
		if(data.menu_items) state.drawer.items = data.menu_items
	},
	clear(state) {
		state.result = null
		state.type = null;
		state.status = null;

		state.alert = {
			type: null,
			title: null,
			message: null,
		}
	}
};

export const AdminPanel = {
	namespaced: true,
	state,
	actions,
	mutations
};