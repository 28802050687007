import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OrdersView from '../views/OrdersView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'default',
      // help: 'HelpHomePage',
    },
    component: OrdersView
  },
  {
    path: '/orders',
    name: 'OrdersHome',
    meta: {
      layout: 'default',
    },
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      layout: 'default',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/@:username',
    name: 'UserPublicProfile',
    meta: {
      layout: 'main-panel',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/User/DashboardView.vue')
  }, 
  {
    path: '/panel/account',
    name: 'UserDashboard',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'داشبورد کاربری',
      layout: 'main-panel',
    },
    // route level code-splitting
    
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/User/DashboardView.vue')
  }, 
  
  // ----------------------------------------------------------------------

  {
    path: '/panel/account/books/my-library',
    name: 'UserLibraryView',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'کتابخانه من',
      layout: 'main-panel',
    },
    component: () => import('../views/User/LibraryView.vue')
  }, 
  
  // ----------------------------------------------------------------------

  {
    path: '/panel/account/books/marked',
    name: 'UserLibraryView',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'کتاب های علامت گذاری شده',
      layout: 'main-panel',
    },
    component: () => import('../views/User/LibraryView.vue')
  }, 
  
  {
    path: '/panel/account/invoices',
    name: 'InvoiceList',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'فاکتور ها',
      layout: 'main-panel',
    },
    component: () => import( '../views/User/InvoiceListView.vue')
  },
  {
    path: '/panel/account/invoices/view/:id',
    name: 'ViewInvoice',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'فاکتور ها',
      layout: 'main-panel',
    },
    component: () => import( '../views/User/InvoiceView.vue')
  }, 
  {
    path: '/panel/account/wallet',
    name: 'WalletPage',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'کیف پول',
      layout: 'main-panel',
    },
    component: () => import( '../views/User/WalletView.vue')
  }, 
  {
    path: '/panel/account/profile',
    name: 'ProfileSettings',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'تنظیمات کاربری',
      layout: 'main-panel',
    },
    component: () => import( '../views/User/ProfileSettingsView.vue')
  }, 
  {
    path: '/panel/account/referral',
    name: 'UserReferralsPage',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'سامانه معرف',
      layout: 'main-panel',
    },
    component: () => import( '../views/User/ReferralsView.vue')
  }, 
  {
    path: '/panel/account/order',
    name: 'order',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'ثبت سفارش',
      layout: 'main-panel',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/User/Orders/RequestView.vue')
  },
  {
    path: '/panel/account/order/:form_id',
    name: 'ClientRequestOrder',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'ثبت سفارش جدید',
      layout: 'main-panel',
    },
    component: () => import( '../views/User/Orders/DynamicFormRequestView.vue')
  },
  {
    path: '/panel/account/register/translator',
    name: 'RegisterTranslator',
    meta: {
      logged_in: true,
      layoutTitle: 'ثبت نام مترجم',
      access: 'user',
      layout: 'main-panel',
    },
    component: () => import('../views/User/Register/TranslatorView.vue')
  },

  {
    path: '/panel/account/register/publisher',
    name: 'RegisterPublisher',
    meta: {
      logged_in: true,
      layoutTitle: 'ثبت نام ناشر',
      access: 'user',
      layout: 'main-panel',
    },
    component: () => import('../views/User/Register/PublisherView.vue')
  },

  {
    path: '/panel/account/register/writer',
    name: 'RegisterWriter',
    meta: {
      logged_in: true,
      layoutTitle: 'ثبت نام ویراستار',
      access: 'user',
      layout: 'main-panel',
    },
    component: () => import('../views/User/Register/WriterView.vue')
  },

  {
    path: '/panel/account/register/content_creator',
    name: 'RegisterContentCreator',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'ثبت نام متخصص تولید محتوا',
      layout: 'main-panel',
    },
    component: () => import('../views/User/Register/ContentCreatorView.vue')
  },
  /**
   * Support Panel (Admin)
   */
  {
    path: '/panel/support', //dashboard
    name: 'AdminSupport',
    meta: {
      logged_in: true,
      layout: 'support-panel',
      layoutTitle: 'داشبورد',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        }, 
      ],
    },
    metaInfo: {
      title: 'داشبورد',
      titleTemplate: 'مدیریت |‌ %s',
      description: 'test', 
    },
    component: () => import('../views/Admin/DashboardView.vue')
  }, 
  
  /**
   * Admin Related Routes
   */
  {
    path: '/panel/admin', //dashboard
    name: 'AdminDashboard',
    meta: {
      logged_in: true,
      access: [
        'admin',
        'admin_translator',
        'admin_publisher',
        'admin_content_creator',
        'admin_editor',
      ],
      layout: 'admin-panel',
      layoutTitle: 'داشبورد',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        }, 
      ],
    },
    metaInfo: {
      title: 'داشبورد',
      titleTemplate: 'مدیریت |‌ %s',
      description: 'test', 
    },
    component: () => import('../views/Admin/DashboardView.vue')
  }, 


  {
    path: '/panel/admin/settings', //dashboard
    name: 'AdminSettings',
    meta: {
      logged_in: true,
      access: [
        'admin',
      ],
      layout: 'admin-panel',
      layoutTitle: 'تنظیمات سامانه',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        }, 
      ],
    },
    metaInfo: {
      title: 'تنظیمات سامانه',
      titleTemplate: 'مدیریت |‌ %s',
      description: 'تنظیمات سامانه', 
    },
    component: () => import('../views/Admin/Setting/WebsiteView.vue')
  }, 

  {
    path: '/panel/admin/settings/emails', //dashboard
    name: 'AdminEmailSettings',
    meta: {
      logged_in: true,
      access: [
        'admin',
      ],
      layout: 'admin-panel',
      layoutTitle: 'ایمیل های سامانه',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        }, 
        {
          text: 'تنظیمات',
          disabled: false,
          to: '/panel/admin/settings',
        }, 
      ],
    },
    metaInfo: {
      title: 'تنظیمات سامانه',
      titleTemplate: 'مدیریت |‌ %s',
      description: 'تنظیمات سامانه', 
    },
    component: () => import('../views/Admin/Setting/EmailsView.vue')
  }, 

  {
    path: '/panel/admin/users/list', // users
    name: 'AdminManageUsers',
    meta: {
      logged_in: true,
      access: [
        'admin',
        'admin_translator',
        'admin_publisher',
        'admin_content_creator',
        'admin_editor',
      ],
      layout: 'admin-panel',
      layoutTitle: 'مدیریت کاربران',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        },
        {
          text: 'مدیریت کاربران',
          disabled: false,
          to: '/panel/admin/users/list',
        },
      ],
    },
    component: () => import('../views/Admin/Users/ManageView.vue')
  }, 
  {
    path: '/panel/admin/users/user/:id',
    name: 'AdminModifyUsers',
    meta: {
      logged_in: true,
      access: [
        'admin',
        'admin_translator',
        'admin_publisher',
        'admin_content_creator',
        'admin_editor',
      ],
      layout: 'admin-panel',
      layoutTitle: 'مدیریت کاربر',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        },
        {
          text: 'مدیریت کاربران',
          disabled: false,
          to: '/panel/admin/users/list',
        },
        {
          text: 'مدیریت کاربر',
          disabled: false,
        },
      ],
    },
    component: () => import('../views/Admin/Users/ManageUserView.vue')
  }, 
  {
    path: '/panel/admin/users/new',
    name: 'AdminNewUser',
    meta: {
      logged_in: true,
      access: 'admin',
      layout: 'admin-panel',
      layoutTitle: 'مدیریت کاربر',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        },
        {
          text: 'مدیریت کاربران',
          disabled: false,
          to: '/panel/admin/users/list',
        },
        {
          text: 'مدیریت کاربر',
          disabled: false,
        },
      ],
    },
    component: () => import('../views/Admin/Users/ManageUserView.vue')
  }, 
  {
    path: '/panel/admin/users/groups', // users
    name: 'AdminManageGroups',
    meta: {
      logged_in: true,
      access: 'admin',
      layout: 'admin-panel',
      layoutTitle: 'گروه های کاربری',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        },
        {
          text: 'مدیریت کاربران',
          disabled: false,
          to: '/panel/admin/users/list',
        },
        {
          text: 'گروه های کاربری',
          disabled: false,
          to: '/panel/admin/users/groups',
        },
      ],
    },
    component: () => import('../views/Admin/Users/ManageGroupsView.vue')
  }, 

  // ----------------------------------------------------------------------
  
  
  {
    path: '/panel/admin/projects/list', // users
    name: 'AdminProjectsList',
    meta: {
      logged_in: true,
      access: [
        'admin',
        'admin_translator',
        'admin_publisher',
        'admin_content_creator',
        'admin_editor',
      ],
      layout: 'admin-panel',
      layoutTitle: 'مدیریت پروژه ها',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        },
        {
          text: 'مدیریت پروژه ها',
          disabled: false,
          to: '/panel/admin/projects/list',
        },
      ],
    },
    component: () => import('../views/Admin/Projects/ManageView.vue')
  }, 

  // ----------------------------------------------------------------------
  
  
  {
    path: '/panel/admin/invoices/list', // users
    name: 'AdminInvoiceList',
    meta: {
      logged_in: true,
      access: [
        'admin',
        'admin_translator',
        'admin_publisher',
        'admin_content_creator',
        'admin_editor',
      ],
      layout: 'admin-panel',
      layoutTitle: 'مدیریت فاکتور ها',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        },
        {
          text: 'مدیریت فاکتور ها',
          disabled: false,
          to: '/panel/admin/invoices/list',
        },
      ],
    },
    component: () => import('../views/Admin/ManageInvoicesView.vue')
  }, 

  // ----------------------------------------------------------------------
  
  {
    path: '/panel/admin/forms/design',
    name: 'AdminDesignForm',
    meta: {
      logged_in: true,
      access: 'admin',
      layout: 'admin-panel',
      layoutTitle: 'طراحی فرم',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        },
        {
          text: 'فرم های سفارشی',
          disabled: false,
          to: '/panel/admin/forms/list',
        },
        {
          text: 'طراحی فرم',
          disabled: false,
          to: '/panel/admin/forms/design',
        },
      ],
    },
    component: () => import('../views/Admin/Forms/DesignView.vue')
  }, 
  
  {
    path: '/panel/admin/forms/categories',
    name: 'AdminDesignFormCategories',
    meta: {
      logged_in: true,
      access: 'admin',
      layout: 'admin-panel',
      layoutTitle: 'دسته بندی فرم ها',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        },
        {
          text: 'فرم های سفارشی',
          disabled: false,
          to: '/panel/admin/forms/list',
        },
        {
          text: 'دسته بندی فرم ها',
          disabled: false,
          to: '/panel/admin/forms/categories',
        },
      ],
    },
    component: () => import('../views/Admin/Forms/CategoriesView.vue')
  }, 
  
  {
    path: '/panel/admin/forms/list',
    name: 'AdminDesignFormList',
    meta: {
      logged_in: true,
      access: 'admin',
      layout: 'admin-panel',
      layoutTitle: 'فرم های سفارشی',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: null,
          to: '/panel/admin',
        },
        {
          text: 'فرم های سفارشی',
          disabled: null,
          to: '/panel/admin/forms/list',
        },
      ],
    },
    component: () => import('../views/Admin/Forms/ListView.vue')
  }, 
  
  {
    path: '/panel/admin/client/request/:cat/:id',
    name: 'AdminManageCustomForms',
    meta: {
      logged_in: true,
      access: [
        'admin',
        'admin_translator',
        'admin_publisher',
        'admin_content_creator',
        'admin_editor',
      ],
      layout: 'admin-panel',
      layoutTitle: 'مدیریت سفارش ها',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: null,
          to: '/panel/admin',
        },
      ],
    },
    component: () => import('../views/Admin/Forms/ManageRequestsView.vue')
  },
  // ----------------------------------------------------------------------
  
  /**
   * Admin Booking
   */

   {
    path: '/panel/admin/books/categories', //dashboard
    name: 'AdminBooksCategory',
    meta: {
      logged_in: true,
      access: [
        'admin',
        'admin_booker'
      ],
      layout: 'admin-panel',
      layoutTitle: 'دسته بندی کتاب ها',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        }, 
        {
          text: 'لیست کتاب ها',
          disabled: false,
          to: '/panel/admin/books/manage',
        }, 
      ],
    },
    metaInfo: {
      title: 'دسته بندی کتاب خانه',
      titleTemplate: 'مدیریت |‌ %s',
      description: 'test', 
    },
    component: () => import('../views/Admin/Books/ManageCategoriesView.vue')
  }, 
  
  // ----------------------------------------------------------------------
  
  /**
   * Admin Booking
   */

   {
    path: '/panel/admin/books/list', //dashboard
    name: 'AdminBooksList',
    meta: {
      logged_in: true,
      access: [
        'admin',
        'admin_booker'
      ],
      layout: 'admin-panel',
      layoutTitle: 'مدیریت کتاب ها',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        }, 
      ],
    },
    component: () => import('../views/Admin/Books/ManageBooksView.vue')
  }, 
  
  // ----------------------------------------------------------------------
  
  /**
   * Admin Booking
   */

   {
    path: '/panel/admin/books/upload', //dashboard
    name: 'AdminUploadBook',
    meta: {
      logged_in: true,
      access: [
        'admin',
        'admin_booker'
      ],
      layout: 'admin-panel',
      layoutTitle: 'مدیریت کتاب',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        }, 
        {
          text: 'مدیریت کتاب ها',
          disabled: false,
          to: '/panel/admin/books/list',
        }, 
      ],
    },
    component: () => import('../views/Admin/Books/ManageBookView.vue')
  }, 
  
  // ----------------------------------------------------------------------
  
  /**
   * View a book
   */
   {
    path: '/web/:player_type/:id', //dashboard
    name: 'WebPDFReader',
    meta: {
      layout: 'reader',
      dir: 'ltr'
    },
    component: () => import('../views/WebPDFReader.vue')
  }, 
  
  // ----------------------------------------------------------------------
  
  /**
   * Admin modify books
   */
   {
    path: '/panel/admin/books/modify/:id', //dashboard
    name: 'AdminUploadBook',
    meta: {
      logged_in: true,
      access: [
        'admin',
        'admin_booker'
      ],
      layout: 'admin-panel',
      layoutTitle: 'مدیریت کتاب',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        }, 
        {
          text: 'مدیریت کتاب ها',
          disabled: false,
          to: '/panel/admin/books/list',
        }, 
      ],
    },
    component: () => import('../views/Admin/Books/ManageBookView.vue')
  }, 
  
  // ----------------------------------------------------------------------
  /**
   * Admin Booking
   */

  {
    path: '/panel/admin/books/collaborators/:type', //dashboard
    name: 'AdminBookscollaborators',
    meta: {
      logged_in: true,
      access: [
        'admin',
        'admin_booker'
      ],
      layout: 'admin-panel',
      layoutTitle: 'مدیریت افراد',
      breadcrumbs: [
        {
          text: 'مجموعه اهل سخن',
          disabled: false,
          to: '/panel/admin',
        }, 
        {
          text: 'لیست کتاب ها',
          disabled: false,
          to: '/panel/admin/books/manage',
        }, 
      ],
    },
    metaInfo: {
      title: 'دسته بندی کتاب خانه',
      titleTemplate: 'مدیریت |‌ %s',
      description: 'test', 
    },
    component: () => import('../views/Admin/Books/ManageCollaboratorsView.vue')
  }, 

  // ----------------------------------------------------------------------

  /**
   * Experts Routes
   */
  {
    path: '/panel/:expert/projects/view/:id',
    name: 'ExpertViewProject',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'بررسی پروژه',
      layout: 'main-panel',
    },
    component: () => import('../views/User/Projects/ProjectView.vue')
  },
  {
    path: '/panel/:expert/projects/list',
    name: 'ExpertOngoingProjects',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'پروژه های انجام شده',
      layout: 'main-panel',
    },
    component: () => import('../views/User/Projects/ArchivedProjectsView.vue')
  },
  {
    path: '/panel/:expert/income',
    name: 'ExpertFinishedProjects',
    meta: {
      logged_in: true,
      access: 'user',
      layoutTitle: 'سابقه درآمد',
      layout: 'main-panel',
    },
    component: () => import('../views/User/Projects/IncomeView.vue')
  },
  
  // ----------------------------------------------------------------------

  /**
   * Experts Dashboards
   */
  {
    path: '/panel/translator',
    name: 'TranslatorDashboard',
    meta: {
      logged_in: true,
      layoutTitle: 'داشبورد مترجم',
      access: 'translator',
      layout: 'main-panel',
      // layout: 'translator-panel',
    },
    component: () => import('../views/User/Translator/DashboardView.vue')
  },  
  // ----------------------------------------------------------------------

  {
    path: '/panel/writer',
    name: 'WriterDashboard',
    meta: {
      logged_in: true,
      access: 'writer',
      layoutTitle: 'داشبورد ویراستار',
      layout: 'main-panel',
      // layout: 'writer-panel',
    },
    component: () => import('../views/User/Writer/DashboardView.vue')
  }, 
  
  // ----------------------------------------------------------------------

  {
    path: '/panel/content_creator',
    name: 'ContentCreatorDashboard',
    meta: {
      logged_in: true,
      layoutTitle: 'داشبورد تولید محتوا',
      access: 'content_creator',
      layout: 'main-panel',
    },
    component: () => import('../views/User/ContentCreator/DashboardView.vue')
  }, 
  
  // ----------------------------------------------------------------------

  {
    path: '/panel/publisher',
    name: 'PublisherDashboard',
    meta: {
      logged_in: true,
      access: 'publisher',
      layoutTitle: 'داشبورد انتشارات',
      layout: 'main-panel',
    },
    component: () => import('../views/User/Publisher/DashboardView.vue')
  }, 
  
  // ----------------------------------------------------------------------

  {
    path: '/panel/publisher/books/upload',
    name: 'PublisherUploadBook',
    meta: {
      logged_in: true,
      access: 'publisher',
      layoutTitle: 'آپلود کتاب',
      layout: 'main-panel',
    },
    component: () => import('../views/User/Publisher/UploadBookView.vue')
  }, 

  // ----------------------------------------------------------------------

  {
    path: '/authentication/login',
    name: 'LoginPage',
    meta: {
      guest_only: true,
      title: 'ورود به سامانه',
      layout: 'authentication',
    },
    component: () => import('../views/Auth/LoginView.vue')
  },
  {
    path: '/authentication/logout',
    name: 'LogoutPage',
    meta: {
      title: 'ورود به سامانه',
      layout: 'authentication',
    },
    component: () => import('../views/Auth/LogoutView.vue')
  },
  {
    path: '/authentication/register',
    name: 'RegisterPage',
    meta: {
      layout: 'authentication',
    },
    component: () => import('../views/Auth/RegisterView.vue')
  },
  {
    path: '/authentication/register/:username',
    name: 'RegisterReferralPage',
    meta: {
      guest_only: true,
      layout: 'authentication',
    },
    component: () => import('../views/Auth/RegisterView.vue')
  },
  {
    path: '/authentication/recover',
    name: 'ForgotPage',
    meta: {
      guest_only: true,
      layout: 'authentication',
    },
    component: () => import('../views/Auth/ForgotView.vue')
  },
  {
    path: '/authentication/recover/via/:type',
    name: 'ForgotPageRequest',
    meta: {
      guest_only: true,
      layout: 'authentication',
    },
    component: () => import('../views/Auth/ForgotView.vue')
  },
  // {
  //   path: '/shop',
  //   name: 'ShopPage',
  //   meta: {
  //     layout: 'default',
  //   },
  //   component: () => import('../views/Shop/ShopIndex.vue')
  // },
  // {
  //   path: '/shop/explore/authors',
  //   name: 'AuthorsHome',
  //   meta: {
  //     layout: 'authors',
  //   },
  //   component: () => import('../views/Explore/Authors/AuthorsHome.vue')
  // },
  // {
  //   path: '/shop/explore/tags',
  //   name: 'ExploreShopTags',
  //   meta: {
  //     layout: 'shop',
  //   },
  //   component: () => import('../views/Shop/ShopIndex.vue')
  // },
  // {
  //   path: '/shop/explore/categories',
  //   name: 'ShopCategoriesView',
  //   meta: {
  //     layout: 'default',
  //   },
  //   component: () => import('../views/Shop/ShopIndex.vue')
  // },
  // {
  //   path: '/shop/explore/categories/:id',
  //   name: 'ShopCategoryView',
  //   meta: {
  //     layout: 'default',
  //   },
  //   component: () => import('../views/Shop/ViewCategory.vue')
  // },
  // {
  //   path: '/shop/books/:id',
  //   name: 'ShoppingBooks',
  //   meta: {
  //     layout: 'default',
  //   },
  //   component: () => import('../views/Shop/ViewBook.vue')
  // },

  // must be deleted:
  // {
  //   path: '/translation',
  //   name: 'TranslationPage',
  //   meta: {
  //     layout: 'translation',
  //   },
  //   component: () => import('../views/Translation/TranslationIndex.vue')
  // },
  // {
  //   path: '/translation/request/project',
  //   name: 'RequestProjectPage',
  //   meta: {
  //     layout: 'translation',
  //   },
  //   component: () => import('../views/Translation/Project/Request.vue')
  // }, 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page

  const authRequired = to.meta.logged_in;
  const loggedIn = JSON.parse(localStorage.getItem('user'));

  if (authRequired && !loggedIn) {
    return next('/authentication/login');
  }
  const guestOnly = to.matched.some(record => record.meta.guest_only);
  
  if(loggedIn && guestOnly)
  {
    return next('/panel/account');
  }

  if(loggedIn && (!loggedIn.active || !loggedIn.meta.phone_activated))
  {
    if(to.fullPath !== '/panel/account/profile' && to.fullPath !== '/authentication/logout')
      return next('/panel/account/profile');
  }

  if(loggedIn && to.fullPath.match('panel/*'))
  {
    var userAccess = [];
  
    Object.keys(loggedIn.access).forEach(function(key) {
      userAccess.push(loggedIn.access[key])
    });
    if(Array.isArray(to.meta.access)) {

      
      let granted = false

      to.meta.access.forEach((val) => {

        if(userAccess.includes(val)){
          granted = true
          next()
        }  
      })
        
      if(!granted)
      {
        // alert("شما دسترسی لازم جهت نمایش این صفحه را ندارید");
        next('/panel/account');
      }

    }

    else {

      if(!userAccess.includes(to.meta.access))
      {
        // alert("شما دسترسی لازم جهت نمایش این صفحه را ندارید");
        next('/panel/account');
      }
    }
  }

  next();
})