<template> 
	<v-navigation-drawer
		left
		fixed
		:width="['xs','sm'].includes($vuetify.breakpoint.name) ? '100%': '30%'"
		v-model="isOpen"
		overlay-opacity=".5"
		temporary
		touchless
		id="chat-container"
	>
    <v-system-bar color="deep-purple darken-3"></v-system-bar>

    <v-app-bar
      :color="'deep '+colors[activeChatRoom]+' accent-4'"
      dark
      prominent
			dense
			absolute
			elevate-on-scroll
			shrink-on-scroll
			extended
			fade-img-on-scroll
			scroll-target="#chat-container > .v-navigation-drawer__content"
			
    >
      <v-app-bar-nav-icon  @click.stop="$emit('close-chat')"></v-app-bar-nav-icon>

      <v-toolbar-title>گفت و گوهای من</v-toolbar-title>

      <v-spacer></v-spacer>
      <template v-slot:extension >
        <v-tabs align-with-title  center-active centered  fixed-tabs>
          <v-tab 
						@click="activeChatRoom = 'projects'"
						:color="colors['projects']+' accent-3'"
						depressed
						dark >
							<v-badge
								color="red"
								:value="chats.projects.count"
								:content="chats.projects.count"
							>
								پروژه ها
							</v-badge>
						</v-tab>
          <v-tab
						@click="activeChatRoom = 'support'"
						:color="colors['support']+' accent-3'"
						depressed
						dark>
						
						<v-badge
						
							color="red"
							:value="chats.support.count"
							:content="chats.support.count"
						>
							پشتیبانی
						</v-badge>
						

					</v-tab>
        </v-tabs> 
			</template>
    </v-app-bar>
		
		<div v-if="activeChatRoom == 'projects'">

			<v-sheet color="purple" dark rounded elevation="10" style="margin-top: 250px" class=" overflow-hidden pa-2 mr-2 ml-2 ">
					<div >asd</div>
				<b>{{account.user.firstName}} {{account.user.lastName}}</b>
				<v-subheader class="ma-0">لیست گفتگو های شخصی شما</v-subheader>
			</v-sheet>
			<v-list class="mb-10">
				<v-list-item
					v-for="n in 5"
					:key="n"
					link
				>
					<private-chat></private-chat>
				
				</v-list-item>
			</v-list>
		</div>
		
		<div v-if="activeChatRoom == 'support'">

			<v-sheet color="red" dark rounded elevation="10" style="margin-top: 250px" class=" overflow-hidden pa-2 mr-2 ml-2 ">
					<div >asd</div>
				<b>{{account.user.firstName}} {{account.user.lastName}}</b>
				<v-subheader class="ma-0">لیست گفتگو های شخصی شما</v-subheader>
			</v-sheet>
			
			<v-list class="mb-10">
				<v-list-item
					v-for="n in 5"
					:key="n"
					link
				>
					<private-chat></private-chat>
				
				</v-list-item>
			</v-list>
		</div>
	</v-navigation-drawer>

</template>



<script>
const chatResult = { 
	'support': {
		count: 0,
		people: [
			{
				id: 1,
				displayName: "کاربر تست",
				message: "سلام این یک پیام تست میباشد"
			}
		]
	},
	'projects': {
		count: 0,
		people: [
			{
				id: 1,
				displayName: "کاربر تست",
				message: "سلام این یک پیام تست میباشد"
			}
		]
	}
} 

export default ({
	props: {
		isopen: { type: Boolean },
		account: { type: Object },
	},
	name: 'TranslatorChatDrawer',
  components:{ 
		
  },
	created(){

	},
	data(instance) {

		return {
			socket: null,
			timerID : null,

			// Chat Drawer State
			isOpen: instance.isopen,

			// Active Chat Room (group) to display
			activeChatRoom: 'projects',

			// Chat Group Colors
			colors: {
				'support': 'primary',
				'projects': 'teal',
			},

			// Object of Chat Results
			chats: chatResult,

			// Object of Current User Status
			user: {
				status: 'online',
			},
		}
  },
	methods: {

		send() {
			this.socket.send("Hello");
		},
		keepAlive() { 
			var timeout = 20000;  
			if (this.socket.readyState == this.socket.OPEN) {  
				this.socket.send('');  
			}  
			this.timerID = setTimeout(this.keepAlive, timeout);  
		},
		cancelKeepAlive() {  
			if (this.timerID) {  
				clearTimeout(this.timerID);  
			}  
			this.socket = null
		},

	},
  watch: {
    isopen: function(newVal) { // watch it
      this.isOpen = newVal
    },
		isOpen: function(n)
		{
			document.querySelector('html').classList.toggle('application--drawer-opened', n);

			if(!n)
			this.$emit('close-chat')
		}
  }

})
</script>
