// Important data
import { authHeader, config, handleResponse } from '../helpers';

// ------------------------------------------------------------

/**
 * formService exportable constant
 */
export const UserPanelService = {
    getUserPanelData,
    saveProfile,

    getAvailableProjects,
    getIndex,
    getCategories,
    
    requestInvoices,
    getInvoice,
    requestInvoicePayment,
    
    buyWalletCredit,
    requestWalletTransactions,

    getOngoingProjects,
    
    closeRoom,
    deleteRoom,
    getChatDrawer,
    readChatRoom,
    markMessagesAsReady,

    searchCollaborators,
    searchBookCategories,

    fileUpload,
    getFiles,

    getAllBooks,
    publisherUploadBook,

    expertArchivedProjects,

    requestSupport,

    requestMyRefers,

};

// ------------------------------------------------------------

/**
 * Request Support Agent
 * 
 * @returns Promise
 */
function requestSupport(Request, signal){

    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(Request)
    };

    return fetch(`${config.apiUrl}/client/support/request`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get All Books
 * 
 * @returns Promise
 */
function getAllBooks(request, signal) {
    const requestOptions = {
        method: 'GET',
        signal,
        headers: authHeader()
    };
    var queryString = Object.keys(request).map(key => key + '=' + request[key]).join('&');

    return fetch(`${config.apiUrl}/website/books/display?${queryString}`, requestOptions).then(handleResponse);
}
// ------------------------------------------------------------

/**
 * Upload File
 * 
 * @returns Promise
 */
function publisherUploadBook(formData, signal) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            signal,
            'Accept': 'application/json',
        },
        body: formData
    };
    return fetch(`${config.apiUrl}/client/publisher/upload`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Upload File
 * 
 * @returns Promise
 */
function fileUpload(formData, signal) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            signal,
            'Accept': 'application/json',
        },
        body: formData
    };
    return fetch(`${config.apiUrl}/client/files/upload`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get Files
 * 
 * @returns Promise
 */

function getFiles(request, signal) {
    const requestOptions = {
        method: 'GET',
        signal,
        headers: authHeader()
    };
    var queryString = Object.keys(request).map(key => key + '=' + request[key]).join('&');

    return fetch(`${config.apiUrl}/client/files/get?${queryString}`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Save User Profile
 * 
 * @returns Promise
 */
function saveProfile(UserData, signal){

    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(UserData)
    };

    return fetch(`${config.apiUrl}/client/profile/save`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get Chat Room Conversations
 * 
 * @returns Promise
 */
function markMessagesAsReady(MessageIDs, signal){

    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'ids': MessageIDs
        })
    };

    return fetch(`${config.apiUrl}/client/sys/chat/markasread`, requestOptions).then(handleResponse);
}



// ------------------------------------------------------------

/**
 * Get Chat Room Conversations
 * 
 * @returns Promise
 */
function closeRoom(room, signal){


    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({room})

    };

    return fetch(`${config.apiUrl}/client/support/close`, requestOptions).then(handleResponse);
}


// ------------------------------------------------------------

/**
 * Get Chat Room Conversations
 * 
 * @returns Promise
 */
 function deleteRoom(room, signal){

    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({room, delete: true})

    };


    return fetch(`${config.apiUrl}/client/support/close`, requestOptions).then(handleResponse);
}


// ------------------------------------------------------------

/**
 * Get Chat Room Conversations
 * 
 * @returns Promise
 */
 function readChatRoom(filters, signal){

    const requestOptions = {
        method: 'GET',
        signal,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&'); 

    return fetch(`${config.apiUrl}/client/sys/chats?${queryString}`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get User Panel Data (Drawer And Header)
 * 
 * @returns Promise
 */
function getChatDrawer(signal){

    const requestOptions = {
        method: 'GET',
        signal,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/client/sys/chats`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get User Panel Data (Drawer And Header)
 * 
 * @returns Promise
 */
function getUserPanelData(){

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/client/sys/panel`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get Dashboard Panel Index Page 
 * 
 * @returns Promise
 */
function getIndex(section){

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/client/dashboard?section=${section}`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get Dashboard Panel Index Page 
 * 
 * @returns Promise
 */
function expertArchivedProjects(expert){

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/expert/projects/archived?section=${expert}`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get Array of Purchasable Forms
 * 
 * @returns Promise
 */
function getAvailableProjects(){

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/client/orders/index`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get a list of bought projects
 * 
 * @returns Promise
 */
function getOngoingProjects(filters){

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&'); 

    return fetch(`${config.apiUrl}/client/orders/ongoing?${queryString}`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Request invoice list
 * 
 * @returns Promise
 */
function requestInvoices(filters, signal){

    const requestOptions = {
        signal,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&'); 

    return fetch(`${config.apiUrl}/client/invoices?${queryString}`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Request invoice ID
 * 
 * @returns Promise
 */
function getInvoice(ID, signal){

    const requestOptions = {
        signal,
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        },
    };


    return fetch(`${config.apiUrl}/client/invoices/get?id=${ID}`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Request invoice ID
 * 
 * @returns Promise
 */
function requestInvoicePayment(invoice, signal){

    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({invoice})

    };


    return fetch(`${config.apiUrl}/client/invoices/pay`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Request to purchase wallet credit
 * 
 * @returns Promise
 */
function buyWalletCredit(amount, signal){

    const requestOptions = {
        signal,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({amount})
    };

    return fetch(`${config.apiUrl}/client/wallet/buy`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Search Collaborators
 * 
 * @returns Promise
 */
function searchCollaborators(filters, signal){

    const requestOptions = {
        signal,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&'); 

    return fetch(`${config.apiUrl}/website/books/collaborators/search?${queryString}`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Search Book Categories
 * 
 * @returns Promise
 */
function searchBookCategories(filters, signal){

    const requestOptions = {
        signal,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&'); 

    return fetch(`${config.apiUrl}/website/books/categories/search?${queryString}`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Request invoice list
 * 
 * @returns Promise
 */
function requestWalletTransactions(filters, signal){

    const requestOptions = {
        signal,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&'); 

    return fetch(`${config.apiUrl}/client/wallet/transactions?${queryString}`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Request Referred list
 * 
 * @returns Promise
 */
function requestMyRefers(signal){

    const requestOptions = {
        signal,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };


    return fetch(`${config.apiUrl}/client/reffered`, requestOptions).then(handleResponse);
}

// ------------------------------------------------------------

/**
 * Get Form By Section
 * 
 * @returns Promise
 */
function getCategories(){

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/admin/custom_forms/categories`, requestOptions).then(handleResponse);
} 