<template>
	

	<div
		id="admindrawer"
		class="blue lighten-3" 
		style="height: 98vh;overflow-y: scroll"
	>

		<v-sheet
			color="grey lighten-5"
			width="100%"

		>
      <v-card
				:loading="AdminPanel.drawer.loading"
				:disabled="AdminPanel.drawer.loading"
				dark
				flat
				style="border-radius: unset !important"
				class="blue darken-4 unselectable"
				width="400"
			>
				<template slot="progress">
					<v-progress-linear height="250px" absolute color="blue" indeterminate></v-progress-linear>
				</template>

				<v-card-subtitle class="white--text ">
					<div class="text-h7 mb-3" > 
						پنل مدیریت	 
					</div>
					<v-avatar size="36" right>
						<img
							alt="user"
							:src="account.user.avatar"
						>
					</v-avatar>
					<span class="mr-3" > 
						{{account.user.fullname}}
					</span>
					<!-- <v-divider class="mt-2"></v-divider> -->
				</v-card-subtitle>
				<!-- <v-card-text class="caption"> 

				</v-card-text> -->
			</v-card>
		</v-sheet>
		<v-list
			color="blue accent-1"
			nav
			dense
			no-action
			v-if="account.user.access.includes('admin') ||
				account.user.access.includes('admin_publisher') ||
				account.user.access.includes('admin_content_creator') ||
				account.user.access.includes('admin_editor') ||
				account.user.access.includes('admin_translator')
			"
		>
			
			<div v-for="(link, i) in SystemMenu" :key="i">

				<v-list-item
					v-if="!link.subLinks"
					:to="link.to"
					exact
					class="v-list-item mb-2"
				>
					<v-list-item-icon class="ml-2">
						<v-icon>{{ link.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-title v-text="link.text" />
				</v-list-item>
				<v-list-group
					to=""
					v-else
					:key="link.text"
					:prepend-icon="link.icon"
					:value="subIsActive(link.group)"
					no-action
					@click="keepDrawerOpen()"

				>
					<template v-slot:activator>
						<v-list-item-title>{{ link.text }}</v-list-item-title>
					</template>
		
					<v-list-item
						v-for="sublink in link.subLinks"
						exact
						:to="sublink.to"
						class="pr-7"
						:key="sublink.text"
					>
							<v-list-item-icon class="ml-1">
								<v-icon>{{ sublink.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-title>{{ sublink.text }} </v-list-item-title>
					</v-list-item>
				</v-list-group>
			</div>
		</v-list>

		<v-list
			color="orange accent-1"
			
			nav
			tile
			dense
			no-action
			v-if="account.user.access.includes('admin')"
		>
			
			<div v-for="(link, i) in BookingMenu" :key="i">

				<v-list-item
					v-if="!link.subLinks"
					:to="link.to"
					:active-class="color"
					avatar
					class="v-list-item mb-2"
				>
					<v-list-item-icon class="ml-2">
						<v-icon>{{ link.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-title v-text="link.text" />
				</v-list-item>
				<v-list-group
					to=""
					v-else
					:key="link.text"
					:prepend-icon="link.icon"
					:value="subIsActive(link.group)"
					no-action
					@click="keepDrawerOpen()"

				>
					<template v-slot:activator>
						<v-list-item-title>{{ link.text }}</v-list-item-title>
					</template>
		
					<v-list-item
						v-for="sublink in link.subLinks"
						:to="sublink.to"
						exact
						class="pr-7"
						:key="sublink.text"
					>
							<v-list-item-icon class="ml-1">
								<v-icon>{{ sublink.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-title>{{ sublink.text }} </v-list-item-title>
					</v-list-item>
				</v-list-group>
			</div>
		</v-list>
		<v-list
			color="teal accent-1"
			tile
			nav
			dense
			no-action
		>
			
			<div v-for="(link, i) in ProjectsMenu" :key="i">

				<v-list-item
					v-if="!link.subLinks"
					:to="link.to"
					exact
					:active-class="color"
					avatar
					class="v-list-item mb-2"
				>
					<v-list-item-icon class="ml-2">
						<v-icon>{{ link.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-title v-text="link.text" />
				</v-list-item>
				<v-list-group
					to=""
					v-else-if="link.access ? account.user.access.includes(link.access) : true"
					:key="link.text"
					:prepend-icon="link.icon"
					:value="subIsActive(link.group)"
					no-action
					@click="keepDrawerOpen()"

				>
					<template v-slot:activator>
						<v-list-item-title>{{ link.text }}</v-list-item-title>
					</template>
		
					<v-list-item
						v-for="sublink in link.subLinks"
						:to="sublink.to"
						exact
						class="pr-7"
						:key="sublink.text"
					>
							<v-list-item-icon class="ml-1">
								<v-icon>{{ sublink.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-title>{{ sublink.text }} </v-list-item-title>
					</v-list-item>
				</v-list-group>
			</div>
		</v-list>
		<v-list
			color="teal accent-3"
			nav
			tile
			dense no-action rounded>
			<v-list-item>
				<v-list-item-title>
					سفارش ها
				</v-list-item-title>
			</v-list-item>
			<v-list-group
				no-action
				@click="keepDrawerOpen()"

				v-for="(dItems, index) in drawerItems"
				:key="index" 
				:value="subIsActive('/panel/admin/client/request/'+encodeURIComponent(index))"

			>
				<template v-slot:activator>
					<v-list-item-action class="ma-0 ml-3">
						<v-badge v-if="dItems.badge"
							overlap
							bordered
							:offset-x="-5"
							:offset-y="10"
							:content="dItems.badge"></v-badge>
						<v-badge v-else color="grey"
							overlap
							bordered
							:offset-x="-5"
							:offset-y="10"
							:content="0"></v-badge>
					</v-list-item-action>
					<v-list-item-title>{{index}}</v-list-item-title>
				</template>
	
				<v-list-item
					v-for="(CustomForm, i) in dItems.items"
					:key="i" 
					class="pr-4"
					:to="'/panel/admin/client/request/'+encodeURIComponent(index)+'/'+CustomForm.id"
				> 
					<v-list-item-action class="ma-0 ml-3">
						<v-badge v-if="CustomForm.badge" inline overlap bordered :content="CustomForm.badge"></v-badge>
						<v-badge v-else color="grey" inline overlap bordered :content="0"></v-badge>
					</v-list-item-action>
					<v-list-item-title>
						{{ CustomForm.name }}
					</v-list-item-title>
				</v-list-item>
			</v-list-group> 

		</v-list>
	</div>
</template>
<style>
v-list-item__icon
body #admindrawer .v-application--is-rtl .v-list-item__action:first-child, .v-application--is-rtl .v-list-item__icon:first-child
{
	margin-left: 10px !important;
}
</style>

<script>
const SystemMenu = [
	{
		to     : '/panel/admin',
		icon   : 'mdi-view-dashboard-outline',
		text   : 'داشبورد',
	},
	{
		icon     : 'mdi-badge-account',
		text     : 'مدیریت کاربران',
		group 	 : '/panel/admin/users',
		subLinks : [
			{
				text 	: 'درج کاربر جدید',
				to    : '/panel/admin/users/new',
				group : 'users',
				icon  : 'mdi-account-plus-outline'
			},
			{
				text 	: 'نمایش کاربران',
				to    : '/panel/admin/users/list',
				group : 'users',
				icon  : 'mdi-account-multiple'
			},
			{
				text 	: 'گروه های کاربری',
				to    : '/panel/admin/users/groups',
				group : 'users',
				icon  : 'mdi-account-group'
			},
		]
	},
	{
		icon     : 'mdi-cart',
		text     : 'فاکتور ها',
		group 	 : '/panel/admin/invoices',
		subLinks : [
			{
				text 	: 'مدیریت فاکتور ها',
				to    : '/panel/admin/invoices/list',
				icon  : 'mdi-view-list'
			},
		]
	},
	{
		icon     : 'mdi-cogs',
		text     : 'تنظیمات',
		group 	 : '/panel/admin/settings',
		subLinks : [
			{
				text : 'تنظیمات اصلی',
				to    : '/panel/admin/settings',
				icon  : 'mdi-web'
			},
			{
				text : 'ایمیل ها',
				to    : '/panel/admin/settings/emails',
				icon  : 'mdi-email'
			},
		]
	},
]
const BookingMenu = [

	{
		icon     : 'mdi-bookshelf',
		text     : 'کتابخانه',
		group 	 : '/panel/admin/books',
		subLinks : [
			{
				text : 'درج کتاب جدید',
				to    : '/panel/admin/books/upload',
				icon  : 'mdi-plus'
			},
			{
				text : 'لیست',
				to    : '/panel/admin/books/list',
				icon  : 'mdi-view-list'
			},
			{
				text : 'دسته بندی',
				to    : '/panel/admin/books/categories',
				icon  : 'mdi-book-multiple'
			},
			{
				text : 'لیست نویسندگان',
				to    : '/panel/admin/books/collaborators/author',
				icon  : 'mdi-account-group'
			},
			{
				text : 'لیست انتشارات',
				to    : '/panel/admin/books/collaborators/publisher',
				icon  : 'mdi-account-group'
			},
		]
	},
]

const ProjectsMenu = [
	{
		icon     : 'mdi-folder-star-multiple',
		text     : 'پروژه ها',
		to    	 : '/panel/admin/projects/list',
		group 	 : '/panel/admin/projects',
		// subLinks : [
		// 	// {
		// 	// 	text 	: 'پروژه جدید',
		// 	// 	to    : '/panel/admin/projects/list',
		// 	// 	icon  : 'mdi-folder-multiple-plus'
		// 	// },
		// 	{
		// 		text 	: 'مدیریت پروژه ها',
		// 		to    : '/panel/admin/projects/list',
		// 		icon  : 'mdi-folder-multiple'
		// 	},
		// ]
	},
	{
		icon     : 'mdi-format-columns',
		text     : 'فرم های سفراش',
		group 	 : '/panel/admin/forms',
		access   : 'admin',
		subLinks : [
			{
				text 	: 'طراحی فرم جدید',
				to    : '/panel/admin/forms/design',
				icon  : 'mdi-plus'
			},
			{
				text 	: 'لیست فرم ها',
				to    : '/panel/admin/forms/list',
				icon  : 'mdi-view-list'
			},
			{
				text 	: 'دسته بندی فرم ها',
				to    : '/panel/admin/forms/categories',
				icon  : 'mdi-view-list'
			},
		]
	},
]

import { mapState, mapActions } from 'vuex'

export default ({
	props: {
		color: { type: String },
		panel: { type: Boolean },
		account: { type: Object },
	},
	name: 'AdminMenuDrawer',
  components:{
		
  },
	created(){
		this.requestPanelData();
	},
  computed: {
		drawerItems: function (){
			console.log( this.AdminPanel.drawer.items)
			return this.AdminPanel.drawer.items
			// return this.AdminPanel.drawer.items.filter((i) => {
			// 	return this.account.user.access.some((r)=> {

			// 		return i.admin == r || r == 'admin' // Admin can access all other admins permissions
			// 	})
			// })
 
		},
    ...mapState({
      AdminPanel: state => state.AdminPanel,
    }),
  },
	data(instance) {
    return {
			SystemMenu,
			BookingMenu,
			ProjectsMenu,
			panelMenuDrawer: instance.panel,
			drawer: true
		}
  },
	methods: {
		keepDrawerOpen(){
			// this.panelMenuDrawer = true
			return false
		},
    toggle(v){
			v
		},
		subIsActive(input) {
			const paths = Array.isArray(input) ? input : [input]
			return paths.some(path => {
				return this.$route.path.indexOf((path)) === 0 // current path starts with this path string
			})
		},
    ...mapActions('AdminPanel', {
      requestPanelData: 'requestPanelData'
    })
	},
  watch: {
    panel: function(newVal) { // watch it
      this.panelMenuDrawer = newVal
    },
  }


})
</script>
