<template>
  <v-app id="inspire">
    <v-system-bar color="teal lighten-5" class="unselectable" window app>

      <v-tooltip
        min-width="155px"
        left
        
        nudge-bottom="0"
        nudge-left="60"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            v-bind="attrs"
            v-on="on"
            icon
            small
            to="/authentication/logout">
            <v-badge
              color="orange"
              
              :content="expire.minutes +':'+expire.seconds"
              bordered
              right
              inline
              style="position: absolute; left:-50px;"
            >
              <v-icon size="15px" style="margin-left: -2px">mdi-logout-variant</v-icon>
            </v-badge>



          </v-btn>  
        </template>
        <span>خروج از نشست فعلی</span>
      </v-tooltip>

      
      <v-spacer></v-spacer>
      <v-chip  x-small outlined>
        {{date.toString('jdddd jDo jMMMM')}}
      </v-chip>
      <v-spacer></v-spacer>

    </v-system-bar>

    <v-app-bar
      app
      text
      height="72"
      class="pr-5"
      color="teal darken-3"
      dark
    >
      <v-btn icon class=" ml-2" @click="updatePanelDrawer (!panelMenuDrawer)">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <template v-if="UserPanel.drawer.header">


        <v-btn
          icon
          @click="chatDrawer = !chatDrawer">
            <v-badge
              color="red"
              :value="(chats.private.count + chats.support.count + chats.projects.count)"
              :content="(chats.private.count + chats.support.count + chats.projects.count)"
            >
              <v-icon size="18px" class="ml-1">mdi-chat</v-icon>
            </v-badge>
        </v-btn>
        
        <v-btn outlined to="/panel/account/wallet" class=" mr-2 pa-1">
          <v-icon size="20px" class="ml-1">mdi-wallet</v-icon>
          {{Intl.NumberFormat().format(UserPanel.drawer.header.wallet)}} تومان 
          
        </v-btn>
      </template>
      <template v-else>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
      </template>
    </v-app-bar>

    <panel-selector-drawer :account="account" v-on:handle-drawer-change="updatePanelDrawer"  :panel="panelMenuDrawer" />

    <translator-chat-drawer v-on:close-chat="chatDrawer = false" :account="account" :isopen="chatDrawer" />
    
    <v-main class="teal lighten-4">
      
      <slot />
    </v-main>

    <v-footer
      color="red"
      fixed
      height="25"
      inset
    >
      <!-- 

      <v-text-field
        background-color="grey lighten-1"
        dense
        text
        hide-details
        rounded
        solo
      ></v-text-field>
      -->{{this.$vuetify.breakpoint.name}}
      {{account.updateRequired}}
    </v-footer>
  </v-app>
</template>

<script>
const chatResult = {
	'private': {
		count: 1,
		people: [
			{
				id: 1,
				displayName: "کاربر تست",
				message: "سلام این یک پیام تست میباشد"
			}
		]
	},
	'support': {
		count: 0,
		people: [
			{
				id: 1,
				displayName: "کاربر تست",
				message: "سلام این یک پیام تست میباشد"
			}
		]
	},
	'projects': {
		count: 0,
		people: [
			{
				id: 1,
				displayName: "کاربر تست",
				message: "سلام این یک پیام تست میباشد"
			}
		]
	}
}

import PanelSelectorDrawer from '@/components/Panels/General/PanelSelectorDrawer.vue';
import { mapState, mapActions } from 'vuex'
import TranslatorChatDrawer from '@/components/Panels/General/TranslatorChatDrawer.vue';
import {PersianDate} from '@alireza-ab/vue-persian-datepicker'
export default {

  name: "MainPanel",
  components: {
    PanelSelectorDrawer,
    TranslatorChatDrawer
  },
  data () {
    return {
      timer: null,
      chats: chatResult,
      date: new PersianDate(),
      chatDrawer: ['md','lg','xl'].includes(this.$vuetify.breakpoint.name) ? false : false,
      panelMenuDrawer: ['md','lg','xl'].includes(this.$vuetify.breakpoint.name) ? true : false,
      drawer: false,
      PanelSelectorDrawer: false,
      expire: this.getTimeRemaining()
      
    }
  },

  computed: {
    ...mapState({
      UserPanel: state => state.UserPanel,
      account: state => state.account,
      users: state => state.users.all
    }),
  },
  created () {

    this.timer = setInterval(()=>{
      this.expire = this.getTimeRemaining()
    }, 1000);
 
  },
  methods: {
    toggle(v){
      this.$emit('handle-drawer-change', v)
    },
    updatePanelDrawer(drawerStatus) {
      this.panelMenuDrawer = drawerStatus
    },
    getTimeRemaining() {
      const total = new Date(localStorage.getItem("expire") * 1000) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      
      return {
        total,
        days,
        hours,
        minutes,
        seconds
      };
    },
    ...mapActions('users', {
      getAllUsers: 'getAll',
      deleteUser: 'delete'
    })
  },
  watch: {
  }
}; 
</script>
