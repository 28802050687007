// Important data
import { authHeader, config, handleResponse } from '../helpers';

// ------------------------------------------------------------

/**
 * formService exportable constant
 */
export const WebsiteService = {
    getPage,
    loadShelf,
    requestSearch,
};

// ------------------------------------------------------------

/**
 * Request Home Page Data
 * 
 * @returns Promise
 */
function loadShelf(request, signal){

    const requestOptions = {
        method: 'GET',
        signal,
        headers: authHeader()
    };
    var queryString = Object.keys(request).map(key => key + '=' + request[key]).join('&');

    return fetch(`${config.apiUrl}/website/books/shelfs?${queryString}`, requestOptions).then(handleResponse);    
}

// ------------------------------------------------------------

/**
 * Request Home Page Data
 * 
 * @returns Promise
 */
function requestSearch(request, signal){

    const requestOptions = {
        method: 'GET',
        signal,
        headers: authHeader()
    };
    var queryString = Object.keys(request).map(key => key + '=' + request[key]).join('&');

    return fetch(`${config.apiUrl}/website/books/search?${queryString}`, requestOptions).then(handleResponse);    
}

// ------------------------------------------------------------

/**
 * Request Home Page Data
 * 
 * @returns Promise
 */
function getPage(page, signal){

    const requestOptions = {
        method: 'GET',
        signal,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/website/pages/request?page=${page}`, requestOptions).then(handleResponse);
}