<template>
  <div>
    <SplashScreen :isLoading="isLoading" />
		<loader-view message="درحال تایید نشست کاربری" color="red darken-4" persistent :isOpen="account.refreshing"></loader-view>

    <system-upgrade v-if="newReleaseAvailable && !isLoading" color="blue"></system-upgrade>
    <v-slide-y-transition v-if="!isLoading" mode="out-in">
      <component :is="layout">
        <v-progress-linear
          style="top: 135px"
          v-if="website.status == 'loading'"
          indeterminate
          fixed
          height="10"
          stream
          color="blue"
          class="mb-0"
        ></v-progress-linear>

        <router-view/>
      </component>
    </v-slide-y-transition>
    <v-footer
      style="z-index: 9999"
      v-if="!connected"
      fixed
      dark
      :style="'background-color: #780303; justify-content: center;;'"
    >
    {{connected ? "CONNECTED" : "لطفا از برقراری اینترنت اطمینان حاصل فرمایید"}}

    </v-footer>
  </div>
  
</template>
<script>
import SplashScreen from './components/SplashScreen.vue';
import SystemUpgrade from './components/SystemUpgrade.vue';
import { mapState, mapActions } from 'vuex'
import LoaderView from "@/components/Help/LoaderView.vue"
import { config } from '@/helpers';

const default_layout = 'default'
export default {
  components: {
    SystemUpgrade,
    SplashScreen,
    LoaderView,
  },
  name: 'App',
 
    inject: {
      theme: {
        default: { isDark: true },
      },
    },


  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: ' مجموعه اهل سخن',
    // all titles will be injected into this template
    titleTemplate: '%s | صفحه اصلی',
    description: 'سامانه اهل سخن',
  }, 
  destroyed() {
    window.removeEventListener('offline', this.onOffline)
    window.removeEventListener('online', this.onOnline)
  },
  created(){
    // internet connection status events
    window.addEventListener('offline', this.onOffline)
    window.addEventListener('online', this.onOnline)
    // var diff = Math.abs(new Date('2011/10/09 12:00') - new Date('2011/10/09 00:00'));
    // validation will be based on bearer token
    
    if(this.account.user && this.account.user.id && this.account.remembered.token) { 
      var diff = (new Date(localStorage.getItem("expire") * 1000) - Date.parse(new Date())) / 1000;
      
      
      if(diff < 2700) {
        this.accountRefresh({uid: this.account.user.id, token: this.account.remembered.token})
      } 
    }

    // Website UI Version 
    config.version
    // Get Currently Running Version
    let v = localStorage.getItem('ak_version')

    if(!v) {
      localStorage.setItem("ak_version", config.version)
    }
    else{
      if(config.version > v) {
        this.newReleaseAvailable = true
      }
    } 
    let dir = localStorage.getItem('direction')
    if(dir === null) {
      dir = 'rtl'
      localStorage.setItem("direction", dir)

    }
    // alert(JSON.stringify(this.$route))
    var wasRTL = this.$vuetify.rtl 
    this.$vuetify.rtl = dir == 'rtl'
    if(wasRTL) {
      window.location.reload()
    }
    if(this.isOnline)
    {

      const timer = setInterval(() => {
        alert(';asd')
      }, 5000);

      this.$once("hook:beforeDestroy", () => {
        clearInterval(timer);
      });
    }
  },
	mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);

	},
  beforeDestroy () {
    clearInterval(this.interval)
  },
  computed: {
    ...mapState({
      website: state => state.website,
      account: state => state.account,
      users: state => state.users.all
    }),
    ...mapState({
      alert: state => state.alert
    }),

    /**
     * Which Layout to display
     */
    layout(){
      return (this.$route.meta.layout || default_layout) + '-layout'
    }
  },
  methods: {

    onOffline () { this.connected = false },
    onOnline () { this.connected = true },
    ...mapActions('users', {
      getAllUsers: 'getAll',
      deleteUser: 'delete'
    }),
    ...mapActions({
      accountRefresh: 'account/refresh',
      setDirection: 'website/setDirection',
      clearAlert: 'alert/clear',
    })
  },
  watch: { 
    $route (to, from){
      to, from;
      // clear alert on location change
      this.clearAlert();
    },
    'navigator.onLine'(val) {
      this.connected = val
    }
  },
  data: () => ({
    connected: true,
    refresherLoader: false,
    isLoading: true, 
    newReleaseAvailable: false,
  }),
};
</script>

<style>
  @import './assets/base.css';
</style>
