import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import fa from 'vuetify/src/locale/fa.ts'  
Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    
    lang: {
        locales: {fa},
        current: 'fa',
    },
});
