import { UserPanelService } from '@/services';

const state = {
	drawer: {
		loading: false,
		open: false,
		user_status: null,

		timer: null,
		RequestController: new AbortController(), // Fetch API Request

		// Base Structure of Rooms
		rooms: {
			projects: {
				badge: 0,
				conversations: [] // array of conversations
			},
			support: {
				badge: 0,
				conversations: [] // array of conversations
			},
			archived: {
				projects: {
					badge: 0,
					conversations: [] // array of conversations
				},
				support: {
					badge: 0,
					conversations: [] // array of conversations
				},
			}
		},
		currentRoom: {
			status: false, 
			loading: true,
			information: {},
			participants: {},
			messages: {},
			total_pages: 0,
			current_page: 1,
		}
	},
	


	status: 'loading',
	result: null,
	action: null,

	RequestController: new AbortController(), // Fetch API Request
};


const actions = {
	loadDrawerData({ commit }) {
		commit("loadingDrawer")
		state.drawer.RequestController.abort()
		state.drawer.RequestController = new AbortController()
		
		UserPanelService.getChatDrawer(state.RequestController.signal).then(
			result => commit('drawerLoaded', result.data),
			error => commit('drawerFailed', error)
		)
	},
	loadMoreMessages({ commit }, {roomID, page}) {

		state.RequestController.abort()
		state.RequestController = new AbortController()
		UserPanelService.readChatRoom({
			room: roomID,
			page
		}, state.RequestController.signal).then(
			result => commit('loadedMoreMessages', result.data),
			error => commit('error', error)
		)
	},
	enterRoom({ commit }, roomID) {
		commit('loadingRoom', roomID)
		UserPanelService.readChatRoom({
			room: roomID
		}).then(
			result => commit('enteredChatRoom', result.data),
			error => commit('failedToEnterChatRoom', error)
		)
	},
	closeRoom({ commit }, roomID) {
		UserPanelService.closeRoom(roomID).then(
			result => commit('roomClosed', result.data),
			error => commit('failedToEnterChatRoom', error)
		)
	},
	deleteRoom({ commit }, roomID) {
		UserPanelService.deleteRoom(roomID).then(
			result => commit('roomDeleted', result.data),
			error => commit('failedToEnterChatRoom', error)
		)
	},
	loadConversations({ commit }, roomID) {

		UserPanelService.readChatRoom({
			room: roomID
		}).then(
			result => commit('conversationLoaded', result.data),
			error => commit('error', error)
		)
	},
	messageRecieved({ commit }, message) {
		
		commit('addNewMessage', message)
	},
	markAsRead({ commit }, MessageIDs) {
		UserPanelService.markMessagesAsReady(MessageIDs).then(
			result => commit('markedAsRead', result.data),
			error => commit('error', error)
		)
	},
	markAsReadLocal({ commit }, MessageID) {
		commit('markMessageAsRead', MessageID)
	},
	requestPanelData({ commit }) {
		commit('PanelDataRequested', 'requestPanelData');

		UserPanelService.getUserPanelData().then(
			result => commit('PanelDataDone', result.data),
			error => commit('PanelDataFailed', error)
		)

	},

	/**
	 * Request Client Dashboard data
	 * @param {*} commit 
	 * @param {*} section 
	 */
	getRoom({ commit }, section) {
		// commit('RequestStarted', 'requestDashboard');

		UserPanelService.getIndex(section).then(
			form => commit('RequestDone', form),
			error => commit('error', error)
		)
	},  
};

const mutations = {
	RequestStarted(state, action) {
		
		state.result = null
		state.action = action
		state.status  = 'loading'

		
		if(state.status == 'loading')
		{
			state.RequestController.abort()
			state.RequestController = new AbortController()
		}
	},
	RequestDone(state, data) {
		state.result = data.data ? data.data : ( data.message ? data.message : data.status) 
		state.status  = 'done'
	},

	error(state, data) {
		if(data == 'AbortError: The user aborted a request.')
			return true; // cool
		state.result = data.data ? data.data : ( data.message ? data.message : data.status) 
		state.status  = data.status
		

		// if(!data.OK && data.errors) {
		// 	state.alert.type = 'error'
		// 	state.alert.title = 'خطا'
		// 	Object.keys(data.errors).forEach((errorKey) => {
		// 		state.alert.message = (state.alert.message ? state.alert.message : '') + "<p>"+data.errors[errorKey]+"</p>"
		// 	})
		// }
	},

	/**
	 * Chat Drawer is loading
	 * @param {*} state 
	 */
	loadingDrawer(state) {
		state.drawer.loading = true
		if(state.drawer.timer) clearInterval(state.drawer.timer)

		state.drawer.timer = setInterval(() => {
			state.drawer.loading = true
	
			this.dispatch('ChatPanel/loadDrawerData', { });
		}, 15000)
		
	},

	/**
	 * Panel Data Request has been made
	 * @param {*} state 
	 */
	drawerLoaded(state, data) {
		state.drawer.loading = false
		if(data.projects) state.drawer.rooms.projects = data.projects
		if(data.support) state.drawer.rooms.support = data.support
		if(data.archived) state.drawer.rooms.archived = data.archived
	},

	/**
	 * Panel Data Request has been made
	 * @param {*} state 
	 */
	drawerFailed(state) {
		state.drawer.loading = false
	},

	/**
	 * Chat Room Opened Successfuly
	 * @param {*} state 
	 */
	loadingRoom(state, roomID) {
		roomID
		state.drawer.loading = true
		state.drawer.currentRoom = {
			status: 'loading',
			loading: true,
			information: [],
			participants: [],
			messages: [],
			total_pages: 0,
			current_page: 1,
		}
	},
	/**
	 * Chat Room Opened Successfuly
	 * @param {*} state 
	 */
	roomClosed(state, result) {
		result
		state.drawer.loading = false
		state.drawer.currentRoom = {
			status: 'roomClosed',
			loading: false,
			information: null,
			participants: null,
			messages: null,
		}
		this.dispatch('ChatPanel/loadDrawerData', {   });

	},
	/**
	 * Chat Room Opened Successfuly
	 * @param {*} state 
	 */
	roomDeleted(state, result) {
		result
		state.drawer.loading = false
		state.drawer.currentRoom = {
			status: 'roomDeleted',
			loading: false,
			information: null,
			participants: null,
			messages: null,
		}
		this.dispatch('ChatPanel/loadDrawerData', {   });
	},
	/**
	 * Chat Room Opened Successfuly
	 * @param {*} state 
	 */
	enteredChatRoom(state, result) {
		state.drawer.loading = false
		state.drawer.currentRoom = {
			loading: false,
			information: result.information,
			participants: result.participants,
			messages: result.messages,
			total_pages: result.totalPages,
			current_page: result.current_page,
		}
	},
	
	/**
	 * Failed to Open ChatRoom
	 * @param {*} state 
	 */
	failedToEnterChatRoom(state) {
		state.drawer.loading = false
		state.drawer.currentRoom = {
			loading: false,
			information: null,
			participants: null,
			messages: null,
		}
	},
	/**
	 * Used to update messages for current opened room
	 * @param {*} state 
	 */
	loadedMoreMessages(state, result) {
		state.drawer.loading = false
		state.drawer.loading = false
		state.drawer.currentRoom = {
			information: result.information,
			participants: result.participants,
			messages: [...result.messages, ...state.drawer.currentRoom.messages],
			total_pages: result.totalPages,
			current_page: result.current_page,
		}
	},
	/**
	 * Used to update messages for current opened room
	 * @param {*} state 
	 */
	conversationLoaded(state, result) {
		state.drawer.loading = false
		state.drawer.currentRoom = {
			information: result.information,
			participants: result.participants,
			messages: result.messages,
		}
	},

	/**
	 * Add New Message to current room
	 * @param {*} state 
	 */
	addNewMessage(state, message) {
		state.drawer.loading = false
		state.drawer.currentRoom.messages.push(message)
	},
	/**
	 * Mark a message as read ( through local )
	 * @param {*} state 
	 */
	markMessageAsRead(state, msgid) {
		if(state.drawer.currentRoom.messages) {
			
			Object.keys(state.drawer.currentRoom.messages).forEach((key) => {
				if(state.drawer.currentRoom.messages[key].id == msgid) {
					state.drawer.currentRoom.messages[key].read = 1
				}
			})
		}
	},
	/**
	 * Messages has been marked as read
	 * @param {*} state 
	 */
	markedAsRead(state, result) {
		state, result
	},
	
	/**
	 * Panel Data Request has been made
	 * @param {*} state 
	 */
	PanelDataRequested(state) {
		state.drawer.loading = true
		state.drawer.header = null
	},

	/**
	 * Panel Data Request has finished with data
	 * @param {*} state 
	 * @param {*} data 
	 */
	PanelDataDone(state, data) {
		state.drawer.loading = false

		if(data.header) {
			state.drawer.header = data.header
		}
		if(data.items) {
			state.drawer.items = data.items
		}

	},

	/**
	 * Failed to get panel data
	 * @param {*} state 
	 * @param {*} data 
	 */
	PanelDataFailed(state, data) {

		if(data == 'AbortError: The user aborted a request.')
			return true; // cool

		state.drawer.loading = false
		
		state.drawer.header = null 
	},

	clear(state) {
		state.result = null
		state.type = null;
		state.status = null;
 
	}
};

export const ChatPanel = {
	namespaced: true,
	state,
	actions,
	mutations
};