<template>
	
	<div

		class="green lighten-3" 
		style="height: 100vh;overflow-y: scroll"
	>

		<v-sheet
			color="grey lighten-5"
			width="100%"
		>
      <v-card
				
				:loading="UserPanel.drawer.loading"
				:disabled="UserPanel.drawer.loading"
				dark flat style="border-radius: unset !important" class="green darken-3 unselectable" width="400">
				<template slot="progress">
					<v-progress-linear height="250px" absolute color="green" indeterminate></v-progress-linear>
				</template>

				<v-card-subtitle class="white--text ">
					<div class="text-h7 mb-3" > 
						پنل کاربری	 
					</div>
					<v-avatar size="36" right>
						<img
							alt="user"
							:src="account.user.avatar"
						>
					</v-avatar>
					<span class="mr-3" > 
						{{account.user.fullname}}
					</span>
					<v-divider class="mt-2"></v-divider>
				</v-card-subtitle>
				<v-card-text class="caption"> 
					<div class="pa-1" v-if="UserPanel.drawer.items.my_ip">
						آی پی شما


						<v-chip
							class=" white--text"
							color="green accent-3"
							outlined
							style="float: left; top: -4px;"
							small
						>
							{{UserPanel.drawer.items.my_ip}}
						</v-chip>
					</div>
				</v-card-text>
			</v-card>
		</v-sheet>

		<v-list 
			nav
			dense
			no-action
		>
			
			<div v-for="(link, i) in menu" :key="i">

				<v-list-item
					v-if="!link.subLinks"
					exact
					:to="link.to"
					:active-class="color"
					avatar
					class="v-list-item mb-2"
				>
					<v-list-item-icon class="ml-2">
						<v-icon>{{ link.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-title v-html="link.text" />
				</v-list-item>
				<v-list-group
					to=""
					v-else
					:key="link.text"
					:prepend-icon="link.icon"
					:value="subIsActive(link.group)"
					no-action

				>
					<template v-slot:activator>
						<v-list-item-title>{{ link.text }}</v-list-item-title>
					</template>
		
					<v-list-item
						v-for="sublink in link.subLinks"
						:to="sublink.to"
						class="pr-7"
						:key="sublink.text"
					>
							<v-list-item-icon class="ml-1">
								<v-icon>{{ sublink.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-title>{{ sublink.text }} </v-list-item-title>
					</v-list-item>
				</v-list-group>
			</div>
		</v-list>
	</div>
</template>

<script>
const menu = [
	{
		text: 'داشبورد',
		to: '/panel/account',
		icon: 'mdi-view-dashboard',
	},
	{
		text: 'ثبت سفارش',
		to: '/panel/account/order',
		icon: 'mdi-cart',
	},
	// {
	// 	icon     : 'mdi-book-open-blank-variant',
	// 	text     : 'کتابخانه من',
	// 	group 	 : '/panel/account/books',
	// 	subLinks : [
	// 		{
	// 			text 	: 'کتاب های من',
	// 			to    : '/panel/account/books/my-library',
	// 			group : 'books',
	// 			icon  : 'mdi-bookshelf'
	// 		},
	// 		{
	// 			text 	: 'نشان شده‌ها',
	// 			to    : '/panel/account/books/marked',
	// 			group : 'books',
	// 			icon  : 'mdi-bookmark'
	// 		}, 
	// 	]
	// },
	// {
	// 	text: 'پشتیبانی',
	// 	to: '/panel/account/support',
	// 	icon: 'mdi-face-agent',
	// },
	// {
	// 	text: 'دیکشنری',
	// 	to: '/panel/account/dictionary',
	// 	icon: 'mdi-translate',
	// },
	{
		text: 'کیف پول من <span id="cdf"></span>',
		to: '/panel/account/wallet',
		icon: 'mdi-wallet',
	}, 
	{
		text: 'فاکتور ها',
		to: '/panel/account/invoices',
		icon: 'mdi-clipboard-text-clock',
	},
	{
		text: 'تنظیمات',
		to: '/panel/account/profile',
		icon: 'mdi-cogs',
	},
	{
		text: 'سامانه معرف',
		to: '/panel/account/referral',
		icon: 'mdi-human-capacity-increase',
	}
]
import { mapState, mapActions } from 'vuex'

export default ({
	props: {
		color: { type: String },
		panel: { type: Boolean },
		account: { type: Object },
	},
	name: 'DefaultUserMenuDrawer',
  components:{
		
  },
  computed: {
    ...mapState({
      UserPanel: state => state.UserPanel,
    }),
  },
	created(){
		this.requestPanelData();
	},
	data(instance) {
    return {
			menu,
			panelMenuDrawer: instance.panel,
			drawer: true
		}
  },
	methods: {
		subIsActive(input) {
			const paths = Array.isArray(input) ? input : [input]
			return paths.some(path => {
				return this.$route.path.indexOf((path)) === 0 // current path starts with this path string
			})
		},
    ...mapActions('UserPanel', {
      requestPanelData: 'requestPanelData'
    }),
    toggle(v){
      this.$emit('handle-drawer-change', v)
    }
	},
  watch: { 
		'UserPanel.status': function(s) {
			if(s == 'done') {
			console.log(document.getElementById('cdf'))
			}
		},
    panel: function(newVal) { // watch it
      this.panelMenuDrawer = newVal
    }
  }


})
</script>
