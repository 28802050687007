
import Vue from 'vue';
import Vuex from 'vuex';

import { website } from './website.module';
import { alert } from './alert.module';
import { account } from './account.module';
import { users } from './users.module';

import { customForm } from './CustomForms.module';

import { AdminPanel } from './panels/AdminPanel.module';
import { UserPanel } from './panels/UserPanel.module';
import { TranslatorPanel } from './panels/TranslatorPanel.module';

import { ChatPanel } from './chat.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AdminPanel,
    UserPanel,
    TranslatorPanel,

    ChatPanel,

    customForm,
    alert,
    account,
    users,
    website
  }
});