<template>
  <v-app id="appdef" class=" vapp blue darken-5" style="/*padding-bottom: 73px;*/">
    <system-bar
      
      :account="account" color="deep-purple darken-3"></system-bar>
    
    <v-app-bar
      id="mappbar"
      color="deep-purple accent-4"
      dark
      fixed
      class="mt-6"
      hide-on-scroll
      scroll-target="#basesys"
    >

      <v-app-bar-title class="text-h6 hidden-sm-and-down"> مجموعه اهل سخن</v-app-bar-title>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <v-combobox
        v-if="false" 
        :loading="search.loading"
        v-model="search.query"
				@input="(e) => search.query = e"
        class="mt-2"
				dense
				aria-multiline=""
        flat
				filled
				tile
        hide-details
				clearable
				:hide-no-data="typeof search.query === 'object'"
				@keypress.native="(val) => search.query = search.query"
				open-on-clear
				hide-selected
				item-text="name"
				:items="search.items"
        label="جستجو در مجموعه اهل سخن"
        solo-inverted
				
      >
				<template v-slot:no-data>
					<v-list-item>
						<span class="subheading">موردی مرتبط با متن جستجو شما یافت نشد</span>
					</v-list-item>
				</template> 

				<template v-slot:item="{ index, item }">
					<v-list-item-icon :key="index" style="height: 70px">
						<img style="max-width:50px;border-radius: 5px" :src="JSON.parse(item.cover_images.cover).path">
					</v-list-item-icon>
					<v-list-item-title class="mr-5" style="height: 70px">
						{{item.name}}
						<v-list-item-subtitle class="mt-3" style="max-width: 400px" >
							{{item.description | stripHTML}}
						</v-list-item-subtitle>
					</v-list-item-title>
					<!-- <v-spacer></v-spacer>
					<v-list-item-action @click.stop>
						<v-btn
							icon
							@click.stop.prevent="edit(index, item)"
						>asd
						</v-btn>
					</v-list-item-action> -->
				</template>

			</v-combobox>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
 
      <template v-slot:extension>
        <v-chip-group>

          <v-skeleton-loader
            class="ml-2"
            v-if="website.status == 'loading'"
            type="chip"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="ml-2"
            v-if="website.status == 'loading'"
            type="chip"
          ></v-skeleton-loader>
          <v-skeleton-loader
          v-if="website.status == 'loading'"
            type="chip"
          ></v-skeleton-loader>
          <v-chip
            v-else
            color="deep-purple accent-3"
            pill
            v-for="(link, index) in links"
            :key="index"
            
            :to="link.to || null"
            :href="link.link || null"
            
          >

            <span
              text
              v-if="!link.menu"
            >
              {{ link.text }}
            </span>
            <v-menu
              :close-on-content-click="false"
              nav
              open-on-hover
              center
              :close-on-click="false"
              v-if="link.menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                v-if="link.to"
                :to="link.to"
                text
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                >
                  {{ link.text }}
                </v-list-item>
              </template>

              <v-list dense>
                <div
                  v-for="(item, index) in link.menu"
                  :key="index"
                  :to="link.to"
                >
                  <v-list-item v-if="!item.menu">
                    <v-list-item-title>{{ item.text }}</v-list-item-title> 
                  </v-list-item>
                  <v-list-group
                    v-else
                  >
                    <template v-slot:activator>
                      <v-list-item-title>{{ item.text }}</v-list-item-title> 
                    </template>
                    <v-list-item :to="subItem.to" v-for="(subItem, subKey) in item.menu" :key="subKey">
                      <v-list-item-title>{{subItem.text}}</v-list-item-title>
                    </v-list-item>
                  </v-list-group>

                </div>
              </v-list>
            </v-menu>
          </v-chip>


          </v-chip-group>

          <span class="hidden-sm-and-down"></span>
          <v-spacer ></v-spacer>
          <v-menu
            v-if="account.status.loggedIn"
            offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar
                  v-if="account.user.avatar"
                  size="25px"
                  class="ml-2"
                  right>
                  <img :src="account.user.avatar">
                </v-avatar>
                <v-icon v-else class="ml-1">mdi-account-circle</v-icon>
                {{account.user.fullname}}
              </v-btn>
            </template>
            <v-list dense>
              <!-- <v-list-item
                to="/panel/account/books/my-library"
              >
                <v-list-item-icon>
                  <v-icon>mdi-bookshelf</v-icon>
                </v-list-item-icon>
                <v-list-item-title>کتابخانه من</v-list-item-title>
              </v-list-item>
              <v-list-item
                to="/panel/account/books/marked"
              >
                <v-list-item-icon>
                  <v-icon>mdi-bookmark</v-icon>
                </v-list-item-icon>
                <v-list-item-title>نشان شده ها</v-list-item-title>
              </v-list-item> -->
              <v-list-item
                to="/panel/account/wallet"
              >
                <v-list-item-icon>
                  <v-icon>mdi-wallet</v-icon>
                </v-list-item-icon>
                <v-list-item-title>کیف پول</v-list-item-title>
              </v-list-item>
              <v-list-item
                to="/panel/account/invoices"
              >
                <v-list-item-icon>
                  <v-icon>mdi-cart</v-icon>
                </v-list-item-icon>
                <v-list-item-title>فاکتور های من</v-list-item-title>
              </v-list-item>
              <v-list-item
                to="/panel/account/profile"
              >
                <v-list-item-icon>
                  <v-icon>mdi-cogs</v-icon>
                </v-list-item-icon>
                <v-list-item-title>ویرایش پروفایل</v-list-item-title>
              </v-list-item>
              <v-list-item
                to="/authentication/logout"
              >
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>خروج از حساب کاربری</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn to="/authentication/login" color="" text v-else>
            <v-icon>mdi-account</v-icon> ورود/ثبت نام
          </v-btn>
      </template>


      <!--

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
        -->
    </v-app-bar>

    <div style="margin-top: 150px;">
      <slot />
    </div>
    <v-footer
      absolute
      dark
      padless
    >
      <v-card
        flat
        tile
        class="deep-purple lighten-1 white--text text-center"
      > 
        <v-row
          justify="center"
          no-gutters
        >
          <v-btn
            color="white"
            text
            rounded
            class="my-2"
          >
            صفحه اصلی
          </v-btn>
          <v-btn
            color="white"
            text
            rounded
            class="my-2"
          >
            تماس با ما
          </v-btn>
          <v-btn
            color="white"
            text
            rounded
            class="my-2"
          >
            فروشگاه
          </v-btn>
        </v-row>


        <v-card-text class="white--text pt-0">
          کتابراه مرجع قانونی دانلود کتاب الکترونیکی و دانلود کتاب صوتی است که امکان دسترسی به هزاران کتاب، رمان، مجله و کتاب صوتی و همچنین خرید کتاب الکترونیک از طریق موبایل تبلت و رایانه برای شما فراهم می‌کند. شما با استفاده از کتابراه همیشه و همه جا به کتاب‌ها و کتابخانه خود دسترسی دارید و می‌توانید به سادگی از هر فرصتی برای مطالعه استفاده کنید. در کتابراه برای همه سلیقه‌ها از داستان، رمان و شعر تا روانشناسی، تاریخی، علمی، موفقیت و... کتاب‌هایی پیدا می شود. همچنین در کتابراه هزاران کتاب رایگان نیز قابل دانلود است. اپلیکیشن کتابخوان کتابراه برای اندروید، IOS و ویندوز در دسترس است.


        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Vuetify</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>
import SystemBar from '@/components/SystemBar.vue';
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DefaultLayout',
  components:{
    SystemBar,
  },

	filters: {
		stripHTML: function(string) {
			return string.replace(/<\/?[^>]+>/ig, " "); 
		}
	},

  computed: {
    ...mapState({
      website: state => state.website,
      search: state => state.website.search,
      account: state => state.account,
      users: state => state.users.all
    }),
  },
  created(){

    window.addEventListener("scroll", function(){
      var st = window.pageYOffset || document.documentElement.scrollTop;
      
      var lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      
      this.top = lastScrollTop <= 5 ? true : false
    }, false);
  },
  methods: {

    ...mapActions('website', {
      requestSearch: 'requestSearch',
    }),

    ...mapActions('users', {
      getAllUsers: 'getAll',
      deleteUser: 'delete'
    }),
  },
  data: () => ({
    top: false,
    links: [
      {
        text: 'صفحه اصلی',
        to: '/'
      },
      // {
      //   text: 'خدمات',
      //   to: '/translation',
      //   menu: [ ]
      // },
      {
        text: 'فروشگاه',
        link: 'https://ahlesokhan.com/'
      },
    ],
  }),
  watch:{
    'search.query': function(){
			
			if(typeof this.search.query === 'object' && this.search.query.id) {
				// router
				this.$router.push({path: '/shop/books/'+this.search.query.id})
			}
			else this.requestSearch(this.search.query)
    },
    'website.status': function(){

      switch (this.website.status) {
        case 'done':
          // if(this.website.result.services) {
          //   this.links[1].menu = []
            
          //   Object.keys(this.website.result.services).forEach((key) => {
          //     let menuItem = {
          //       text: this.website.result.services[key].name
          //     }

          //     if(this.website.result.services[key].forms){
          //       menuItem.menu = [];
          //       Object.keys(this.website.result.services[key].forms).forEach((formKey) => {
          //         menuItem.menu.push({
          //           text: this.website.result.services[key].forms[formKey].name,
          //           to: '/services/request/'+this.website.result.services[key].forms[formKey].id
          //         })
          //       })

          //     }
          //     this.links[1].menu.push(menuItem)
          //   })
          // }

          break;
      
        default:
          break;
      }
    },
  }
}
</script>
