
import { required, max, min, digits, email, regex } from 'vee-validate/dist/rules'
import { extend,  setInteractionMode } from 'vee-validate'
setInteractionMode('eager')



const farsi = (value) => {
	var p = /^[\u0600-\u06FF \s]+$/;

	return p.test(value)
};


extend('farsi', {
    validate: value => farsi(value),
	message: '{_field_} باید کارکتر های فارسی باشد.',
})

const validateEnglish = (value) => {
	var p = /^[A-Za-z][A-Za-z0-9]*$/;

	return p.test(value)
};


extend('english', {
    validate: value => validateEnglish(value),
	message: '{_field_} باید کارکتر های انگلیسی باشد.',

});
extend('required', {
	...required,
	message: '{_field_} را وارد کنید',
})
extend('min', {
	...min,
	message: 'فیلد {_field_} نباید کمتر از {length} کارکتر باشد',
})
extend('max', {
	...max,
	message: 'فیلد {_field_} نباید بیشتر از {length} کارکتر باشد',
})
function validatePhoneNumber(number){
	// /^(\+98|0|0098)?9\d{9}$/

	var Regex = /^9([0-9])-?[0-9]{3}-?[0-9]{4}/;
	return Regex.test(number);
  }

extend('phone_number', {
    validate: value => validatePhoneNumber(value),
	message: 'فیلد {_field_} باید یک شماره تلفن صحیح باشد ',
})
function validatePhoneNumberLandline(number){
	
	var Regex = /^0\d{2,3}\d{8}$/

	return Regex.test(number);
  }

extend('phone_landline', {
    validate: value => validatePhoneNumberLandline(value),
	message: 'فیلد {_field_} باید یک شماره تلفن ثابت صحیح باشد ',
})
function validateUserName(username){
	var Regex = /^[a-z0-9_]+$/;
	return Regex.test(username);
  }

extend('username', {
    validate: value => validateUserName(value),
	message: 'فیلد {_field_} باید فقط کارکتر های A تا Z و یا 0 تا 9 باشد ',
})
const validateCodeMelli = (value) => {
	let code = value.replace(/-/g, "");
	var L=code.length;

	if(L<8 || parseInt(code,10)==0) return false;
	code=('0000'+code).substr(L+4-10);
	if(parseInt(code.substr(3,6),10)==0) return false;
	var c=parseInt(code.substr(9,1),10);
	var s=0;
	for(var i=0;i<9;i++)
		s+=parseInt(code.substr(i,1),10)*(10-i);
	s=s%11;
	return (s<2 && c==s) || (s>=2 && c==(11-s));
};

extend('codemelli', {
    validate: value => validateCodeMelli(value),
	message: 'فیلد {_field_} باید یک کد ملی صحیح باشد ',
})  
extend('regex', {
	...regex,
	message: '{_field_} {_value_} با پترن مطابقت ندارد {regex}',
})

extend('digits', {
	...digits,
	message: '{_field_} باید {length} عدد باشد. ({_value_})',
})

extend('email', {
	...email,
	message: 'یک ایمیل صحیح وارد کنید',
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === document.getElementById(target).value;
  },
  message: 'تکرار رمز عبور صحیح نمیباشد'
});

