<template>
	<div> 
    
    <v-navigation-drawer
      @input="toggle"
      v-model="panelMenuDrawer"
      fixed
      app
      :style="customStyle"
      color="lighten-3"
      right
      dir="ltr"
      width="350px"
      
    >
      <v-row
      dir="rtl"
        no-gutters
        class="full-height" style="height: 100%; overflow: hidden">
        <v-col
          cols="3" style="height:calc(100vh - 20px ); overflow-y: scroll; direction: ltr">
          
          <v-avatar
            class="d-block text-center mx-auto mt-4"
            color="brown darken-4"
            @click="$router.push({path: '/'})"
          >
    
            <img
              alt="Avatar"
              src="@/assets/appicon.svg"
            >
          </v-avatar>
          

          <v-divider class="mx-3 my-5"></v-divider>
          
          <v-tooltip
            v-if="account.user.access.includes('admin') ||
              account.user.access.includes('admin_publisher') ||
              account.user.access.includes('admin_content_creator') ||
              account.user.access.includes('admin_editor') ||
              account.user.access.includes('admin_translator')
            "
            left
            color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                class="d-block text-center mx-auto mb-4"
                v-bind="attrs"
                v-on="on"
                color="blue lighten-1"
                size="28"
                @click="panelMenuDrawer = true"
                to="/panel/admin"
                dense rounded icon

              >
                <v-icon>mdi-account-tie</v-icon>
              </v-btn>
            </template>
            <span>پنل مدیریت</span>
          </v-tooltip>
          <v-tooltip
            left
            color="green">
            <template v-slot:activator="{ on, attrs }">
            <v-btn 
              class="d-block text-center mx-auto mb-4"
              @click="panelMenuDrawer = true"
              to="/panel/account"
              color="green lighten-1"
              size="28"
              v-bind="attrs"
              v-on="on"

              dense rounded icon>
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-btn>
            </template>
            <span>پنل کاربری</span>
          </v-tooltip>
          <v-tooltip
            left
            color="teal">
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                class="d-block text-center mx-auto mb-4"
                @click="panelMenuDrawer = true"
                :to="account.user.access.includes('translator') ? '/panel/translator' : '/panel/account/register/translator'"
                v-bind="attrs"
                v-on="on"
                color="teal lighten-1"
                size="28"
                dense rounded icon>
                <v-icon>mdi-translate</v-icon>
              </v-btn>
            </template>
            <span v-if="account.user.access.includes('translator')">پنل ترجمه</span>
            <span v-else>ثبت نام مترجم</span>
          </v-tooltip>
          <v-spacer ></v-spacer>
          <v-tooltip left color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                class="d-block text-center mx-auto mb-4"
                :to="account.user.access.includes('writer') ? '/panel/writer' : '/panel/account/register/writer'"
                v-bind="attrs"
                v-on="on"
                color="blue lighten-1"
                size="28"
                dense rounded icon
              >
                <v-icon>mdi-typewriter</v-icon>
              </v-btn>
            </template>
            <span v-if="account.user.access.includes('writer')">پنل ویراستار</span>
            <span v-else>ثبت نام ویراستار</span>
          </v-tooltip>
          
          <v-tooltip left color="purple">
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                class="d-block text-center mx-auto mb-4"
                :to="account.user.access.includes('content_creator') ? '/panel/content_creator' : '/panel/account/register/content_creator'"
                v-bind="attrs"
                v-on="on"
                color="purple lighten-1"
                size="28"
                dense rounded icon
              >
                <v-icon>mdi-video-vintage</v-icon>
              </v-btn>
            </template>
            <span v-if="account.user.access.includes('content_creator')">پنل تولید محتوی</span>
            <span v-else>ثبت نام کارشناس تولید محتوی</span>
          </v-tooltip> 
          
          <v-tooltip left color="cyan">
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                class="d-block text-center mx-auto mb-4"
                :to="account.user.access.includes('publisher') ? '/panel/publisher' : '/panel/account/register/publisher'"
                v-bind="attrs"
                v-on="on"
                color="cyan lighten-1"
                size="28"
                dense rounded icon
              >
                <v-icon>mdi-book-open-page-variant-outline</v-icon>
              </v-btn>
            </template>
            <span v-if="account.user.access.includes('publisher')">پنل ناشرین</span>
            <span v-else>ثبت نام ناشر</span>
          </v-tooltip> 
        </v-col>
        <v-col cols="9" style="height:calc(100vh - 20px )">
          <v-slide-x-transition mode="out-in">
            <component style=""
              :account="account"
              :is="panelDrawer"
              v-on:handle-drawer-change="updatePanelDrawer"
              @close="panelMenuDrawer = false"
              @open="panelMenuDrawer = true"
              :panel="panelMenuDrawer">
              
            </component>
          </v-slide-x-transition>


        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>



<script>

import AccountMenuDrawer from '@/components/Panels/Default/DefaultUserMenuDrawer.vue';
import TranslatorMenuDrawer from '@/components/Panels/Translator/TranslatorMenuDrawer.vue';
import WriterMenuDrawer from '@/components/Panels/Writer/WriterMenuDrawer.vue';
import AdminMenuDrawer from '@/components/Panels/Admin/AdminMenuDrawer.vue';
import PublisherMenuDrawer from '@/components/Panels/Publisher/PublisherMenuDrawer.vue';
import content_creatorMenuDrawer from '@/components/Panels/ContentCreator/content_creatorMenuDrawer.vue';

export default ({
	props: {
		account: { type: Object },
		customStyle: { type: String },
		color: { type: String },
		panel: { type: Boolean },
		menus: { type: Boolean },
	},
	name: 'PanelSelectorDrawer',
  components:{
		AccountMenuDrawer,
    AdminMenuDrawer,
    TranslatorMenuDrawer,
    WriterMenuDrawer,
    content_creatorMenuDrawer,
    PublisherMenuDrawer

  },
	updated(){
    this.panelDrawer = this.$route.fullPath.split("/")[2]+"MenuDrawer"

	},
	data(instance) {
    return {
			panelMenuDrawer: instance.panel,
			drawer: true,
      panelDrawer: this.$route.fullPath.split("/")[2]+"MenuDrawer"
		}
  },
	methods: {
    updatePanelDrawer(drawerStatus) {
      console.log(drawerStatus)
      this.panelMenuDrawer = drawerStatus
    },
    toggle(v){
      this.$emit('handle-drawer-change', v)
    }
	},
  watch: { 
    panel: function(newVal) { // watch it
      this.panelMenuDrawer = newVal
    }, 
    $route: function(){
      this.panelDrawer = this.$route.fullPath.split("/")[2]+"MenuDrawer"
    }
  }


})
</script>
